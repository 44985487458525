import React, { useState, useEffect, useContext } from "react";
import {
    Row,
    Col,
    Input,
    Button,
    Spin,
    Alert,
    message,
    Select
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { featureImg2 } from "../../constant/images";
import { RiDeleteBin6Line } from "react-icons/ri";
// import { removeFromCart } from "../../store/actions/user";
import { removeFromCart } from "../../StoreRedux/appSlice";
import Swal from "sweetalert2";
import * as UploadServices from "../../services/uploadServices";
import { LoadingOutlined } from "@ant-design/icons";
import * as getServices from "../../services/getServices";
import * as updateServices from "../../services/updateServices";
import { useAtom } from "jotai";
import { cartCount } from "../../Helpers/atoms/freeCoursesAtom"
import { CartContext } from '../../Helpers/CartContext';
import { useNavigate } from "react-router-dom";
import { DEMO_IMAGE_URL } from "../../Helper"

export default function CheckoutCourses({ setCourseDetails }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userData = useSelector((state) => state.user.userData);
    const isUserLoggedIn = useSelector((state) => state.user.isUserLoggedIn);
    const isVendorLoggedIn = useSelector((state) => state.user.isVendorLoggedIn);
    const cartItems = useSelector((state) => state.user.cart);
    const { addItemToCart } = useContext(CartContext);
    const [couponData, setCouponData] = useState("");
    const [couponLoader, setCouponLoader] = useState(false);
    const [cartData, setCartData] = useState([])
    const [couponDiscountData, setCouponDiscountData] = useState()
    const [cartCountValue, setCartCountValue] = useAtom(cartCount);
    const [pricingCategories, setPricingCategory] = useState([]);
    const [selectedPricingCategory, setSelectedPricingCategory] = useState();
    const [categoryLoader, setCategoryLoader] = useState(false);
    const [totalFee, setTotalFee] = useState(0)
    const [totalDiscount, setTotalDiscount] = useState(0)
    const [totalDue, setTotalDue] = useState(0)
    const [pricingCategoryDiscount, setPricingCategoryDiscount] = useState()
    const [totalCategoryDiscount, setTotalCategoryDIscount] = useState()


    const fetchCartItems = async () => {
        try {
            const response = await dispatch(getServices.getCartData());
            addItemToCart(response?.data?.length);
            setCartCountValue(response?.data?.length || 0)
            setCartData(response?.data || []);
        } catch (error) {
            console.error("Error fetching cart data", error);
        }
    };
    useEffect(() => {
        if (isUserLoggedIn) {
            fetchCartItems();
        } else {
            addItemToCart(cartItems?.length)
        }
    }, [isUserLoggedIn]);


    const fetchCategoryPricingDuscount = async (selectedPricingCategory) => {
        try {
            const courseIds = await cartData?.map(course => course?.course?.id)
            const formData = new FormData();
            formData.append("course_id", JSON.stringify(courseIds))
            formData.append("category_id", JSON.stringify(selectedPricingCategory))
            dispatch(UploadServices?.pricingCategoryDiscount(formData)).then((response) => {
                setPricingCategoryDiscount(response?.data)
            }).catch((err) => {
                console.error({ err });
            })
        } catch (e) {
            console.error("Error fetching category pricing discount", e);
        }
    }

    useEffect(() => {
        if (selectedPricingCategory) {
            fetchCategoryPricingDuscount(selectedPricingCategory)
        }
    }, [selectedPricingCategory])


    useEffect(() => {
        let order_items = []
        if (isUserLoggedIn) {
            order_items = cartData?.length > 0 && cartData?.map((items) => {
                const matchedCoupon = couponDiscountData?.course_discount_details?.find(coupon => coupon.course_id === items?.course?.id);
                const matchedPricingCategory = pricingCategoryDiscount?.find(discount => discount.course_id === items?.course?.id);
                const courseFee = items?.course?.fee ? items?.course?.fee : 0;
                let discountedPrice = courseFee;

                if (matchedCoupon?.discount && matchedPricingCategory?.discount) {
                    discountedPrice = items?.course?.fee - (parseFloat(matchedCoupon.discount) + parseFloat(matchedPricingCategory.discount));
                } else if (!matchedCoupon?.discount && matchedPricingCategory?.discount) {
                    discountedPrice = items?.course?.fee - parseFloat(matchedPricingCategory.discount);
                } else if (matchedCoupon?.discount && !matchedPricingCategory?.discount) {
                    discountedPrice = items?.course?.fee - parseFloat(matchedCoupon?.discount);
                }else{
                    discountedPrice = items?.course?.fee;
                }
                return {
                    course_id: items?.course?.id,
                    course_price: items?.course?.fee ? items?.course?.fee : 0,
                    price: items?.course?.fee ? items?.course?.fee : 0,
                    discounted_price: discountedPrice,
                    coupon_id: matchedCoupon ? couponDiscountData?.coupon_id : null,
                    coupon_discount: matchedCoupon ? matchedCoupon?.discount : null,
                    vendor_id: items?.course?.vendor_id,
                    pricing_category_id: matchedPricingCategory?.pricing_category_id ?matchedPricingCategory?.pricing_category_id: null,
                    pricing_category_discount: parseFloat(matchedPricingCategory?.discount ? matchedPricingCategory?.discount :0)

                };
            });


        } else {
            order_items = cartItems?.length > 0 && cartItems?.map((items) => {
                const matchedCoupon = couponDiscountData?.course_discount_details?.find(coupon => coupon.course_id === items?.id);
                const matchedPricingCategory = pricingCategoryDiscount?.find(discount => discount.course_id === items?.course?.id);

                const courseFee = items?.course?.fee ? items?.course?.fee : 0;
                let discountedPrice = courseFee;

                if (matchedCoupon?.final_price && matchedPricingCategory?.discount) {
                    discountedPrice = parseFloat(matchedCoupon.final_price) - parseFloat(matchedPricingCategory.discount);
                } else if (!matchedCoupon?.final_price && matchedPricingCategory?.discount) {
                    discountedPrice = courseFee - parseFloat(matchedPricingCategory.discount);
                } else if (matchedCoupon?.final_price && !matchedPricingCategory?.discount) {
                    discountedPrice = parseFloat(matchedCoupon.final_price);
                }
                return {
                    course_id: items?.id,
                    course_price: items?.fee ? items?.fee : 0,
                    price: items?.fee ? items?.fee : 0,
                    discounted_price: discountedPrice,
                    coupon_id: matchedCoupon ? couponDiscountData?.coupon_id : null,
                    coupon_discount: matchedCoupon ? matchedCoupon?.discount : null,
                    vendor_id: items?.vendor_id,
                    pricing_category_id: matchedPricingCategory?.pricing_category_id ? matchedPricingCategory?.pricing_category_id : null,
                    pricing_category_discount: parseFloat(matchedPricingCategory?.discount ? matchedPricingCategory?.discount:0)

                }

            })
        }

        let discountFromCategory = 0;
        if (pricingCategoryDiscount?.length > 0) {
            discountFromCategory = pricingCategoryDiscount.reduce((acc, curr) => {
                return acc + parseFloat(curr?.discount || 0);
            }, 0);
        }

        const completeOrderItems = {
            order_items,
            total_original_amount: totalFee,
            paid_amount: totalDue,
            coupon_id: couponDiscountData?.coupon_id ? couponDiscountData?.coupon_id : null,
            coupon_code: couponDiscountData?.coupon_code ? couponDiscountData?.coupon_code : null,
            coupon_discount: couponDiscountData?.total_discount ? couponDiscountData?.total_discount : null,
            pricing_category_id: selectedPricingCategory? selectedPricingCategory:null,
            pricing_category_discount: discountFromCategory
        }
        setCourseDetails(completeOrderItems)
    }, [cartData, cartItems, couponDiscountData ,pricingCategoryDiscount,totalDue]);



    useEffect(() => {
        let totalFeeCart = 0;

        if (isUserLoggedIn) {
            totalFeeCart = cartData?.reduce((acc, curr) => {
                const finalPrice = curr?.course?.fee || 0;
                return acc + parseFloat(finalPrice);
            }, 0);
        } else {
            totalFeeCart = cartItems?.reduce((acc, curr) => {
                const finalPrice = curr?.fee || 0;
                return acc + parseFloat(finalPrice);
            }, 0);
        }

        setTotalFee(totalFeeCart);
    }, [isUserLoggedIn, cartData, cartItems]);



    useEffect(() => {
        let discountFromCategory = 0;
        if (pricingCategoryDiscount?.length > 0) {
            discountFromCategory = pricingCategoryDiscount.reduce((acc, curr) => {
                return acc + parseFloat(curr?.discount || 0);
            }, 0);
        }

        setTotalDiscount(couponDiscountData?.total_discount ? couponDiscountData?.total_discount : 0);
        setTotalCategoryDIscount(discountFromCategory)
        setTotalDue(totalFee - ((couponDiscountData?.total_discount ? couponDiscountData?.total_discount : 0) + discountFromCategory));

    }, [couponDiscountData]);

    useEffect(() => {
        let discountFromCategory = 0;

        if (pricingCategoryDiscount?.length > 0) {
            discountFromCategory = pricingCategoryDiscount.reduce((acc, curr) => {
                return acc + parseFloat(curr?.discount || 0);
            }, 0);
        }
        setTotalCategoryDIscount(discountFromCategory)
        setTotalDiscount(couponDiscountData?.total_discount ? couponDiscountData?.total_discount : 0)
        setTotalDue(totalFee - (couponDiscountData?.total_discount ? couponDiscountData?.total_discount + discountFromCategory : 0 + discountFromCategory));

    }, [totalFee, pricingCategoryDiscount]);



    const handleApplyCoupon = async () => {
        setCouponLoader(true)
        if (isUserLoggedIn) {
            const courseIds = await cartData?.map(course => course?.course?.id)
            const formData = new FormData();
            formData.append("course_id", JSON.stringify(courseIds))
            formData.append("coupon_code", couponData)
            formData.append("user_id", userData?.user?.id ? userData?.user?.id : "")

            dispatch(UploadServices?.applyCoupon(formData)).then((response) => {
                setCouponLoader(false)
                setCouponDiscountData(response.data)
                setCouponData("")
                Swal.fire(
                    "Successful!",
                    `${response.message}`,
                    "success"
                )

            }).catch((error) => {
                setCouponData("")
                setCouponLoader(false)
                console.error({ error })
                Swal.fire(
                    "Failed",
                    `${error?.response?.data?.message}`,
                    "error"
                )
            })
        } else {
            const courseIds = cartItems?.map(course => course?.id)
            const formData = new FormData();
            formData.append("course_id", JSON.stringify(courseIds))
            formData.append("coupon_code", couponData)
            formData.append("user_id", "")

            dispatch(UploadServices?.applyCoupon(formData)).then((response) => {
                setCouponLoader(false)
                setCouponDiscountData(response.data)
                Swal.fire(
                    "Successful!",
                    `${response.message}`,
                    "success"
                )

            }).catch((error) => {
                setCouponLoader(false)
                Swal.fire(
                    "Failed",
                    `${error.message}`,
                    "error"
                )
            })
        }
    };

    const handleCloseAlert = () => {
        let discountFromCategory = 0;
        if (pricingCategoryDiscount?.length > 0) {
            discountFromCategory = pricingCategoryDiscount.reduce((acc, curr) => {
                return acc + parseFloat(curr?.discount || 0);
            }, 0);
        }
        setTotalDiscount(0)
        setTotalCategoryDIscount(discountFromCategory)
        setTotalDue(totalFee - discountFromCategory)
        setCouponDiscountData()
    };

    const handleRemoveItem = (id) => {
        try {
            Swal.fire({
                title: "Remove Item",
                text: "Are you sure you want to remove?",
                icon: "info",
                showCancelButton: true,
                confirmButtonText: "Yes, remove it!",
                cancelButtonText: "No, keep it",
            }).then((result) => {
                if (result.isConfirmed) {
                    if (isUserLoggedIn) {
                        dispatch(updateServices.removeCartItem(id)).then((response) => {
                            if (isUserLoggedIn) {
                                fetchCartItems();
                            }
                            message.success(`${response.message}`);
                        }).catch((err) => {
                            message.error(`${err.message}`);
                        })
                    } else {

                        dispatch(removeFromCart(id))
                        addItemToCart(cartItems?.length === 0 ? 0 : cartItems?.length - 1);
                        message.success("Item removed successfully.");
                    }
                }
            }).catch((err) => { });
        } catch (e) {
            Swal.fire(
                "Error",
                `${e.message}`,
                "error"
            );
        }
    }

    const handleCourseData = (values) => {
        navigate(`/course/${values?.id}/${values?.title}`, {
            state: { course_id: values?.id },
        });
    };


    const getPricingCategory = () => {
        setCategoryLoader(true)
        dispatch(getServices.getPricingCategory()).then((response) => {
            setCategoryLoader(false)
            setPricingCategory(response?.pricing_categories);
        }).catch((err) => {
            setCategoryLoader(false)
            console.error(err);
        })
    }

    return (
        <>
            <div className="checkout-cart-section">
                <div className="row">
                    <div className="col-xl-12 col-md-12">
                        <h4 className="formTitle">Order Summary</h4>
                    </div>
                </div>
                <Row className="checkout-cart-row">
                    <Col className="cart-items-col" xl={24} md={24}>
                        <Row className="product-items-row">
                            {isUserLoggedIn && cartData && cartData.length > 0 ? (
                                <>
                                    {cartData.map((item) => {
                                        const discountItem = couponDiscountData?.course_discount_details?.find(
                                            (course) => course.course_id === item?.course?.id
                                        );
                                        const finalPrice = discountItem ? discountItem.final_price : item?.course?.fee;
                                        return (<Col
                                            className="shop-cart-items-content"
                                            xl={24}
                                            md={24}
                                            key={item?.id}
                                        >
                                            <Row>
                                                <Col className="cart-items-col px-2" md={16} sm={24}>
                                                    <div className="product-detail single-pro-items d-flex" style={{ cursor: "pointer" }} onClick={() => handleCourseData(item?.course)}>
                                                        <div className="item-image-thumb">
                                                            <img
                                                                src={item?.image ? `${DEMO_IMAGE_URL}${item?.image}` : item?.course?.image ? `${DEMO_IMAGE_URL}${item?.course?.image}` : featureImg2}
                                                                alt="Course Image"
                                                            />
                                                        </div>
                                                        <div className="item-content-tab">
                                                            {
                                                                item?.course?.parent_category_name !== null ? <h5 className="main-category mb-0">{item?.course?.parent_category_name}</h5 > : ''
                                                            }

                                                            <h4>{item?.course?.title}</h4>
                                                            <p>{item?.course?.about_course}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col className="cart-items-col px-2" md={4} sm={24}>
                                                    <div className="product-price">
                                                        <span className={discountItem !== undefined && item?.course?.fee > 0 && item?.course?.fee !== null ? "original-amount" : ""}>${item?.course?.fee ? item?.course?.fee : 0}  </span> {discountItem !== undefined && item?.course?.fee > 0 ? <span className="discounted-amount">${finalPrice.toFixed(2)}</span> : ""}
                                                    </div>
                                                </Col>
                                                <Col className="cart-items-col" md={2} sm={24}>
                                                    <div className="remove-item">
                                                        <RiDeleteBin6Line
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => handleRemoveItem(item?.id)}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>)
                                    })}
                                    {totalDue > 0 ? (
                                        <div className="coupon-application-container">
                                            <p className="apply-coupon-text">Apply Coupon</p>
                                            {couponDiscountData ? (
                                                <Alert
                                                    className="couponRibbon mb-4"
                                                    message={
                                                        <>
                                                            <p className="couponText">{couponDiscountData?.coupon_code}</p>
                                                            <span>
                                                                {couponDiscountData?.coupon_code} - ${couponDiscountData?.total_discount}
                                                            </span>
                                                        </>
                                                    }
                                                    type="success"
                                                    showIcon
                                                    closable
                                                    onClose={() => handleCloseAlert()}
                                                />
                                            ) : (

                                                <div className="coupon-codes">
                                                    <Input
                                                        value={couponData}
                                                        onChange={(e) => setCouponData(e.target.value)}
                                                    />
                                                    <Button
                                                        onClick={() => handleApplyCoupon()}
                                                        type="button"
                                                    >
                                                        {couponLoader ? (
                                                            <Spin indicator={<LoadingOutlined style={{ color: "#fff" }} spin />} />
                                                        ) : (
                                                            "Apply"
                                                        )}
                                                    </Button>
                                                </div>

                                            )}

                                            <div className="pricing-category-heading-dropdown">
                                                <p className="select-category-heading">Select Category</p>
                                                <Select
                                                    placeholder="Select funding category"
                                                    className="select-category-dropdown"
                                                    onClick={() => pricingCategories.length === 0 ? getPricingCategory() : <></>}
                                                    onChange={(e) => setSelectedPricingCategory(e)}
                                                    notFoundContent={categoryLoader ? (
                                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                                            <Spin indicator={<LoadingOutlined style={{ fontSize: 28 }} spin />} />
                                                        </div>
                                                    ) : null}
                                                    options={pricingCategories?.map((option) => ({
                                                        value: option.id,
                                                        label: option.name,
                                                    }))}
                                                />
                                            </div>

                                        </div>
                                    ) : (<></>)}

                                </>
                            ) : !isUserLoggedIn && !isVendorLoggedIn && cartItems && cartItems.length > 0 ? (
                                <>

                                    {cartItems.map((item) => {
                                        const discountItem = couponDiscountData?.course_discount_details?.find(
                                            (course) => course.course_id === item?.id
                                        );
                                        const finalPrice = discountItem ? discountItem.final_price : item?.fee;
                                        return (<Col
                                            className="shop-cart-items-content"
                                            xl={24}
                                            md={24}
                                            key={item?.id}
                                        >
                                            <Row>
                                                <Col className="cart-items-col px-2" md={16} sm={24}>
                                                    <div className="product-detail single-pro-items d-flex">
                                                        <div className="item-image-thumb">
                                                            <img
                                                                src={item?.image ? `${DEMO_IMAGE_URL}${item?.image}` : featureImg2}
                                                                alt="Course Image"
                                                            />
                                                        </div>
                                                        <div className="item-content-tab">
                                                            <h6>{item?.title}</h6>
                                                            <p>{item?.about_course}</p>


                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col className="cart-items-col px-2" md={4} sm={24}>
                                                    <div className="product-price">
                                                        <span className={discountItem !== undefined && item?.fee > 0 ? "original-amount" : ""}>${item?.fee}  </span> {discountItem !== undefined && item?.fee > 0 ? <span className="discounted-amount">${finalPrice.toFixed(2)}</span> : ""}
                                                    </div>
                                                </Col>
                                                <Col className="cart-items-col" md={2} sm={24}>
                                                    <div className="remove-item">
                                                        <RiDeleteBin6Line
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => handleRemoveItem(item?.id)}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>)
                                    })}

                                    <div className="coupon-application-container">
                                        <p className="apply-coupon-text">Apply Coupon</p>
                                        {couponDiscountData ? (
                                            <Alert
                                                className="couponRibbon mb-4"
                                                message={
                                                    <>
                                                        <p className="couponText">{couponDiscountData?.coupon_code}</p>
                                                        <span>
                                                            {couponDiscountData?.coupon_code} - ${couponDiscountData?.total_discount}
                                                        </span>
                                                    </>
                                                }
                                                type="success"
                                                showIcon
                                                closable
                                                onClose={() => handleCloseAlert()}
                                            />
                                        ) : (

                                            <div className="coupon-codes">
                                                <Input
                                                    onChange={(e) => setCouponData(e.target.value)}
                                                />
                                                <Button
                                                    onClick={() => handleApplyCoupon()}
                                                    type="button"
                                                >
                                                    {couponLoader ? (
                                                        <Spin indicator={<LoadingOutlined style={{ color: "#fff" }} spin />} />
                                                    ) : (
                                                        "Apply"
                                                    )}
                                                </Button>
                                            </div>

                                        )}
                                        <div className="pricing-category-heading-dropdown">
                                            <p className="select-category-heading">Select Category</p>
                                            <Select
                                                placeholder="Select funding category"
                                                className="select-category-dropdown"
                                                onClick={() => pricingCategories.length === 0 ? getPricingCategory() : <></>}
                                                onChange={(e) => setSelectedPricingCategory(e)}
                                                notFoundContent={categoryLoader ? (
                                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                                        <Spin indicator={<LoadingOutlined style={{ fontSize: 28 }} spin />} />
                                                    </div>
                                                ) : null}
                                                options={pricingCategories?.map((option) => ({
                                                    value: option.id,
                                                    label: option.name,
                                                }))}
                                            />
                                        </div>
                                    </div>
                                </>


                            ) : (
                                <p>No items in the cart.</p>
                            )}
                        </Row>
                    </Col>
                </Row>

                <div className="totalSection mt-4">
                    <div className="totalList">
                        <span className="leftText">Subtotal</span>
                        <span className="rightText">${totalFee}</span>
                    </div>
                    <div className="totalList">
                        <span className="leftText">Coupon Discount</span>
                        <span className="rightText discountText">-${totalDiscount}</span>
                    </div>
                    <div className="totalList">
                        <span className="leftText">Category Discount</span>
                        <span className="rightText discountText">-${totalCategoryDiscount}</span>
                    </div>
                    <div className="totalList">
                        <span className="leftText dueText">Total Amount</span>
                        <span className="rightText dueText">${totalDue}</span>
                    </div>
                </div>
            </div>
        </>
    );
}
