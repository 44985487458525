import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Table, Pagination, Modal, Spin } from 'antd';
import Sidebar from '../../components/Sidebar/sidebar';
import { useNavigate } from 'react-router-dom';
import { MdDelete } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import * as getServices from '../../services/getServices';
import { useDispatch } from 'react-redux';
import { IoEyeOutline } from "react-icons/io5";
import * as updateService from '../../services/updateServices';
import { LoadingOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';
const AllCoupon = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [coupons, setCoupons] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [modelOpen, setModelOpen] = useState(false)
    const [selectedCoupon, setSelectedCoupon] = useState()
    const [loader, setLoader] = useState(false);
    const [pageSize, setPageSize] = useState(5);
    const [totalCoupon, setTotalCoupon] = useState(0);


    const handleEdit = (data) => {
        navigate('/vendor/coupons', { state: { couponsData: data } })
    };


    const getCoupons = () => {
        setLoader(true)
        try {

            dispatch(getServices?.getCoupons(pageSize, currentPage))
                .then((response) => {
                    setCoupons(response?.data);
                    setTotalCoupon(response?.total)
                    setLoader(false)
                }).catch((err) => {
                    console.error({ err });
                })
        } catch (error) {
            console.log(error, "error")
        }

    }
    console.log(coupons, "coupons")
    useEffect(() => {
        getCoupons()
    }, [pageSize, currentPage])


    const handleView = (data) => {
        setModelOpen(true)
        setSelectedCoupon(data)
    }

    const handleHelpCancle = () => {
        setModelOpen(false)
    }

    const handleDelete = (data) => {

        Swal.fire({
            title: "Are you sure you want to delete this item?",
            text: "This action cannot be undone",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "Cancel",
        }).then((result) => {
            if (result.isConfirmed) {
                try {
                    dispatch(updateService.deleteCoupons(data?.id))
                        .then((response) => {
                            Swal.fire({
                                title: "Delete Item",
                                text: `${response?.message}`,
                                icon: "success",
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    getCoupons();
                                }
                            });
                        })
                        .catch((err) => {
                            Swal.fire("Failed", `${err?.message}`, "error");
                        });
                } catch (err) {
                    Swal.fire("Failed", `Failed to detete items.`, "error");
                }
            }
        });
    };

    const sortedData = coupons?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

    const DateFormatter = (date) => {
        const inputDate = date;
        const dateObj = new Date(inputDate);
        const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];

        const day = dateObj.getDate();
        const month = monthNames[dateObj.getMonth()];
        const year = dateObj.getFullYear();

        const formatDate = `${day} ${month} ${year}`;
        return formatDate;
    };

    const handleChangePage = (page) => {
        setCurrentPage(page);
    };

    const handlePageSizeChange = (current, size) => {
        setPageSize(size);
        setCurrentPage(1); // Reset to the first page when page size changes
        getCoupons(size, 1); // Fetch data with the new page size
    };


    const generatePageSizeOptions = (total) => {
        const options = [];
        const maxPageSize = Math.min(total, 100);

        for (let i = 5; i <= maxPageSize; i += 5) {
            options.push(i);
        }
        if (total > 5 && !options.includes(10)) {
            options.push(10);
        }

        if (total > 10 && !options.includes(15)) {
            options.push(15);
        }
        return options.sort((a, b) => a - b);
    };

    // const pageSizeOptions = React.useMemo(() => generatePageSizeOptions(totalCoupon), [totalCoupon]);
    const pageSizeOptions = generatePageSizeOptions(totalCoupon);

    const columns = [

        {
            title: "S No.",
            dataIndex: 'serial',
            render: (_, record, index) => (currentPage - 1) * pageSize + index + 1,
        },
        {
            title: 'Coupon Code',
            dataIndex: 'coupon_code',
            key: 'coupon_code',
        },
        {
            title: 'Discount Type',
            dataIndex: 'discount_type',
            key: 'discount_type',
            render: (text) => (text === 'fixed_amount' ? 'Fixed amount' : 'Percentage'),
        },
        {
            title: 'Discount Value',
            dataIndex: 'discount_value',
            key: 'discount_value',
        },
        {
            title: ' Minimum Purchase Amount',
            dataIndex: 'min_purchase_amt',
            key: 'min_purchase_amt',
        },
        {
            title: 'Validity',
            dataIndex: 'end_date',
            key: 'end_date',
            render: (text, record) => `${record.end_date}`,
        },
        {
            title: 'Created At',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (_, record, index) => (
                <div>
                    <p>{DateFormatter(record?.created_at)}</p>

                </div>
            ),
        },


        {
            title: "Action",
            dataIndex: "action",
            key: "Action",
            render: (time, record) => (
                <div className="timing">
                    <button
                        className="btn updateButton"
                        onClick={() => handleEdit(record)}
                    >
                        <FaEdit />
                    </button>
                    <button
                        className="btn deleteButton"
                        onClick={() => handleDelete(record)}
                    >
                        <MdDelete />
                    </button>
                    <button
                        className="btn viewButton"
                        onClick={() => handleView(record)}
                    >
                        < IoEyeOutline />
                    </button>
                </div>
            ),
        },
    ];


    return (
        <Row>
            <Col xl={4} className="d-xl-block d-md-none">
                <Sidebar />
            </Col>
            <Col xl={20} md={24}>
                <div className="mainContent my-courses">
                    <div className="my-courses-container">
                        <div className="download-courses-container mb-0">
                            <p className="my-course-heading">My Coupons</p>

                            <div className="course-filter-add-item">

                                <Button
                                    onClick={() => navigate("/vendor/coupons")}
                                    className="add-new-course-button"
                                >
                                    Add New Coupon
                                </Button>
                            </div>

                        </div>

                        {/* <div>

                            <Space className="space">
                                <Search
                                    placeholder="input search text"
                                    onSearch={applyFilters}
                                    // enterButton

                                    allowClear


                                />
                            </Space>

                        </div> */}
                        <div className="my-course-table-container table-responsive">

                            {
                                loader ? (
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
                                    </div>
                                ) : (


                                    sortedData?.length > 0 ? (
                                        <>
                                            <div
                                                style={{ padding: "3rem 0" }}
                                                className="table-responsive"
                                            >
                                                <Table
                                                    className="my-course-table"
                                                    columns={columns}
                                                    dataSource={sortedData}
                                                    pagination={{
                                                        showSizeChanger: true,
                                                        current: currentPage,
                                                        pageSize: pageSize,
                                                        total: totalCoupon,
                                                        onChange: handleChangePage,
                                                        onShowSizeChange: handlePageSizeChange,
                                                        pageSizeOptions: pageSizeOptions,
                                                    }}

                                                />
                                                {/* <Pagination
                                                className="mt-4"
                                                current={currentPage}
                                                pageSize={pageSize}
                                                        total={totalCoupon}
                                                onChange={handleChangePage}
                                                // showSizeChanger={false}
                                                onShowSizeChange={handlePageSizeChange}
                                                showSizeChanger
                                                pageSizeOptions={pageSizeOptions}
                                            /> */}
                                            </div>
                                        </>
                                    ) : "No Data"

                                )}







                        </div>
                    </div>
                </div>

                <Modal
                    title="Coupons Detail"
                    open={modelOpen}
                    onOk={handleHelpCancle}
                    onCancel={handleHelpCancle}
                    centered
                    className="booking-detail-model"
                    footer={false}

                >


                    <div >
                        <p> <strong>Coupon Code:</strong> {selectedCoupon?.coupon_code}</p>
                        <p><strong>Discount Type:</strong> {selectedCoupon?.discount_type === 'fixed_amount' ? 'Fixed Amount' : 'Percentage'}</p>
                        <p><strong>Discount Value:</strong> {selectedCoupon?.discount_value}</p>
                        <p> <strong>Minimum Purchase Amount:</strong> {selectedCoupon?.min_purchase_amt}</p>
                        <p><strong>Validity:</strong> {selectedCoupon?.end_date}</p>
                        <p><strong>Created At:</strong> {DateFormatter(selectedCoupon?.created_at)}</p>
                        <p>
                            <strong>Course Name :</strong>
                            {selectedCoupon?.apply_to_all_courses === 1 && selectedCoupon.apply_to_all_courses !== undefined
                                ? "All Courses"
                                : selectedCoupon?.course
                                    ? selectedCoupon.course.map(course => course.title).join(", ")
                                    : 'No course selected'}
                        </p>


                    </div>


                </Modal>


            </Col>
        </Row>
    )
}

export default AllCoupon;