import React, { useState, useEffect } from "react";
import { Row, Col, Image, Card, Button } from "antd";
import { FaUnlock, FaLock } from "react-icons/fa";
import * as getServices from "../../services/getServices";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {DEMO_IMAGE_URL} from "../../Helper"
import Swal from "sweetalert2";
import { FaRegHeart } from "react-icons/fa6";
import { GoHeartFill } from "react-icons/go";
import * as uploadService from "../../services/uploadServices";
import {featureImg2} from "../../constant/images"

const RecentCourse = ({wishlistData,getWishlistData}) => {
    const userData = useSelector((state) => state.user.userData);
    const isUserLoggedIn = useSelector((state) => state.user.isUserLoggedIn);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [courses, setCourses] = useState([]);
    const [paymentDetails, setPaymentDetails] = useState([]);
    

    useEffect(() => {  
        let data = { 
            sort_by: 'created_at',
            sort_order: 'desc',
            per_page: 3,
            page: 1
        }
        let quesryString =  `?${new URLSearchParams(data).toString()}`
        dispatch(getServices?.getCourses(quesryString))
            .then((response) => setCourses(response?.data))
            .catch((err) => console.error({ err }));

        if (userData?.user?.id) {
            dispatch(getServices?.getPaymentDetails(userData?.user?.id))
                .then((response) => setPaymentDetails(response?.payments))
                .catch((err) => console.error({ err }));
        }
    }, [dispatch, userData?.user?.id]);


   
    const DateFormatter = (date) => {
        const dateObj = new Date(date);
        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        return `${dateObj.getDate()} ${monthNames[dateObj.getMonth()]} ${dateObj.getFullYear()}`;
    };

    const sortedCourses = courses.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));


    const handlePurchase = (courseData) => {
        navigate(`/payment/${userData?.user_id}/${courseData?.id}`, { state: { courseData } });
    };

    const handleCourseData = (courseData) => {
        navigate(`/course/${courseData?.id}/${courseData?.title}`, { state: { course_id: courseData?.id } });
    };
    const handleWishlistList = (course) => {

        let formData = new FormData();
        formData.append("course_id", course?.id)

        try {

            dispatch(uploadService.addRemoveCourseWishlist(formData)).then((response) => {
                if (userData?.user?.id) {
                    getWishlistData()
                }
                Swal.fire(
                    "Success!",
                    `${response?.message}`,
                    "success"
                )
            }).catch((error) => {
                Swal.fire(
                    "Failed",
                    "Unable to add items to wishlist.",
                    "error"
                )
            })

        } catch (e) {
            Swal.fire(
                "Failed",
                "Unable to add item to wishlist.",
                "error"
            )
        }

    }
    return (
        <div className="recentCourseWrapper">
            {courses.length > 0 && (
                <div className="container">
                    <Row className="headerRow">
                        <Col md={24}>
                            <div className="header">
                                <h4 className="border-bottom-heading bottom-border-center">Recently Added</h4>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mainContent-recent-course">
                        <Col md={12} className="leftSection px-1">
                            <Card>
                                <div className="imgWrapper">
                                    {courses[0]?.parent_category_name ? (
                                        <h5 className="main-category">{courses[0]?.parent_category_name}</h5>
                                    ) : (<></>)}
                                    <Image
                                        src={courses[0]?.image ? `${DEMO_IMAGE_URL}${courses[0]?.image}` :featureImg2}
                                        alt="Not Found"
                                        className="img-fluid"
                                        preview={false}
                                    />
                                    {courses[0]?.trial === 1 ? (
                                        <div className="trail-available-container">
                                            <p className="trial-available-text">Trial Available</p>
                                        </div>
                                    ) : (<></>)}

                                    <div className="start-end-date-container">
                                        <p className="start-enddate">{`${DateFormatter(courses[0]?.course_start)} - ${DateFormatter(courses[0]?.course_end)}`}</p>
                                    </div>
                                    <div className="cart-lock-div">
                                        {paymentDetails.some((item) => item?.course_id === courses[0]?.id) ? (
                                            <FaUnlock color="#fff" />
                                        ) : (
                                            <FaLock color="#fff" />
                                        )}
                                    </div>
                                    <div className="wishlist-icon-div">
                                        {
                                            isUserLoggedIn ? (
                                                <button
                                                    type="button"
                                                    className="btn wishlist-feature-button add-to-wishlist"
                                                    onClick={() => handleWishlistList(courses[0])}
                                                >
                                                    {wishlistData?.some(
                                                        (wish) => parseInt(wish?.id) === parseInt(courses[0]?.id)
                                                    ) ? (
                                                        <GoHeartFill />
                                                    ) : (
                                                        <FaRegHeart />
                                                    )}
                                                </button>
                                            ) : " "
                                        }
                                    </div>
                                </div>
                                <div className="infoSection">
                                    <div className="text">

                                        <h3>{courses[0]?.title}</h3>
                                        {
                                            courses[0]?.course_status === 1 || courses[0]?.is_sfc_status === 1 ? (


                                                <span>
                                                    {courses[0]?.course_status === 1 && courses[0]?.is_sfc_status === 1
                                                        ? "WSQ, SFC"
                                                        : courses[0]?.course_status === 1
                                                            ? "WSQ"
                                                            : courses[0]?.is_sfc_status === 1
                                                                ? "SFC"
                                                                : ''
                                                    }
                                                </span>


                                            ) : ""
                                        } 
                                        {courses[0]?.approach === 1 ? <Button>Online</Button> : courses[0]?.approach === 2 ? <Button>Physical</Button> : courses[0]?.approach === 3 ? <Button>Combined</Button> : ""}

                                        <p>{courses[0]?.about_course}</p>
                                    </div>
                                    {courses[0]?.parent_category_name ? (
                                        <div className="tags">
                                            <span className="one">{courses[0]?.parent_category_name}</span>
                                        </div>
                                    ) : (<></>)}

                                    
                                    <div className="link">
                                        <Button className="view-course-recent-course-button" onClick={() => handleCourseData(courses[0])}>View Details</Button>
                                    </div>
                                </div>
                            </Card>
                        </Col>

                        <Col md={12} className="rightSection px-1">
                            <div className="splitRowWrapper">
                                {courses[1] && (
                                    <div className="topSection">
                                        <Card>
                                            <div className="imgWrapper">
                                                {sortedCourses[1]?.parent_category_name ? (
                                                    <h5 className="main-category">{sortedCourses[1]?.parent_category_name}</h5>
                                                ) : (<></>)}
                                                <Image
                                                    src={sortedCourses[1]?.image ? `${DEMO_IMAGE_URL}${sortedCourses[1]?.image}` :featureImg2}
                                                    alt="Not Found"
                                                    className="img-fluid"
                                                    preview={false}
                                                />
                                                {sortedCourses[1]?.trial === 1 ? (
                                                    <div className="trail-available-container">
                                                        <p className="trial-available-text">Trial Available</p>
                                                    </div>
                                                ) : (<></>)}

                                                <div className="start-end-date-container">
                                                    <p className="start-enddate">{`${DateFormatter(sortedCourses[1]?.course_start)} - ${DateFormatter(sortedCourses[1]?.course_end)}`}</p>
                                                </div>
                                                <div className="cart-lock-div">
                                                    {paymentDetails.some((item) => item?.course_id === sortedCourses[1]?.id) ? (
                                                        <FaUnlock color="#fff" />
                                                    ) : (
                                                        <FaLock color="#fff" />
                                                    )}
                                                </div>
                                                <div className="wishlist-icon-div">


                                                    {
                                                        isUserLoggedIn ? (
                                                            <button
                                                                type="button"
                                                                className="btn wishlist-feature-button add-to-wishlist"
                                                                onClick={() => handleWishlistList(sortedCourses[1])}
                                                            >
                                                                {wishlistData?.some(
                                                                    (wish) => parseInt(wish?.id) === parseInt(sortedCourses[1]?.id)
                                                                ) ? (
                                                                    <GoHeartFill />
                                                                ) : (
                                                                    <FaRegHeart />
                                                                )}
                                                            </button>
                                                        ) : " "
                                                    }
                                                </div>
                                            </div>
                                            <div className="rightCardSection">
                                                <div className="text">
                                                    <h4>{sortedCourses[1]?.title}</h4>
                                                    {
                                                        sortedCourses[1]?.course_status === 1 && sortedCourses[1]?.is_sfc_status === 1 ? (


                                                            <button>
                                                                {sortedCourses[1]?.course_status === 1 && sortedCourses[1]?.is_sfc_status === 1
                                                                    ? "WSQ, SFC"
                                                                    : sortedCourses[1]?.course_status === 1
                                                                        ? "WSQ"
                                                                        : sortedCourses[1]?.is_sfc_status === 1
                                                                            ? "SFC"
                                                                            : ''
                                                                }
                                                            </button>


                                                        ) : ""
                                                    } 
                                                     {sortedCourses[1]?.approach === 1 ? <Button>Online</Button> : sortedCourses[1]?.approach === 2 ? <Button>Physical</Button> : sortedCourses[1]?.approach === 3 ? <Button>Combined</Button> : ""}
                                                </div>
                                               
                                                <div className="tags">
                                                    {sortedCourses[1]?.parent_category_name ? (
                                                        <span className="one">{sortedCourses[1]?.parent_category_name}</span>
                                                    ) : (<></>)}
                                                </div>
                                                <div className="link">
                                                    <Button className="view-course-recent-course-button" onClick={() => handleCourseData(sortedCourses[1])}>View Details</Button>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                )}

                                {sortedCourses[2] && (
                                    <div className="BottomSection">
                                        <Card>
                                            <div className="imgWrapper">
                                                {sortedCourses[2]?.parent_category_name ? (

                                                    <h5 className="main-category">{sortedCourses[2]?.parent_category_name}</h5>
                                                ) : (<></>)}
                                                <Image
                                                    src={sortedCourses[2]?.image ? `${DEMO_IMAGE_URL}${sortedCourses[2]?.image}`:featureImg2}
                                                    alt="Not Found"
                                                    className="img-fluid"
                                                    preview={false}
                                                />
                                                {sortedCourses[2]?.trial === 1 ? (
                                                    <div className="trail-available-container">
                                                        <p className="trial-available-text">Trial Available</p>
                                                    </div>
                                                ) : (<></>)}

                                                <div className="start-end-date-container">
                                                    <p className="start-enddate">{`${DateFormatter(sortedCourses[2]?.course_start)} - ${DateFormatter(sortedCourses[2]?.course_end)}`}</p>
                                                </div>
                                                <div className="cart-lock-div">
                                                    {paymentDetails.some((item) => item?.course_id === sortedCourses[2]?.id) ? (
                                                        <FaUnlock color="#fff" />
                                                    ) : (
                                                        <FaLock color="#fff" />
                                                    )}
                                                </div>

                                                <div className="wishlist-icon-div">


                                                    {
                                                        isUserLoggedIn ? (
                                                            <button
                                                                type="button"
                                                                className="btn wishlist-feature-button add-to-wishlist"
                                                                onClick={() => handleWishlistList(sortedCourses[2])}
                                                            >
                                                                {wishlistData?.some(
                                                                    (wish) => parseInt(wish?.id) === parseInt(sortedCourses[2]?.id)
                                                                ) ? (
                                                                    <GoHeartFill />
                                                                ) : (
                                                                    <FaRegHeart />
                                                                )}
                                                            </button>
                                                        ) : " "
                                                    }
                                                </div>
                                            </div>

                                            <div className="rightCardSection">
                                                <div className="text">
                                                    <h4>{sortedCourses[2]?.title}</h4>
                                                    {
                                                        sortedCourses[2]?.course_status === 1 && sortedCourses[2]?.is_sfc_status === 1 ? (


                                                            <span>
                                                                {sortedCourses[2]?.course_status === 2 && sortedCourses[2]?.is_sfc_status === 2
                                                                    ? "WSQ, SFC"
                                                                    : sortedCourses[2]?.course_status === 2
                                                                        ? "WSQ"
                                                                        : sortedCourses[2]?.is_sfc_status === 2
                                                                            ? "SFC"
                                                                            : ''
                                                                }
                                                            </span>


                                                        ) : ""
                                                    } 
                                                    {sortedCourses[2]?.approach === 1 ? <Button>Online</Button> : sortedCourses[2]?.approach === 2 ? <Button>Physical</Button> : sortedCourses[2]?.approach === 3 ? <Button>Combined</Button> : ""}
                                                </div>
                                                
                                                {sortedCourses[2]?.parent_category_name ? (
                                                    <div className="tags">
                                                        <span className="one">{sortedCourses[2]?.parent_category_name}</span>
                                                    </div>
                                                ) : (<></>)}
                                                <div className="link">
                                                    <Button className="view-course-recent-course-button" onClick={() => handleCourseData(sortedCourses[2])}>View Details</Button>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                )}
                            </div>
                        </Col>
                    </Row>
                </div>
            )}
        </div>
    );
};

export default RecentCourse;
