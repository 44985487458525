import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Input, Select, Button, Image, Table, Modal, Checkbox } from "antd";
import { MdOutlineCategory } from "react-icons/md";
import CoursePolicy from "./coursePolicy";
import { CiDollar } from "react-icons/ci";
import { BsFileCode } from "react-icons/bs";
import { CiUser } from "react-icons/ci";
import { LiaCalendarAltSolid } from "react-icons/lia";
import { BsInfoCircle } from "react-icons/bs";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { FaArrowRightLong } from "react-icons/fa6";
import { DEMO_IMAGE_URL } from '../../Helper';

export default function ViewCourse({ courseDetails, classes, pricingCategory }) {
    const [form] = Form.useForm();
    const { TextArea } = Input;
    const [helpModel, setHelpModel] = useState(false)
    const [fundingPrices, setFundingPrices] = useState([]);
    const [isWSQChecked, setIsWSQChecked] = useState(courseDetails?.course_status == 1 ? true : false);
    const [coursePolicy, setCoursePolicy] = useState({
        refund_policy: courseDetails?.refund_policy ? courseDetails?.refund_policy : "",
        is_premium: courseDetails?.is_premium === "paid" ? 0 : 1,
        trial: courseDetails?.trial === 1 ? 1 : 0,
        trial_days: courseDetails?.trial_days ? parseInt(courseDetails?.trial_days) : 0,
        refundable: courseDetails?.refundable

    });
    // const courseStatus = courseDetails?.course_status?.split(',');
    // const hasWSQ = courseStatus.includes('WSQ');
    // const hasSFC = courseStatus.includes('SFC');
    // const [checkedWSQ, setCheckedWSQ] = useState(hasWSQ);
    // const [checkedSFC, setCheckedSFC] = useState(hasSFC);
    // useEffect(() => {
    //     setCheckedWSQ(hasWSQ);
    //     setCheckedSFC(hasSFC);
    // }, [courseDetails]);
    const handlehelp = () => {
        setHelpModel(true)
    }
    const handleHelpCancle = () => {
        setHelpModel(false)
    }
    const handlehelpOk = () => {
        setHelpModel(false)
    }

    const slotsColumn = [
        {
            title: "S.No.",
            dataIndex: "S.No.",
            key: "S.No.",
            render: (text, record, index) => (
                <p className="sno-text">
                    {String(index + 1).padStart(2, "0")}
                </p>
            ),

        },
        {
            title: "Week Day",
            dataIndex: "week_day",
            key: "week_day",
            render: (text, record) => (
                <p className="week-day">
                    {record?.week_day}
                </p>
            ),
        },
        {
            title: "Start Time",
            dataIndex: "start_time",
            key: "start_time",
            render: (text, record) => (
                <p className="start-time">
                    {record?.start_time}
                </p>
            ),
        },
        {
            title: "End Time",
            dataIndex: "end_time",
            key: "end_time",
            render: (text, record) => (
                <p className="end-time">
                    {record?.end_time}
                </p>
            ),
        },
    ];

    const handleDownload = () => {
        const fileUrl = `${DEMO_IMAGE_URL}${courseDetails?.document}`
        const link = document.createElement("a");
        link.href = fileUrl;
        link.download = courseDetails?.document;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const approachOptions = [
        {
            label: 'Online',
            value: 1,
        },
        {
            label: 'Physical',
            value: 2,
        },
        {
            label: 'Combined',
            value: 3,
        },
    ]

    useEffect(() => {
        if (courseDetails?.skill_funding?.length > 0) {
            const initialPrices = courseDetails?.skill_funding?.map(item => ({
                id: item?.id,
                price_category_id: item?.price_category_id,
                discount: item?.discount,
            }));
            setFundingPrices(initialPrices);
        }
    }, [courseDetails?.skill_funding]);

    const renderFundingPrice = () => {
        const getFilteredOptions = (currentIndex) => {
            const selectedValues = fundingPrices
                .filter((_, idx) => idx !== currentIndex)
                .map(row => row.price_category_id);

            return pricingCategory
                .filter(option => !selectedValues.includes(option.id))
                .map(option => ({
                    value: option.id,
                    label: option.name,
                }));
        };

        const handleInputChange = (e, index) => {
            setFundingPrices(fundingPrices.map((row, idx) => idx === index ? { ...row, discount: e.target.value } : row));
        };
        return (
            <>
                <p
                    style={{
                       textAlign: "start",
                        fontFamily: "poppins",
                        fontSize: "16px",
                        marginBottom: "16px",
                        fontWeight: "500",
                        color:"#434343"
                    }}
                >
                    Funding Price
                </p>
                {fundingPrices.map((row, index) => (
                    <Row className="funding-price-row row align-items-center" key={index}>
                        <Col className="funding-price-col" md={24}>

                            <Row className='align-items-center'>
                                <Col md={12} className="input_right_spacing">
                                    <Select
                                        disabled
                                        placeholder="Select funding category"
                                        className="input-box-login funding-select"
                                        value={row.price_category_id}
                                        options={getFilteredOptions(index)}
                                    />
                                </Col>
                                <Col md={12} className="input_left_spacing ps-4">
                                    <Input
                                        disabled
                                        type="number"
                                        placeholder="Enter skill funding"
                                        value={row.discount}
                                        style={{ padding: '0.7rem', borderRadius: '11px' }}
                                        onChange={(e) => handleInputChange(e, index)}
                                        className='funding-price-input'
                                        onKeyPress={(e) => {
                                            if (e.key === '-' || e.key === 'e') {
                                                e.preventDefault();
                                            }
                                        }}
                                        min="0"
                                        required
                                        status={!row.price && row.touched ? 'error' : ''}
                                    />
                                </Col>

                            </Row>
                        </Col>
                    </Row>

                ))}

            </>
        );
    }
    return (
        <div>
            <Form
                name="basic"
                initialValues={{
                    title: courseDetails?.title,
                    category: courseDetails?.parent_category_name || "No value selected",
                    sub_category: courseDetails?.sub_category_name || "No value selected",
                    child_category: courseDetails?.child_category_name || "No value selected",
                    visibility: courseDetails?.visibility || "No value selected",
                    course_class: courseDetails?.class_level ? parseInt(courseDetails?.class_level) : "No value selected",
                    about_this_course: courseDetails?.about_course || "No description entered by vendor",
                    fee: courseDetails?.fee || "No fee available",
                    special_instruction: courseDetails?.special_instruction || "No specail instruction entered by vendor",
                    instructor_name: courseDetails?.instructor[0]?.instructor_name || "No instructor name entered by vendor",
                    work_experience: courseDetails?.instructor[0]?.work_experience || "No work experience available",
                    about: courseDetails?.instructor[0]?.about || "No about entered by vendor",
                    course_start: courseDetails?.course_start || "",
                    course_end: courseDetails?.course_end || "",
                    postal_code: courseDetails?.postal_code ? courseDetails?.postal_code : "No postal code entered by the vendor",
                    address: courseDetails?.address ? courseDetails?.address : "No address entered by the vendor",
                    course_code: courseDetails?.course_code ? courseDetails?.course_code : "No course code added by the vendor",
                    wsq: courseDetails?.course_status == 1 ? true : false,
                    sfc: courseDetails?.is_sfc_status == 1 ? true : false,
                    trial_days: courseDetails?.trial_days,
                    select_approach: courseDetails?.approach === 0 ? "No value selected" : courseDetails?.approach

                }}
                autoComplete="off"
                className="add-new-course-form"
                layout="vertical"
            >
                <Row className="form-row">
                    <Col md={24} className="custom-padding laptop-padd">
                        <Form.Item
                            className="form-input-class"
                            label="Title"
                            name="title"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter title.",
                                },
                            ]}
                        >
                            <Input
                                disabled
                                value={courseDetails?.title}
                                className="input-box-login"
                                placeholder="Type here....."
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col md={8} className="input-new-course">
                        <Form.Item
                            className="form-input-class"
                            name="category"
                            label="Select Categories"
                        >
                            <Select
                                disabled
                                className="input-box-login select-category"
                                placeholder="Category"
                                prefix={<MdOutlineCategory />}

                            >
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col md={8} className="input-new-course">
                        <Form.Item
                            className="form-input-class"
                            name="sub_category"
                            label="Select Sub-Categories"
                        >
                            <Select
                                disabled
                                className="input-box-login sub-select-category"
                                placeholder="Sub-Categories"
                                prefix={<MdOutlineCategory />}
                            >

                            </Select>
                        </Form.Item>
                    </Col>

                    <Col md={8} className="input-new-course">
                        {/* as per the data this id sub Categories */}
                        <Form.Item
                            className="form-input-class"
                            name="child_category"
                            label="Select Child Categories"
                        >
                            <Select
                                disabled
                                className="input-box-login sub-select-category"
                                placeholder="Child-Categories"
                                prefix={<MdOutlineCategory />}
                            >
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col
                        md={8}
                        className="custom-padding fee-instruction-upload-col special-instruction"
                    >

                        <Form.Item
                            className="form-input-class"
                            name="visibility"
                            label="Visibility"
                        >
                            <Select
                                disabled
                                className="input-box-login select-category"
                                placeholder="Choose Visibility"
                                prefix={<MdOutlineCategory />}
                                label
                            >
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col md={8} className="input-new-course">
                        <Form.Item
                            className="form-input-class"
                            name="course_class"
                            label='Select Session'
                        >
                            <Select
                                disabled
                                className="input-box-login select-class"
                                placeholder="Class"
                                prefix={<MdOutlineCategory />}
                            >
                                {classes &&
                                    classes.map((classe) => (
                                        <Select.Option key={classe.id} value={classe.id}>
                                            {classe.name}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col md={8} className="input-new-course input_left_spacing input_right_spacing">
                        <Form.Item
                            className="form-input-class"
                            name="select_approach"
                            label="Select Approach"

                        >
                            <Select
                                disabled
                                placeholder="Select Approach"
                                className="input-box-login select-class"
                                options={approachOptions}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row className="form-row">
                    <Col md={24} className="custom-padding laptop-padd">
                        <Form.Item
                            className="form-input-class"
                            name="about_this_course"
                            label="Description"
                        >
                            <TextArea
                                disabled
                                className="leave-comment-text-area"
                                rows={4}
                                placeholder="Type here....."
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row className="form-row">
                    <Col md={24} className="custom-padding laptop-padd">
                        <Form.Item
                            className="form-input-class"
                            name="school_images"
                            valuePropName="file"
                            label="Organisation Image"
                        >
                            {courseDetails?.school_images?.length > 0 ? (
                                <div className='view_image_row'>
                                    {courseDetails?.school_images?.map((images, index) => {
                                        return (
                                            <div key={index} className='view_image_col'>
                                                <Image className='upload-image view_uploaded_image' src={`${DEMO_IMAGE_URL}${images}`} alt='course image' />
                                            </div>
                                        )
                                    })}
                                </div>
                            ) : (<><p className="none_info_title">No image added by the vendor</p></>)}

                        </Form.Item>
                    </Col>
                </Row>

                <Row className="form-row fee-instruction-upload-row">
                    <Col md={12}
                        className="custom-padding fee-instruction-upload-col fee-include"
                    >
                        <Form.Item
                            className="form-input-class"
                            name="fee"
                            label='Fee'
                        >
                            <Input
                                disabled
                                type="number"
                                className="input-box-login"
                                placeholder="Type here....."
                                prefix={<CiDollar />}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={12}
                        className="custom-padding fee-instruction-upload-col special-instruction"
                    >
                        <Form.Item
                            className="form-input-class"
                            name="special_instruction"
                            label="Special Instruction (Optional)"
                        >
                            <Input
                                disabled
                                className="input-box-login"
                                prefix={<BsFileCode />}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={12} className="custom-padding fee-instruction-upload-col fee-include">
                        <Form.Item
                            name="course_start"
                            label='Start Date'>
                            <Input disabled />
                        </Form.Item>
                    </Col>

                    <Col md={12} className="custom-padding fee-instruction-upload-col fee-include">
                        <Form.Item
                            name="course_end"
                            label='End Date'>
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col md={12} className="input-new-course">
                        <Form.Item
                            className="form-input-class"
                            name="postal_code"
                            label='Postal Code'
                        >
                            <Input
                                disabled
                                className="input-box-login"
                            />
                        </Form.Item>
                    </Col>
                    <Col md={12} className="input-new-course">
                        <Form.Item
                            className="form-input-class"
                            name="address"
                            label="Address"
                        >
                            <Input
                                disabled
                                className="input-box-login"
                            />
                        </Form.Item>
                    </Col>
                    <Col md={12} className="input-new-course">
                        <Form.Item
                            className="form-input-class"
                            name="course_code"
                            label='Item Code'
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter item code.",
                                },
                            ]}
                        >
                            <Input
                                disabled
                                className="input-box-login"
                                placeholder="Item Code"
                            />
                        </Form.Item>
                    </Col>
                    <Col md={12} className="input-new-course fee-instruction-upload-col upload-image ">
                        <Form.Item
                            className="form-input-class"
                            name="course_image"
                            label="Item Image"
                        >
                            {courseDetails?.image ? (
                                <Image className='course_item_image' src={`${DEMO_IMAGE_URL}${courseDetails?.image}`} />
                            ) : (
                                <div className='none_info_title'>No Image added by the vendor</div>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                {/* course divder */}
                <div className='course_details_divider'>
                </div>
                <Row className="form-row eligibility_row">
                    <Col md={24}>
                        <p className="eligibility_heading">
                            Eligibility
                        </p>
                    </Col>
                    <Col md={8} className="input-new-course input_left_spacing input_right_spacing">
                        <div className="d-flex justify-content-center align-items-center">
                            <Form.Item
                                className="form-input-class me-3"
                                name="wsq" valuePropName="checked" >
                                <Checkbox
                                    disabled
                                    key="wsq"
                                    value="wsq"
                                >
                                    WSQ
                                </Checkbox>
                            </Form.Item>
                            <Form.Item
                                className="form-input-class"
                                name="sfc" valuePropName="checked" >
                                <Checkbox
                                    disabled
                                    key="sfc"
                                    value="sfc"
                                >
                                    SFC
                                </Checkbox>
                            </Form.Item>
                        </div>
                    </Col>

                    <Col md={24} className="fee-instruction-upload-col fee-include funding_col_main">
                        {coursePolicy?.is_premium === 0 ?renderFundingPrice() : <></>}
                    </Col>
                </Row>
                {/* course divder */}
                <div className='course_details_divider'>
                </div>

                <Row className="form-row instructor-details-container">
                    <Col md={24} className='custom-padding'>
                        <p className="instructor-detains">
                            Instructor’s Detail
                        </p>
                    </Col>
                    <Col md={24}
                        className="input-new-course fee-instruction-upload-col upload-image"
                    >
                        <Form.Item
                            className="form-input-class"
                            name="view_profile_photo"
                            label="Profile Photo"
                        >
                            {courseDetails?.instructor[0]?.profile_photo ? (
                                <Image className='course_item_image ' src={`${DEMO_IMAGE_URL}${courseDetails?.instructor[0]?.profile_photo}`} />
                            ) : (
                                <div className='none_info_title'>No image added by the vendor</div>
                            )}
                        </Form.Item>
                    </Col>
                    <Col md={12}
                        className="input-new-course fee-instruction-upload-col upload-image"
                    >
                        <Form.Item
                            className="form-input-class"
                            name="instructor_name"
                            label="Instructor Name"
                        >
                            <Input
                                disabled
                                className="input-box-login"
                                prefix={<CiUser color="#7c8597" />}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={12} className="input-new-course">
                        <Form.Item
                            className="form-input-class"
                            name="work_experience"
                            label="Work Experience"
                        >
                            <Input
                                disabled
                                type="number"
                                className="input-box-login"
                                prefix={<LiaCalendarAltSolid color="#7c8597" />}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={24} className="input-new-course">
                        <Form.Item
                            className="form-input-class"
                            name="about"
                            label="About"
                        >
                            <Input
                                disabled
                                className="input-box-login"
                                placeholder="Type something"
                                prefix={<BsInfoCircle color="#7c8597" />}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                {/* course divder */}
                <div className='course_details_divider'>
                </div>

                <div className="course-faq-container custom-padding mb-0">
                    <p className="frequently-asked-que mb-1">
                        Frequently Asked Questions (FAQ’s)❓
                    </p>
                </div>
                {courseDetails?.vendor_faq?.length > 0 ? (
                    <>
                        <Row className="faq-box-shadow-none">
                            {courseDetails?.vendor_faq?.map((item) => (
                                <Col md={12}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<FaArrowRightLong />}
                                            aria-controls="panel1-content"
                                            id={item.key}
                                        >
                                            {item.question}
                                        </AccordionSummary>
                                        <AccordionDetails>{item.answer}</AccordionDetails>
                                    </Accordion>
                                </Col>
                            ))}
                        </Row>
                    </>
                ) : (
                    <p className='nothing_added_txt custom-padding'>NO FAQ added by the vendor</p>
                )}

                {/* course divder */}
                <div className='course_details_divider'>
                </div>

                <CoursePolicy setCoursePolicy={setCoursePolicy} coursePolicy={coursePolicy} handlehelp={handlehelp} type={"view"} />

                {/* course divder */}
                <div className='course_details_divider'>
                </div>

                <p className='custom-padding mb-1'>
                    Item Slots
                </p>
                {courseDetails?.time_slots?.length > 0 ? (<Table bordered columns={slotsColumn} dataSource={courseDetails?.time_slots} className='custom-padding course_info_table' />) : (<p className='nothing_added_txt custom-padding'>No slots available</p>)}
                { }
                {/* course divder */}
                <div className='course_details_divider'>
                </div>
                <Row className="form-row mt-3">
                    <Col md={24} className="input-new-course upload-documents">
                        <p className='course_main_title'>
                            Document
                        </p>
                        <Form.Item
                            name="course_document"
                        >
                            {courseDetails?.document ? (
                                <Button onClick={handleDownload} className='download_docs_btn'>Download Document</Button>
                            ) : (<p className='nothing_added_txt'>No Document uploaded</p>)}
                        </Form.Item>
                    </Col>
                </Row>
                {/* course divder */}
                <div className='course_details_divider'>
                </div>
                {courseDetails?.video && courseDetails?.thumbnail ? (
                    <Row className="form-row">
                        <Col md={24} className="input-new-course">
                            <p
                                style={{
                                    textAlign: "start",
                                    fontFamily: "POPPINS",
                                }}
                            >
                                Video and Thumbnail
                            </p>

                         

                            <video width="400" controls poster={`${DEMO_IMAGE_URL}${courseDetails?.thumbnail}`}>
                                <source src={`${DEMO_IMAGE_URL}${courseDetails?.video}`} />
                            </video>
                        </Col>
                    </Row>
                ) : (<></>)}
            </Form>
            <Modal
                title="Standard list"
                open={helpModel}
                onOk={handlehelpOk}
                onCancel={handleHelpCancle}
                centered
                className='course_policy_popup'

            >
                <div>
                    <h3>Eligibility for Refund</h3>
                    <p><strong>Timeframe:</strong> Refunds are available if the request is made within [X] days of purchase or before the course starts.</p>
                    <p><strong>Course Access:</strong> No refunds will be issued after the course has started or if access to course materials has been granted.</p>

                    <h3>Refund Request Process</h3>
                    <p><strong>How to Request:</strong> To request a refund, please contact our support team at [support email/phone number] with your order details and reason for the refund.</p>
                    <p><strong>Required Information:</strong> Include your order number, the date of purchase, and any other relevant information to process your request.</p>

                    <h3>Refund Amount</h3>
                    <p><strong>Full Refund:</strong> A full refund is available if the request is made within [X] days of purchase and before accessing the course materials.</p>
                    <p><strong>Partial Refund:</strong> A partial refund may be available if the request is made after accessing the course but before [specific time or condition].</p>
                    <p><strong>Non-Refundable:</strong> Certain fees, such as administrative or processing fees, may be non-refundable.</p>

                    <h3>Processing Time</h3>
                    <p><strong>Refund Processing:</strong> Refunds will be processed within [X] business days of approval. The time it takes for the refund to appear in your account may vary depending on your payment method and financial institution.</p>

                    <h3>Non-Eligibility for Refund</h3>
                    <p><strong>No Show:</strong> No refunds will be issued for failure to attend the course without prior notice.</p>
                    <p><strong>Content Downloads:</strong> Once downloadable content has been accessed or downloaded, it may not be eligible for a refund.</p>

                    <h3>Cancellation by Provider</h3>
                    <p><strong>Course Cancellation:</strong> If we cancel the course for any reason, a full refund will be issued, or you will be given the option to transfer to a different course.</p>
                    <p><strong>Rescheduling:</strong> If the course is rescheduled, you will be offered the option to attend the rescheduled course or request a refund.</p>

                    <h3>Refund Policy Changes</h3>
                    <p><strong>Policy Updates:</strong> We reserve the right to change this refund policy at any time. Any changes will be communicated to you via email or posted on our website.</p>

                    <h3>Contact Information</h3>
                    <p><strong>Support Contact:</strong> For any questions or concerns regarding the refund policy, please contact us at [support email/phone number].</p>
                </div>

            </Modal>
        </div>
    )
}
