import { Button, Modal, Input, Row, Col } from 'antd'
import React, { useState } from 'react'
import * as uploadServices from '../../services/uploadServices'
import { useDispatch } from 'react-redux'


const AddCustom = () => {
    const dispatch = useDispatch();
    const [openModel, setOpenModel] = useState(false)
    const [days, setDays] = useState('');
    const [courseAmount, setCourseAmount] = useState('');
    const handleModel = () => {
        setOpenModel(true)
    }
    const handleOk = () => {
        setOpenModel(false)
        setDays('')
        setCourseAmount('');
    }
    const handleCancel = () => {
        setOpenModel(false);
        setDays('')
        setCourseAmount('');
    };

    const fetchData = async () => {
        if (days !== '') {

            try {
                dispatch(uploadServices.getCalculatedCustom({ days })).then((response) => {

                    setCourseAmount(response.totalAmount);
                });

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    };

    fetchData();
    const handleDaysChange = (e) => {
        setDays(e.target.value);
    }

    return (
        <>
            <div>
                <Button onClick={handleModel}>open custom modal</Button>

            </div>

            <Modal
                className='CustomModal'
                title="Add Custom Duration"
                open={openModel}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleOk}>
                        Done
                    </Button>,

                ]}
            >
                <p>
                    Set a custom items duration to tailor the learning experience to your students' needs. Adjust start and end dates now.
                </p>
                <Row>
                    <Col span={12} >
                        <Input
                            placeholder="Enter days count"
                            type='number'
                            value={days}
                            onChange={(e) => setDays(e.target.value)}
                            onKeyUp={handleDaysChange}
                            style={{ marginBottom: '1rem', width: "90%" }}
                        />
                    </Col>
                    <Col span={12}>
                        <Input
                            placeholder="Item amount"
                            value={courseAmount}
                            onChange={(e) => setCourseAmount(e.target.value)}
                            style={{ width: "90%" }}
                        />
                    </Col>
                </Row>


            </Modal>
        </>
    )
}

export default AddCustom