import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Input, Form, Button } from "antd";
import { HiOutlineBuildingOffice } from "react-icons/hi2";
import { CiGlobe } from "react-icons/ci";
import { IoLocationOutline } from "react-icons/io5";
import { BsCloudUpload } from "react-icons/bs";
import { CiImageOn } from "react-icons/ci";
import { BsSuitcaseLg } from "react-icons/bs";
import { FaRegAddressCard } from "react-icons/fa";
import { PiHashFill } from "react-icons/pi";
import { FaSackDollar } from "react-icons/fa6";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import Swal from "sweetalert2";

import {
	align,
	font,
	fontColor,
	fontSize,
	formatBlock,
	hiliteColor,
	horizontalRule,
	lineHeight,
	list,
	paragraphStyle,
	table,
	template,
	textStyle,
	image,
	link,
} from "suneditor/src/plugins";

export default function RegistrationStepTwo({ onData, schoolInfo, goBack }) {
	const editorRef = useRef(null);
	const SunEditorOpts = {
		showPathLabel: false,
		imageMultipleFile: true,
		imageWidth: "150px",
		imageHeight: "150px",
		height: "auto",
		minHeight: "200px",
		requestHeaders: {
			"X-Sample": "sample",
		},
		plugins: [
			align,
			font,
			fontColor,
			fontSize,
			formatBlock,
			hiliteColor,
			horizontalRule,
			lineHeight,
			list,
			paragraphStyle,
			table,
			template,
			textStyle,
			image,
			link,
		],
		buttonList: [
			["undo", "redo"],
			["font", "fontSize", "formatBlock"],
			["paragraphStyle"],
			["bold", "underline", "italic", "strike", "subscript", "superscript"],
			["fontColor", "hiliteColor"],
			["removeFormat"],
			["align", "horizontalRule", "lineHeight"],
			["outdent", "indent"],
			["list"],
			["table", "link", "image"],
			["fullScreen", "showBlocks", "preview"],
		],
		formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
		font: [
			"Arial",
			"Calibri",
			"Comic Sans",
			"Courier",
			"Garamond",
			"Georgia",
			"Impact",
			"Lucida Console",
			"Palatino Linotype",
			"Segoe UI",
			"Tahoma",
			"Times New Roman",
			"Trebuchet MS",
			"Roboto",
		],
	};

	const [editorContent, setEditorContent] = useState("");
	const [file, setFile] = useState('');

	const onFinish = (values) => {



		onData(values, editorContent, file);

	};

	const onFinishFailed = () => {
		// console.info("finish failed");
	};

	const handleEditorChange = (content) => {
		setEditorContent(content);
	};

	const handleFileChange = (e) => {
		const selectedFile = e.target.files[0];
		setFile(selectedFile);
	};

	useEffect(() => {
		if (schoolInfo?.about_school && editorRef.current) {
			editorRef.current.setContents(schoolInfo.about_school);
		}
	}, [schoolInfo?.about_school]);

	return (
		<Row className="step-one-container">
			<h2 className="vendor-register-step-one-head">
				Organisation Details
			</h2>
			<Form
				className="step-one-form"
				name="basic"
				labelCol={{ span: 8 }}
				wrapperCol={{ span: 24 }}
				initialValues={{
					school_name: schoolInfo?.school_name || "",
					website_url: schoolInfo?.website_url || "",
					address: schoolInfo?.address || "",
					logo: schoolInfo?.logo?.name || "",
					business_type: schoolInfo?.business_type || "",
					business_identification_type:
						schoolInfo?.business_identification_type || "",
					business_identification_no:
						schoolInfo?.business_identification_no || "",
					gst_no: schoolInfo?.gst_no || "",
					about_school: schoolInfo?.about_school || "",
				}}
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				autoComplete="off"
			>
				<Row>
					<Col md={12} className="custom-padding">
						<p style={{ textAlign: "start", fontFamily: "POPPINS" }}>
							Organisation Name
						</p>
						<Form.Item
							className="form-input-class"
							name="school_name"
						>
							<Input
								className="input-box-login"
								placeholder="Enter school name here"
								prefix={<HiOutlineBuildingOffice className="form-icons" />}
							/>
						</Form.Item>
					</Col>
					<Col md={12} className="custom-padding">
						<p style={{ textAlign: "start", fontFamily: "POPPINS" }}>
							Website URL
						</p>
						<Form.Item
							className="form-input-class"
							name="website_url"
							rules={[
								{
									pattern:
										/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/,
									message: "Please enter a valid URL.",
								},
							]}
						>
							<Input
								className="input-box-login"
								placeholder="Enter website url"
								prefix={<CiGlobe className="form-icons" />}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col md={12} className="custom-padding">
						<p style={{ textAlign: "start", fontFamily: "POPPINS" }}>Address</p>
						<Form.Item
							className="form-input-class"
							name="address"
						>
							<Input
								className="input-box-login"
								placeholder="Enter address here"
								prefix={<IoLocationOutline className="form-icons" />}
							/>
						</Form.Item>
					</Col>
					<Col md={12} className="custom-padding">
						<p style={{ textAlign: "start", fontFamily: "POPPINS" }}>Logo</p>
						<Form.Item
							className="form-input-class"
							name="logo"
						>
							<label
								htmlFor="logo-upload"
								className="custom-upload-logo d-flex justify-content-between"
							>
								<span className="custom-input-logo-left d-flex align-items-center">
									<CiImageOn className="form-icons" />
									{file ? file?.name : "Upload Logo"}
								</span>
								<span className="custom-input-logo-right">
									<BsCloudUpload className="form-icons" />
								</span>
							</label>
							<Input
								type="file"
								id="logo-upload"
								className="input-box-login"
								hidden
								style={{ display: "none" }}
								onChange={handleFileChange}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col md={12} className="custom-padding">
						<p style={{ textAlign: "start", fontFamily: "POPPINS" }}>
							Business Type
						</p>
						<Form.Item
							className="form-input-class"
							name="business_type"

						>
							<Input
								className="input-box-login"
								placeholder="Enter business type"
								prefix={<BsSuitcaseLg className="form-icons" />}
							/>
						</Form.Item>
					</Col>
					<Col md={12} className="custom-padding">
						<p style={{ textAlign: "start", fontFamily: "POPPINS" }}>
							Business Identification Type
						</p>
						<Form.Item
							className="form-input-class"
							name="business_identification_type"

						>
							<Input
								className="input-box-login"
								placeholder="Enter Identification Type"
								prefix={<FaRegAddressCard className="form-icons" />}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col md={12} className="custom-padding">
						<p style={{ textAlign: "start", fontFamily: "POPPINS" }}>
							Business Identification No.
						</p>
						<Form.Item
							className="form-input-class"
							name="business_identification_no"

						>
							<Input
								className="input-box-login"
								placeholder="Enter Identification no."
								prefix={<PiHashFill className="form-icons" />}
							/>
						</Form.Item>
					</Col>
					<Col md={12} className="custom-padding">
						<p style={{ textAlign: "start", fontFamily: "POPPINS" }}>GST No.</p>
						<Form.Item
							className="form-input-class"
							name="gst_no"

						>
							<Input
								className="input-box-login"
								placeholder="Enter GST No."
								prefix={<FaSackDollar className="form-icons" />}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col md={24} className="custom-padding">
						<p style={{ textAlign: "start", fontFamily: "POPPINS" }}>
							About Us
						</p>
						<Form.Item
							className="form-input-class"
							name="about_school"
						>
							<div className="suneditor-outer-new px-0">
								<SunEditor
									getSunEditorInstance={(editor) => {
										editorRef.current = editor;
									}}
									setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
									setOptions={SunEditorOpts}
									onChange={handleEditorChange}
								/>
							</div>
						</Form.Item>
						<Row>
							<Col md={12}>
								<Form.Item
									className="mb-0"
									wrapperCol={{ offset: 8, span: 24 }}
								>
									<Button
										className="register-button button-register go-back-vendor"
										htmlType="button"
										onClick={goBack}
									>
										Back
									</Button>
								</Form.Item>
							</Col>
							<Col md={12}>
								<Form.Item
									className="mb-0"
									wrapperCol={{ offset: 8, span: 24 }}
								>
									<Button
										className="register-button button-register next-button"
										htmlType="submit"
									>
										Next
									</Button>
								</Form.Item>
							</Col>
						</Row>
					</Col>
				</Row>
			</Form>
		</Row>
	);
}
