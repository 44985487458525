import {
    Col,
    Row,
    Image,
    Rate,
    Pagination,
    Tooltip,
    Button,
    Modal,
    Form,
    Input,
    Spin
} from "antd";
import React, { useState, useEffect } from "react";
import Sidebar from "../../../components/Sidebar/sidebar";
import { IoEyeOutline } from "react-icons/io5";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaCircleInfo } from "react-icons/fa6";
import { Coins, StarImage } from "../../../constant/images";
import { MdOutlineWatchLater } from "react-icons/md";
import * as getServices from "../../../services/getServices";
import { useDispatch, useSelector } from "react-redux";
import * as updateServices from "../../../services/updateServices";
import Swal from "sweetalert2";
import { LoadingOutlined } from '@ant-design/icons';
import { featureImg2 } from "../../../constant/images"
import {DEMO_IMAGE_URL} from "../../../Helper"


export default function MyReviews() {
    const userData = useSelector((state) => state.user.userData);
    const TextArea = Input;
    const dispatch = useDispatch();
    const [reviews, setReviews] = useState([]);
    const [isEditReviewModalOpen, setIsEditReviewModalOpen] = useState(false);
    const [formData, setFormData] = useState([]);
    const [rating, setRating] = useState();
    const [editButton, setEditButton] = useState(false);
    const [loader, setLoader] = useState(false);
    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [totalReviews, setTotalReviews] = useState(0);


    useEffect(() => {
        setLoader(true)
        dispatch(
            getServices.getUserReviews(pageSize,current)
        )
            .then((res) => {
                setLoader(false)
                setReviews(res?.reviews?.data);
                setTotalReviews(res?.reviews?.total)
            })
            .catch((err) => {
                setLoader(false)
                console.error(err);
            });
    }, [pageSize,current]);

    const onChange = (page) => {
        setCurrent(page);
    };

    const onPageSizeChange = (current, size) => {
        setPageSize(size);
        setCurrent(1); 
    }

    const DateFormatter = (date) => {
        const inputDate = date;

        const dateObj = new Date(inputDate);

        const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];

        const day = dateObj.getDate();
        const month = monthNames[dateObj.getMonth()];
        const year = dateObj.getFullYear();

        const formatDate = `${day} ${month} ${year}`;
        return formatDate;
    };

    const generatePageSizeOptions = (total) => {
        const options = [];
        const maxPageSize = Math.min(total, 100);

        for (let i = 5; i <= maxPageSize; i += 5) {
            options.push(i);
        }
        if (total > 5 && !options.includes(10)) {
            options.push(10);
        }

        if (total > 10 && !options.includes(15)) {
            options.push(15);
        }
        return options.sort((a, b) => a - b);
    };

    const pageSizeOptions = generatePageSizeOptions(totalReviews);

    const showEditReviewModal = (reviewData, type) => {
        setFormData(reviewData);
        setIsEditReviewModalOpen(true);
        if (type === "edit") {
            setEditButton(true);
        }
    };
    const handleEditReviewOk = () => {
        setIsEditReviewModalOpen(false);
        setEditButton(false);
    };
    const handleEditReviewCancel = () => {
        setIsEditReviewModalOpen(false);
        setEditButton(false);

    };

    const handleDeleteReview = (review) => {
        Swal.fire({
            title: "Delete Review",
            text: "Are you sure you want to delete?",
            icon: "info",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, keep it",
        })
            .then((result) => {
                if (result.isConfirmed) {
                    dispatch(updateServices.deleteUserReview(review?.id))
                        .then((response) => {
                            Swal.fire("Success", "Review deleted successfully", "success");
                        })
                        .then(() => {
                            window.location.reload();
                        })
                        .catch((error) => {
                            Swal.fire("Failed", "Unable to delete review.", "error");
                        });
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire("Cancelled", "Your review is safe :)", "error");
                }
            })
            .catch((error) => {
                Swal.fire("Failed", "Unable to perform the action.", "error");
            });
    };

    const onFinish = (values) => {

        let formDataForm;
        formDataForm = {
            user_id: formData?.user_id,
            name: values?.fullName,
            email: values?.email,
            comment: values?.comment,
            rating: rating !== undefined ? rating.toString() : rating,
            status: "pending",
            course_id: formData?.course_id,
            vendor_id: formData?.vendor_id,
        };

        Swal.fire({
            title: "Edit Review",
            text: "Are you sure you want to update?",
            icon: "info",
            showCancelButton: true,
            confirmButtonText: "Yes, update it!",
            cancelButtonText: "No, keep it",
        })
            .then((result) => {
                if (result.isConfirmed) {
                    dispatch(updateServices.editUserReview(formData?.id, formDataForm))
                        .then((response) => {
                            Swal.fire("Success", "Review updated successfully", "success");
                        })
                        .then(() => {
                            setFormData([])
                            window.location.reload();
                        })
                        .catch((error) => {
                            Swal.fire("Failed", "Unable to update review.", "error");
                        });
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire("Cancelled", "Your review is safe :)", "error");
                }
            })
            .catch((error) => {
                Swal.fire("Failed", "Unable to perform the action.", "error");
            });

    };

    const onFinishFailed = () => {   
    };



    return (
        <>
            <Row>
                <Col xl={4} className="d-xl-block d-md-none">
                    <Sidebar />
                </Col>
                <Col xl={20} md={24}>
                    <div className="my-review-container">
                        <Row className="top-my-review">
                            <Col className="top-first-child" md={10}>
                                <p className="top-my-reward-points">My Reward Points</p>
                            </Col>
                            <Col md={4}>
                                <Image
                                    src={StarImage}
                                    alt="Not Found"
                                    className="img-fluid start-image"
                                    preview={false}
                                />
                            </Col>
                            <Col className="coin-reward" md={10}>
                                <Image
                                    src={Coins}
                                    alt="Not Found"
                                    className="img-fluid coin-image"
                                    preview={false}
                                />
                                <p className="reward-points-seven-hun">700 Reward Points</p>
                            </Col>
                        </Row>
                        <p className="my-review-head">My Reviews</p>
                        <div className="internal-my-review-container">
                            {loader ? (
                                <div className="d-flex justify-content-center align-items-center mt-4 mb-4">
                                    <Spin
                                        indicator={
                                            <LoadingOutlined
                                                style={{
                                                    fontSize: 48,
                                                }}
                                                spin
                                            />

                                        }
                                    />
                                </div>
                            ) : (
                                <>
                                    {reviews
                                    .map((content, index) => {
                                            return (
                                                <>
                                                    <Row className="my-review-content-with-rate"key={index}>
                                                        <Col className="border-right" md={3}>
                                                            <Image
                                                                src={content?.course_image ? `${DEMO_IMAGE_URL}${content.course_image}` : featureImg2}
                                                                alt="Not Found"
                                                                className="img-fluid my-reviews-image"
                                                                preview={false}
                                                            />
                                                        </Col>
                                                        <Col md={5} className="review-padding">
                                                            <div className="outer-review">
                                                                <p className="rating-content">
                                                                    {parseInt(content?.rating)}
                                                                </p>
                                                                <Rate
                                                                    className="rating-star"
                                                                    defaultValue={parseInt(content?.rating)}
                                                                    disabled={true}
                                                                />
                                                            </div>

                                                            <p className="blue-text">
                                                                {content?.category_name ? content?.category_name : "Web Development"}
                                                            </p>
                                                            <div className="date-text-with-icon">
                                                                <MdOutlineWatchLater
                                                                    className="watch-icon"
                                                                    color="#106AB3"
                                                                />
                                                                <p className="date-text">
                                                                    {DateFormatter(content?.created_at)}
                                                                </p>
                                                            </div>
                                                            {content?.status === "approved" ? (
                                                                <>
                                                                    <h6 className="approved-review">
                                                                        Approved
                                                                        <Tooltip
                                                                            className="customTooltip"
                                                                            placement="topLeft"
                                                                            arrow={false}
                                                                            title={
                                                                                <p className="hover-content">
                                                                                    Review has been approved by admin.
                                                                                </p>
                                                                            }
                                                                        >
                                                                            <FaCircleInfo />
                                                                        </Tooltip>
                                                                    </h6>
                                                                </>
                                                            ) : content?.status === "rejected" ? (
                                                                <>
                                                                    <h6 className="failed-review">
                                                                        Rejected
                                                                        <Tooltip
                                                                            style={{ background: "black", opacity: "0" }}
                                                                            placement="topLeft"
                                                                            arrow={false}
                                                                            title={
                                                                                <p className="hover-content">
                                                                                    Review has been rejected by admin.
                                                                                </p>
                                                                            }
                                                                        >
                                                                            <FaCircleInfo />
                                                                        </Tooltip>
                                                                    </h6>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <h6 className="pending-review">
                                                                        Pending
                                                                        <Tooltip
                                                                            style={{ background: "black", opacity: "0" }}
                                                                            placement="topLeft"
                                                                            arrow={false}
                                                                            title={
                                                                                <p className="hover-content">
                                                                                    Your review is still pending from admin
                                                                                    side.
                                                                                </p>
                                                                            }
                                                                        >
                                                                            <FaCircleInfo />
                                                                        </Tooltip>
                                                                    </h6>
                                                                </>
                                                            )}
                                                        </Col>
                                                        <Col className="content-review-div" md={12}>
                                                            <p className="content-review">{content?.comment}</p>
                                                        </Col>
                                                        <Col className="edit-delete-view" md={4}>
                                                            <p className="view-icon">
                                                                <IoEyeOutline style={{ cursor: "pointer" }} onClick={() => showEditReviewModal(content, "view")} color="#106AB3" />
                                                            </p>
                                                            <p className="edit-icon">
                                                                <FaRegEdit
                                                                    onClick={() => showEditReviewModal(content, "edit")}
                                                                    style={{ cursor: "pointer" }}
                                                                    color="#106AB3"
                                                                />
                                                            </p>
                                                            <p className="delete-icon">
                                                                <RiDeleteBin6Line
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => handleDeleteReview(content)}
                                                                    color="#DA0734"
                                                                />
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                </>
                                            );
                                        })}
                                    {reviews && reviews.length > 0 ? (
                                        <Pagination
                                            className="pagination"
                                            current={current}
                                            onChange={onChange}
                                            total={totalReviews}
                                            pageSize={pageSize}
                                            onShowSizeChange={onPageSizeChange}
                                            showSizeChanger
                                            pageSizeOptions={pageSizeOptions}
                                        />
                                    ) : (
                                        <>
                                            <div className="no-review-container">
                                                <p className="no-review-text">No reviews added yet</p>
                                            </div>
                                        </>
                                    )}
                                </>
                            )}

                        </div>
                    </div>
                </Col>
            </Row>
            <Modal
                open={isEditReviewModalOpen}
                onOk={handleEditReviewOk}
                onCancel={handleEditReviewCancel}
            >
                <Form
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    initialValues={{
                        fullName: formData?.name,
                        email: formData?.email,
                        comment: formData?.comment,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    className="leave-comment-form"
                >
                    <Row className="leave-comment-form-row">
                        <Col md={10} className="leave-comment-form-col pe-2">
                            <Form.Item
                                className="form-input-class"
                                name="fullName"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter full name.",
                                    },
                                ]}
                            >
                                <Input disabled={!editButton ? true : false} className="leave-comment-input" placeholder="Name*" />
                            </Form.Item>
                        </Col>
                        <Col md={14} className="leave-comment-form-col ps-2">
                            <Form.Item
                                className="form-input-class"
                                name="email"
                                rules={[
                                    {
                                        type: "email",
                                        required: true,
                                        message: "Email is not valid",
                                    },
                                ]}
                            >
                                <Input disabled={!editButton ? true : false} className="leave-comment-input" placeholder="Email*" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row className="leave-comment-form-row">
                        <Col md={24} className="leave-comment-form-col">
                            <Form.Item
                                className="form-input-class"
                                name="comment"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter full name.",
                                    },
                                ]}
                            >
                                <TextArea
                                    disabled={!editButton ? true : false}
                                    className="leave-comment-text-area"
                                    rows={4}
                                    placeholder="Add Comment here..."
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={24}>
                            <Rate
                                disabled={!editButton ? true : false}
                                className="rating-star"
                                onChange={(e) => setRating(e)}
                                defaultValue={parseInt(formData?.rating)}
                            />
                        </Col>
                    </Row>
                    {editButton ? (
                        <Row className="leave-comment-form-row">
                            <Col md={24} className="leave-comment-form-col">
                                <Form.Item
                                    className="form-input-class mb-0"
                                    wrapperCol={{
                                        offset: 8,
                                        span: 24,
                                    }}
                                >
                                    <Button className="leave-comment-button" htmlType="submit">
                                        Update Review
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    ) : ""}

                </Form>
            </Modal>
        </>
    );
}
