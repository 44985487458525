import React from 'react';
import {Row,Col,Image,Form,Input,Button,Checkbox} from "antd";
import {ForgetPasswordImage,Logo} from "../../constant/images";
import { CiMail } from "react-icons/ci";
import * as UploadService from "../../services/uploadServices";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";

export default function ForgetPassword() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const onFinish = (values) => {
       if(values){
        dispatch(UploadService?.forgetPassword(values)).then((response)=>{
          localStorage.setItem("forgetEmail",values?.email)
            Swal.fire(
                "Link Sent Successfully!",
                "Link has been sent to your email address for password reset.",
                "success"
              );
        }).catch((err)=>{
            Swal.fire(
                "Error",
                "Invalid email address.",
                "error"
              );
        })
       }

    };

    const onFinishFailed = (errorInfo) => {
      console.log('Failed:', errorInfo);
    };
  return (
    <>
    <div className='forget-password-container'>
       <div className='forget-password-inner-container'>
          <Row className='forget-password-row'>
             <Col className='forget-password-col-one' md={12}>
                <Image className='forget-password-image' preview={false} src={ForgetPasswordImage}/>
             </Col>
             <Col className='forget-password-col-two' md={12}>
                <Image preview={false} className='well-of-fun-logo' src={Logo}/>
                <p className='forget-password-heading'>Forgot Password</p>
                <p className='reset-password-text'>Enter your email for resetting your password</p>
                <Form
                  name="basic"
                  labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    span: 24,
                  }}
                  style={{
                    maxWidth: 600,
                  }}
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                 <p
                  style={{
                    textAlign: "start",
                    fontFamily: "POPPINS",
                  }}
                >
                  Email Address
                </p>
                  <Form.Item
                  className="form-input-class"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Email is not valid",
                      },
                    ]}
                  >
                    <Input prefix={<CiMail color="#667085"/>} placeholder="Johndoe@gmail.com" className="input-box-login" />
                  </Form.Item>
                  
                 

                  <Form.Item
                 
                    wrapperCol={{
                      offset: 8,
                      span: 24,
                    }}
                  >
                    <Button className="forget-password-button" htmlType="submit">
                    Submit
                    </Button>
                  </Form.Item>
              <div className="have-account">
                <p style={{color:"white", fontFamily:'poppins',textAlign:"center", fontSize:"13px",fontWeight:"500",lineHeight:"21px"}}>
                Don't have an account? <span style={{cursor:"pointer"}} onClick={()=> navigate("/register")}>Sign Up</span>
                </p>
              </div>
                </Form>
            </Col>
          </Row>
       </div>
    </div>
    </>
  )
}
