import React, { useEffect, useState } from "react";
import { Button, Image, Card, Row, Col, Spin } from "antd";
import { ArrowUpOutlined } from "@ant-design/icons";
import * as getServices from "../../services/getServices";
import { useDispatch } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {DEMO_IMAGE_URL} from "../../Helper"


const handleDate = (isoDate) => {
    const date = new Date(isoDate);

    const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    const month = monthNames[date.getUTCMonth()];
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();

    return `${month} ${day}, ${year}`;
};



const slugify = (title) => {
    return title
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '');
};

function AllBlogs({ order }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [blogs, setBlogs] = useState([]);
    const [loader, setLoader] = useState(false);
    const [take, setTake] = useState(6);
    const [offset, setOffset] = useState(0);
    const [showMoreLoader, setShowMoreLoader] = useState(false)
    const [showMore, setShowMore] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoader(true);
                const response = await dispatch(
                    getServices?.getBlogs(6, 0, order)
                );
                setBlogs(response?.blogs);
            } catch (error) {
                console.error({ error });
            } finally {
                setLoader(false);
            }
        };
        fetchData();
    }, [order]);



    const onLoadMore = async () => {
        setShowMoreLoader(true);
        let newOffset = take;
        let newTake = 3;
        const response = await dispatch(
            getServices?.getBlogs(newTake, newOffset, order)
        );
        const newBlogs = response?.blogs.filter(
            (newBlog) => !blogs.some((existingBlog) => existingBlog.id === newBlog.id)
        );
        setBlogs((prevBlogs) => [...prevBlogs, ...newBlogs]);
    
        setTake(newTake);
        setOffset(newOffset);
        setShowMoreLoader(false);
        setShowMore(true);
    };

    const handleRedirect = (data) => {
        navigate(`/blog/${slugify(data.title)}`, {
            state: {
                blogId: data?.id,
            }
        })
    }
    const toggleShowMore = () => {
        setShowMore(!showMore);
    };

    return (
        <>
            {loader ? (
                <div className="d-flex justify-content-center align-items-center mt-4 mb-4">
                    <Spin
                        indicator={
                            <LoadingOutlined
                                style={{
                                    fontSize: 48,
                                }}
                                spin
                            />
                        }
                    />
                </div>
            ) : (
                <>
                    <Row className="cardMainWrapper">

                        {blogs.slice(0, showMore ? blogs.length : 3).map((item, index) => (
                            <Col md={8}>
                                <Card onClick={() => handleRedirect(item)}>
                                    <div className="imageWrapper" key={item?.id}>
                                        <div className="tagsText">
                                            <span>{item.category?.name}</span>
                                        </div>
                                        <Image
                                            src={`${DEMO_IMAGE_URL}${item.images[0]}`}
                                            alt="Not found"
                                            className="img-fluid"
                                            preview={false}
                                        />
                                    </div>
                                    <div className="textWrapper">
                                        <span>{item.title}</span>
                                        <div className="textWithArrow">
                                            <h3>{item.title}</h3>
                                            <div className="arrowDiv">
                                                <ArrowUpOutlined />
                                            </div>
                                        </div>
                                        <p>{handleDate(item.created_at)}</p>
                                    </div>
                                </Card>
                            </Col>
                        ))}

                    </Row>
                    {blogs?.length > 3 ? (
                        <div>


                            <div
                                style={{
                                    textAlign: "center",
                                    marginTop: 12,
                                    height: 10,
                                    lineHeight: "10px",
                                }}
                            >
                                <Button className="show-more-button" onClick={showMore ? toggleShowMore : onLoadMore}>
                                    {showMoreLoader ? (
                                        <div className="d-flex justify-content-center align-items-center mt-4 mb-4">
                                            <Spin
                                                indicator={
                                                    <LoadingOutlined
                                                        style={{
                                                            fontSize: 28,
                                                        }}
                                                        spin
                                                    />
                                                }
                                            />
                                        </div>
                                    ) : (showMore ? "Show Less" : "Show More")}
                                </Button>
                            </div>
                        </div>
                    ) : (
                        ""
                        )}
                        
                      
                </>
            )}
  
        </>
    );
}


function SpecificBlogs({ order, blogs, category }) {
    const navigate = useNavigate();
    const [sortedData, setSortedData] = useState([]);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        setLoader(true)
        let CategoryData = blogs?.filter((filterdata) => {
            return filterdata?.category?.name === category;
        });

        if (order === "asc") {
            CategoryData.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
        } else if (order === "desc") {
            CategoryData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        }

        setSortedData(CategoryData);
        setLoader(false)
    }, [order, blogs, category]);

    const handleRedirect = (data) => {


        navigate(`/blog/${data.id}/${slugify(data.title)}`, {
            state: {
                blogId: data?.id,
            }
        })
    }

    return (
        <>
            {loader ? (
                <div className="d-flex justify-content-center align-items-center mt-4 mb-4">
                    <Spin
                        indicator={
                            <LoadingOutlined
                                style={{
                                    fontSize: 48,
                                }}
                                spin
                            />
                        }
                    />
                </div>
            ) : (
                <>
                    <Row className="cardMainWrapper">
                        {sortedData?.map((item) => (
                            <Col md={8} key={item.id}>
                                <Card onClick={() => handleRedirect(item)}>
                                    <div className="imageWrapper">
                                        <div className="tagsText">
                                            <span>{item.category?.name}</span>
                                        </div>
                                        <Image
                                            src={`${DEMO_IMAGE_URL}${item.images[0]}`}
                                            alt="Not found"
                                            className="img-fluid"
                                            preview={false}
                                        />
                                    </div>
                                    <div className="textWrapper">
                                        <span>{item.title}</span>
                                        <div className="textWithArrow">
                                            <h3>{item.title}</h3>
                                            <div className="arrowDiv">
                                                <ArrowUpOutlined />
                                            </div>
                                        </div>
                                        <p>{handleDate(item.created_at)}</p>
                                    </div>
                                </Card>
                            </Col>
                        ))}
                        </Row>
                
                </>
            )}
        </>
    );
}

export default SpecificBlogs;

export { AllBlogs, SpecificBlogs };
