import React, { useState, useEffect } from "react";
import { Row, Col, Form, Input, Button, Image, Collapse, Steps, Alert, Spin } from "antd";
import { CiMail } from "react-icons/ci";
import { PaymentCardImage, Logo } from "../../constant/images";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import * as UploadServices from "../../services/uploadServices";
import { useDispatch } from "react-redux";
import * as getServicses from "../../services/getServices";
import AddressForm from "./addressForm";
import PaymentForm from "./paymentForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Loader } from "@googlemaps/js-api-loader";
import { LoadingOutlined } from "@ant-design/icons";
import {DEMO_IMAGE_URL} from "../../Helper"

export default function PaymentProcess({ paymentCourseData }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let { state } = useLocation();
    const userData = useSelector((state) => state.user.userData);
    const { user_id, course_id } = useParams();

    const [rewards, setRewards] = useState([]);
    const [rewardApplicable, setRewardApplicable] = useState();
    const [addressFormData, setAddressFormData] = useState([]);
    const [paymentFormData, setPaymentFormData] = useState([]);
    const [activeStep, setActiveStep] = useState(0);
    const [stripePromise, setStripePromise] = useState(loadStripe("pk_test_51NuUdDDhhEVxDG993lu35F4QwDa8YaZcktRtzp4d5f0Mtplas4c5Hc01lJFPH0R9XgshjLP7BWpoE6IYCanmu8JT00Il8HHzf6"));
    const [clientSecret, setClientSecret] = useState("");
    const [couponCode, setCouponCode] = useState()
    const [couponDiscountAmmount, setCouponDiscountAmount] = useState()
    const [couponLoader, setCouponLoader] = useState(false)
    const [couponInputDisplay,setCouponInputDisplay] = useState(true)
    const [payLoader,setPayLoader] = useState(false)

    // useEffect(() => {
    //     try {
    //         dispatch(UploadServices.PaymentIntent({
    //             "amount": 110,
    //             "currency": "USD"
    //         })).then(async (response) => {
    //             setClientSecret(response?.clientSecret);
    //         }).catch((err) => {
    //             console.error(err);
    //         });
    //     } catch (err) {
    //         console.error(err);
    //     }

    // }, [paymentCourseData]);

    useEffect(() => {
        dispatch(getServicses.getAllRewards())
            .then((response) => {
                setRewards(response?.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    useEffect(() => {
        let found = false;
        rewards.forEach((reward) => {
            if (found) {
                return;
            }
            reward?.course_rewards?.forEach((course) => {
                const targetCourseId = parseInt(
                    course_id || paymentCourseData?.id || state?.courseData?.id
                );
                let parsed_course_courseId = parseInt(course.course_id);

                if (parsed_course_courseId === targetCourseId) {
                    setRewardApplicable({
                        rewardId: reward?.id,
                        rewardName: reward?.name,
                        rewardPoints: reward?.points,
                    });
                    found = true;
                } else if (
                    parsed_course_courseId === "0" ||
                    parsed_course_courseId === 0
                ) {
                    setRewardApplicable({
                        rewardId: reward?.id,
                        rewardName: reward?.name,
                        rewardPoints: reward?.points,
                    });
                }
            });
        });
    }, [rewards, course_id, paymentCourseData, state]);

    const onFinish = (paymentFormData) => {

        setPayLoader(true)
        const formData = new FormData();
        formData.append(
            "course_id",
            parseInt(
                course_id
                    ? course_id
                    : paymentCourseData?.id
                        ? paymentCourseData?.id
                        : state?.courseData?.id
            )
        );
        formData.append("vendor_id", paymentCourseData?.vendor_id ? paymentCourseData?.vendor_id : state?.courseData?.vendor_id);
        formData.append("email", userData?.user?.email);
        formData.append("country", addressFormData?.country);
        formData.append("address_line_1", addressFormData?.address);
        formData.append("zip", addressFormData?.postal_code);
        formData.append(
            "course_fee",
            state?.courseData?.fee ? state?.courseData?.fee : paymentCourseData?.fee
        );
        formData.append(
            "amount",
            couponDiscountAmmount?.final_amount? couponDiscountAmmount?.final_amount:state?.courseData?.fee ? state?.courseData?.fee : paymentCourseData?.fee
        );

        if(couponCode){
            formData.append(
                "coupon_code",
                couponCode
            );
        }
        if (addressFormData?.country) {
            formData.append(
                "country",
                addressFormData?.country
            );
        }

        if (addressFormData?.city) {
            formData.append(
                "city",
                addressFormData?.city
            );
        }
        formData.append(
            "transection_id",
            paymentFormData
        );

        dispatch(UploadServices.payment(formData))
            .then((response) => {
                setPayLoader(false)
                navigate("/thankyou", {
                    state: {
                        transaction_id: paymentFormData
                    }
                })
            })
            .catch((err) => {
                setPayLoader(false)
                Swal.fire("Something went wrong!", "Please try again later.", "error").then(() => {
                    window.location.reload();
                }).catch((err) => {
                    window.location.reload();
                });

            });
    };


    // const goNext = () => {
    //     setActiveStep(activeStep + 1);
    // };

    const goBack = () => {
        setActiveStep(activeStep - 1);
    };

    const handleAddressForm = (addressFormData) => {
        setAddressFormData(addressFormData)

        // if(sponsored === "no"){

            function generateRandomString(length) {
                const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                let result = '';
                const charactersLength = characters.length;
                for (let i = 0; i < length; i++) {
                  result += characters.charAt(Math.floor(Math.random() * charactersLength));
                }
                return result;
              }

              const generatedTransactionID = generateRandomString(24)
              onFinish(generatedTransactionID)
        // }else{

        //     goNext()
        // }
    }

    const handlePaymentForm = (paymentFormData) => {
      
        setPaymentFormData(paymentFormData)

        onFinish(paymentFormData)
    }

    const stepItems = [
        {
            title: "Address",
            content: <AddressForm addressFormData={addressFormData} onData={handleAddressForm} payLoader={payLoader}/>
        },
        // ...(sponsored !== "no"
        //     ? [
        //           {
        //               title: "Payment",
        //               content: (
        //                   <Elements stripe={stripePromise} options={{ clientSecret }}>
        //                       <PaymentForm onData={handlePaymentForm} goBack={goBack} paymentCourseData={paymentCourseData} />
        //                   </Elements>
        //               )
        //           }
        //       ]
        //     : [])
    ];


    const handleApplyCoupon = () => {
        
        const formData = new FormData();
        if (couponCode) {
            setCouponLoader(true)
            formData.append("coupon_code", couponCode)
            formData.append("purchase_amount", state?.courseData?.fee ? state?.courseData?.fee : paymentCourseData?.fee)
            try {
                dispatch(UploadServices.applyCoupon(formData)).then((response) => {
                    setCouponInputDisplay(false)
                    setCouponDiscountAmount(response)
                    setCouponLoader(false)
                }).catch((error) => {
                    setCouponLoader(false)
                    console.error(error)
                    Swal.fire(
                        "Coupon Code",
                        "Invalid coupon code or coupon expired.",
                        "error"
                    )
                })
            } catch (e) {
                console.error({ error: e.message })
            }
        } else {
            Swal.fire(
                "Coupon Code",
                "Please enter coupon code.",
                "info"
            )
        }    
    }


    const handleCloseAlert = () =>{
      setCouponCode(null);
      setCouponDiscountAmount(null);
      setCouponInputDisplay(true);
    }



    return (
        <div className="paymentModalBody">
            <div className="row">
                <div className="col-md-6">
                    <div className="leftSection">
                        <div className="logoWrap">
                            <img className="img-fluid" src={Logo} alt="logo" />
                        </div>
                        <p className="titleText">Order Summary</p>
                        <div className="orderDeatailsSection mb-3">
                            <div className="orderList">
                                <div className="orderImage">
                                    <img className="img-fluid" src={paymentCourseData?.image ? `${DEMO_IMAGE_URL}${paymentCourseData?.image}` : Logo} alt="courseImage" />
                                </div>
                                <div className="orderContent">
                                    <h5 className="title">{paymentCourseData?.title}</h5>
                                    <div className="orderPrice">
                                        {/* <p className="discountRate">
                                            <span className="strike">
                                                $29.00
                                            </span>
                                            <span className="discount">
                                                (35%)
                                            </span>
                                        </p> */}
                                        <p className="totalRow">
                                            <span className="price">${paymentCourseData?.fee}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {couponInputDisplay ? (
                            <div className="coupon-addons">
                                <Input className="" placeholder="Enter coupon" onChange={(e) => setCouponCode(e.target.value)} />
                                <button className="coupon-apply-button" onClick={() => handleApplyCoupon()}>
                                    {couponLoader ? (
                                    
                                            <Spin
                                                indicator={
                                                    <LoadingOutlined
                                                        spin
                                                    />
                                                }
                                            />
                                    ) : ("Apply")
                                    }
                                    </button>
                            </div>
                        ) : (<></>)}
                        
                        <div className="couponRow">
                            {couponCode && couponDiscountAmmount ? (
                                <>
                                <p className="titleText">Coupon Code</p>
                            <Alert
                                className="couponRibbon mb-4"
                                message={
                                    <>
                                        <p className="couponText">
                                            {couponCode}
                                        </p>
                                        <span>{couponCode} - ${couponDiscountAmmount?.discount}</span>
                                    </>
                                }
                                type="success"
                                showIcon
                                closable
                                onClose={()=>handleCloseAlert()}
                            />
                                </>
                            ) : (<></>)}
                            
                            <div className="totalSection">
                                <div className="totalList">
                                    <span className="leftText">Subtotal</span>
                                    <span className="rightText">${state?.courseData?.fee ? state?.courseData?.fee : paymentCourseData?.fee}</span>
                                </div>

                                <div className="totalList">
                                    <span className="leftText">Coupon Discount</span>
                                    <span className="rightText discountText ">{couponDiscountAmmount?.discount ? `-$${couponDiscountAmmount?.discount}` : "$0"}</span>
                                </div>
                                <div className="totalList">
                                    <span className="leftText">Total Due</span>
                                    <span className="rightText dueText ">${couponDiscountAmmount?.final_amount ? couponDiscountAmmount?.final_amount : paymentCourseData?.fee}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="rightSection">
                        <Steps current={activeStep} items={stepItems} />
                        <div className="stepContent mt-4">
                            {stepItems[activeStep].content}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
