import React, { useEffect, useState } from "react";
import { Input, Spin } from "antd";
import { useDispatch } from "react-redux";
import { CiSearch } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import * as getServices from "../../services/getServices";
import { LoadingOutlined } from '@ant-design/icons';
import Swal from "sweetalert2";
import {DEMO_IMAGE_URL} from "../../Helper"
const BannerSection = () => {
    const navigate = useNavigate();
    const [banner, setBanner] = useState()
    const [courseNameSearch, setCourseNameSearch] = useState('')
    const [loader, setLoader] = useState(true)
    const dispatch = useDispatch()
    const handleCourseNameSearch = (value) => {
        setCourseNameSearch(value)
    }
    useEffect(() => {
        dispatch(getServices?.getBanner('home'))
            .then((result) => {
                setBanner(result?.banner)
                // dispatch(setSettingLogo(result))
            })
            .catch((err) => {
                console.error({ err });
            }).finally(() => {
                setLoader(false)
            })
    }, [])
  
    
    const handleSearchHome = () => {
        if (courseNameSearch == '') {
            Swal.fire({
                title: "Write anything for search",
                icon: "info",
                allowEnterKey: false,
            });    
        } else {
            const querystring = `?search=${courseNameSearch}`;
            dispatch(getServices.getCourses(querystring))
                .then((response) => {
                    if (Array.isArray(response?.data) && response.data.length === 0) { 
                        Swal.fire({
                            title: "There is no such item",
                            icon: "error",
                            allowEnterKey: false,
                        });
                        setCourseNameSearch('')
                    } else {
                        navigate("/course-listing", {
                            state: { cartegoryPageData: response?.data, searchString: courseNameSearch },
                        });
                    }
                })
                .catch((err) => {
                    console.error({ err });
                }); 
        }
      
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearchHome();
        }
    };

    return (
        <section className="bannerSection" style={{
            backgroundImage: `url(${DEMO_IMAGE_URL}${banner?.image})`
        }} >
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="innerSection">
                            {
                                banner ? (
                                    <div className="textSection" key={banner?.id}>
                                        <h3 className="title">
                                            {banner?.sub_title}
                                        </h3>
                                        <h2 className="mainTitle">
                                            {banner?.title}
                                        </h2>
                                    </div>
                                ) : (
                                    <div className="textSection">
                                        <h3 className="title">A Learning Paths Tailored to Your Goals</h3>
                                        <h2 className="mainTitle">Browse Our Items Now!</h2>
                                    </div>
                                )
                            }

                            <div className="searchSection">
                                <div className="innerSection">
                                    <Input
                                        className="form-control"
                                        placeholder="Search by Item name"
                                        prefix={
                                            <CiSearch className="search-icon" color="#106AB3" />
                                        }
                                        onChange={(e) => handleCourseNameSearch(e.target.value)}
                                        onKeyDown={handleKeyDown}
                                    />
                                    <button
                                        onClick={handleSearchHome}
                                        type="button"
                                        className="btn button-style"
                                    >
                                        <CiSearch className="search-icon" /> &nbsp;Search
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BannerSection;