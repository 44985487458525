import { Col, Row, Image, Form, Input, Checkbox, Button, Spin } from "antd";
import React, { useState, useEffect,useContext } from "react";
import { Logo } from "../../constant/images";
import { CiMail, CiLock } from "react-icons/ci";
import * as UserServices from "../../services/userServices";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { useSelector } from "react-redux"
// import {
//     // setUserData,
//     setUserLoggedIn,
//     setVendorLoggedIn,
//     setToken,
// } from "../../store/actions/user";
import { setUserData,setUserLoggedIn ,setVendorLoggedIn,setToken} from '../../StoreRedux/appSlice';
import { LoadingOutlined } from "@ant-design/icons";
import { getToken } from "firebase/messaging";
import { messaging } from "../../Utils/firebaseUtils";
import * as uploadService from "../../services/uploadServices"
import * as getServices from "../../services/getServices"
import { FcGoogle } from "react-icons/fc";
import { FaFacebookF } from "react-icons/fa";
import { CartContext } from "../../Helpers/CartContext";

export default function Login() {
    const cartItems = useSelector((state) => state.user.cart);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const [loader, setLoader] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    const { addItemToCart } = useContext(CartContext);
    let previoursPage = localStorage.getItem("coursePage");

    useEffect(() => {
        const storedEmail = localStorage.getItem('email') || sessionStorage.getItem('email');
        const storedPassword = localStorage.getItem('password') || sessionStorage.getItem('password');

        if (storedEmail && storedPassword) {
            form.setFieldsValue({
                email: storedEmail,
                password: storedPassword,
            });
            setRememberMe(true);
        }
    }, [form]);

    const VAPID_KEY = process.env.REACT_APP_VAPID_KEY;

    async function requestPermission() {
        try {
            const permission = await Notification.requestPermission();
            if (permission === "granted") {
                const token = await getToken(messaging, {
                    vapidKey: VAPID_KEY,
                });
                return token;
            } else {

                return;
            }
        } catch (error) {
            console.error("Error requesting permission:", error);
            return;
        }
    }

    const uploadCoursesFromRedux = (user_id) => {
        try {
            const courses = cartItems.map(course => ({
                user_id: user_id,
                course_id: course.id,
                course_price: parseInt(course?.fee)
            }));

            dispatch(uploadService.uploadCoursesAtOnce({ carts: courses })).then((response) => {
            }).catch((error) => {
                console.error({ error })
            })


        } catch (error) {

            console.error({ error });
        }
    }

    const onFinish = async (values) => {
        setLoader(true);
        if (rememberMe) {
            localStorage.setItem('email', values.email);
            localStorage.setItem('password', values.password);
        } else {
            sessionStorage.setItem('email', values.email);
            sessionStorage.setItem('password', values.password);
        }

        try {
            const token = await requestPermission();

            const formData = {
                email: values.email,
                password: values.password,
                fcm_token: token,
            };
            dispatch(UserServices.login(formData))
                .then((response) => {

                    dispatch(setToken(response?.user?.token))
                    localStorage.setItem('token', response?.user?.token);
                    setLoader(false);


                    if (response.status === 403) {
                        Swal.fire("Please verify your email.", "error");
                    } else if (response.status === 200) {
                        // dispatch(setUserData(response));
                        dispatch(setUserData(response))
                        Swal.fire(
                            "Login Successful!",
                            "You have successfully logged in with Well of Fun!",
                            "success"
                        ).then(() => {
                            uploadCoursesFromRedux(response.user?.id)
                            // addItemToCart(0);
                            if (response?.vendor?.id) {
                                localStorage.setItem("activeRoute", "/vendor/dashboard");
                                dispatch(setVendorLoggedIn(true));
                                if (previoursPage) {
                                    localStorage.setItem("previoursPage", false);
                                    navigate(-1);
                                } else {
                                    navigate("/vendor/dashboard");
                                }
                            } else {
                                dispatch(setUserLoggedIn(true));
                                localStorage.setItem("activeRoute", "/user/dashboard");
                                if (previoursPage) {
                                    localStorage.setItem("previoursPage", false);
                                    navigate(-1);
                                } else {
                                    navigate("/user/dashboard");
                                }
                            }
                        });
                    } else {
                        Swal.fire("Verification!", "You need to verify your email.", "info");
                    }
                })
                .catch((err) => {
                    console.error({ err });
                    setLoader(false);
                    Swal.fire("Error", `${err?.response?.data?.message}`, "error");

                });
        } catch (error) {
            console.error("Error getting FCM token: ", error);
            setLoader(false);
            Swal.fire("Error", "Failed to retrieve FCM token.", "error");
        }
    };


    const onFinishFailed = () => {

    };

    const handleForgetPassword = () => {
        navigate("/forgot-password");
    };

    const handleGoogleLogin = (type) =>{
        dispatch(getServices.getSocialLogin(type)).then((response)=>{
               if(response?.url){
                window.location.href = response?.url;
               }
        }).catch((err)=>{
            console.error("Error in social login ",err)
        })
    }

    return (
        <>
            <div className="login-container">
                <div className="login-div">
                    <Row className="d-flex align-items-center justify-content-center form-row-section">
                        <Col className="text-center left-section tab-none" md={12}>
                            <div className="login-banner">
                                <div className="login-image">
                                    <Image
                                        style={{ width: "150px", height: "122.73px" }}
                                        src={Logo}
                                        alt="Not Found"
                                        className="img-fluid"
                                        preview={false}
                                    />
                                    <div className="login-image-heading">
                                        <h3>Welcome to</h3>
                                        <h3>Well Of Fun</h3>
                                    </div>
                                    <div className="login-image-description">
                                        <p className="text-center">
                                            "Unlocking Knowledge, Empowering Minds: Welcome to WELLOFFUN - Your Gateway to Excellence in Learning!"
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={12} className="right-section">
                            <div className="login-page">
                                <div className="login-page-header">
                                    <h3
                                        style={{
                                            textAlign: "start",
                                            color: "white",
                                            fontFamily: "POPPINS",
                                        }}
                                    >
                                        Log In
                                    </h3>
                                </div>

                                <Form
                                    form={form}
                                    name="basic"
                                    labelCol={{
                                        span: 8,
                                    }}
                                    wrapperCol={{
                                        span: 24,
                                    }}
                                    style={{
                                        maxWidth: 600,
                                        margin: '0px auto',
                                    }}
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                >
                                    <p
                                        style={{
                                            textAlign: "start",
                                            color: "white",
                                            fontFamily: "POPPINS",
                                        }}
                                    >
                                        Email address
                                    </p>
                                    <Form.Item
                                        className="form-input-class"
                                        name="email"
                                        rules={[
                                            {
                                                validator: (_, value) => {
                                                    if (!value) {
                                                        return Promise.reject(
                                                            new Error("Email is required")
                                                        );
                                                    }
                                                    const trimmedValue = value.trim();
                                                    if (trimmedValue === "") {
                                                        return Promise.reject(
                                                            new Error("Spaces are not allowed")
                                                        );
                                                    }
                                                    if (!emailRegex.test(trimmedValue)) {
                                                        return Promise.reject(
                                                            new Error("Please enter a valid email address")
                                                        );
                                                    }
                                                    return Promise.resolve();
                                                },
                                            },
                                        ]}
                                    >
                                        <Input
                                            prefix={<CiMail color="#667085" />}
                                            placeholder="Johndoe@gmail.com"
                                            className="input-box-login"
                                        />
                                    </Form.Item>
                                    <p
                                        style={{
                                            textAlign: "start",
                                            color: "white",
                                            fontFamily: "POPPINS",
                                        }}
                                    >
                                        Password
                                    </p>
                                    <Form.Item
                                        className="form-input-class mb-1"
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Password is required",
                                            },
                                        ]}
                                    >
                                        <Input.Password
                                            prefix={<CiLock color="#667085" />}
                                            placeholder="johndoe123"
                                            className="input-box-login"
                                        />
                                    </Form.Item>
                                    <div className="remember-forget">
                                        <Form.Item
                                            className="mb-1"
                                            name="remember"
                                            valuePropName="checked"
                                            wrapperCol={{
                                                offset: 0,
                                                span: 24,
                                            }}
                                        >
                                            <Checkbox
                                                className="form-input-class"
                                                style={{ color: "white", marginBottom: '0px' }}
                                                onChange={(e) => setRememberMe(e.target.checked)}
                                                checked={rememberMe}
                                            >
                                                Remember me
                                            </Checkbox>
                                        </Form.Item>
                                        <p className="forgetPassword"
                                            onClick={handleForgetPassword}
                                            style={{
                                                textAlign: "start",
                                                color: "white",
                                                fontFamily: "poppins",
                                                cursor: "pointer",
                                            }}
                                        >
                                            Forgot password?
                                        </p>
                                    </div>

                                    <Form.Item>
                                        <Button
                                            htmlType="submit"
                                            className="login-button"
                                            disabled={loader}
                                        >
                                            {loader ? (
                                                <Spin
                                                    indicator={
                                                        <LoadingOutlined
                                                            style={{
                                                                fontSize: 24,
                                                                color: "white",
                                                            }}
                                                            spin
                                                        />
                                                    }
                                                />
                                            ) : (
                                                "Log In"
                                            )}
                                        </Button>
                                    </Form.Item>
                                </Form>
                                <p className="or-text">---------- OR ----------</p>
                                <div className="social-icon-container">
                                    {/* <a href="https://welloffun-api.aleaspeaks.com/api/social-login/google" className="google-login icon-box"> */}
                                    <a onClick={()=>handleGoogleLogin('google')} className="google-login icon-box">
                                        <FcGoogle className="google-logo" />
                                    </a>
                                    <a onClick={()=>handleGoogleLogin('facebook')}  className="facebook-login icon-box">
                                        <FaFacebookF className="facebook-logo" />
                                    </a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}
