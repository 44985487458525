import React, { useEffect, useState } from "react";
import { LiaBookReaderSolid } from "react-icons/lia";
import { GrTrophy } from "react-icons/gr";
import { FaRegUserCircle } from "react-icons/fa";
import { IoLogOutSharp } from "react-icons/io5";
import { BsHeadset } from "react-icons/bs";
import { MdOutlinePermContactCalendar } from "react-icons/md";
import { AiOutlineHome } from "react-icons/ai";
import { MdOutlineClass } from "react-icons/md";
import { Layout, Menu, theme } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { TbUserDollar } from "react-icons/tb";
import { PiStudent } from "react-icons/pi";
import { RiCoupon3Line } from "react-icons/ri";
import { GrTransaction } from "react-icons/gr";


// import {
//     setUserData,
//     setUserLoggedIn,
//     setVendorLoggedIn,
//     setToken,
// } from "../../store/actions/user";
import {
    setUserData,
    setUserLoggedIn,
    setVendorLoggedIn,
    setToken,   
} from '../../StoreRedux/appSlice'
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const { Sider } = Layout;

const Sidebar = () => {
    const location = useLocation()
    const isUserLoggedIn = useSelector((state) => state.user.isUserLoggedIn);
    const isVendorLoggedIn = useSelector((state) => state.user.isVendorLoggedIn);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [activeRoute, setActiveRoute] = useState("");
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    useEffect(() => {
        let route = localStorage.getItem("activeRoute");
        if (route !== undefined) {
            setActiveRoute(route);
        }
    }, []);

    const handleLoggout = () => {
        dispatch(setToken(null));
		dispatch(setVendorLoggedIn(false));
		dispatch(setUserLoggedIn(false));
		dispatch(setUserData(""));
		localStorage.clear()
		navigate("/login")
    };

    const handleMenuClick = (values) => {
      
        localStorage?.setItem("activeRoute", values.path);
        setActiveRoute(values.path);
        navigate(values.path);
    };
    useEffect(() => {
        const path = location.pathname;
        let key = path;

        setActiveRoute(key);
    }, [location.pathname]);

   

    const item = [
        {
            key: "Dashboard",
            label: "Dashboard",
            icon: <AiOutlineHome />,
            path: "/vendor/dashboard",
        },
        {
            key: "my_courses",
            label: "My Items",
            icon: <LiaBookReaderSolid />,
            path: "/vendor/my-courses",
        },

        {
            key: "Classes",
            label: "Session",
            icon: <MdOutlineClass />,
            path: "/vendor/my-classes",
        },
        {
            key: "reviews_ratings",
            label: "Reviews & Ratings",
            icon: <GrTrophy />,
            path: "/vendor/all-reviews",
        },
        // {
        //   key: "sales_summary",
        //   label: "Sales Summary",
        //   icon: <MdRateReview />,
        //   path: "/",
        // },
        {
            key: "students",
            label: "Clients",
            icon: <PiStudent />,
            path: "/vendor/my-students",
        },
        {
            key: "course-enquiry",
            label: "Enquiries",
            icon: <MdOutlinePermContactCalendar />,
            path: "/vendor/course-enquiry",
        },
        {
            key: "Coupons",
            label: "Coupons",
            icon: <RiCoupon3Line />,
            path: "/vendor/all-coupons",
        },
        {
            key: "My-Profile",
            label: "My Profile",
            icon: <FaRegUserCircle />,
            path: "/vendor/my-profile",
        },
        {
            key: "Sales Summary",
            label: "Sales Summary",
            icon: <TbUserDollar />,
            path: "/vendor/sales-summary",
        },
        {
            key: "Contact Admin",
            label: "Contact Admin",
            icon: <BsHeadset />,
            path: "/vendor/contact-admin",
        },
        {
            key: "Logout",
            label: "Logout",
            icon: <IoLogOutSharp />,
            path: "/login",
            logout: handleLoggout,
        }
    ];


    const UserSidebar = [
        {
            key: "Dashboard",
            label: "Dashboard",
            icon: <AiOutlineHome />,
            path: "/user/dashboard",
        },
        {
            key: "my_courses",
            label: "My Items",
            icon: <LiaBookReaderSolid />,
            path: "/user/my-courses",
        },
        // {
        //     key: "my_rewards",
        //     label: "My Rewards",
        //     icon: <GrTrophy />,
        //     path: "/user/my-rewards",
        // },
        {
            key: "Contact Admin",
            label: "Contact Admin",
            icon: <BsHeadset />,
            path: "/user/contact-admin",
        },
        {
            key: "My-Reviews",
            label: "My Reviews",
            icon: <GrTrophy />,
            path: "/user/my-reviews",
        },
        {
            key: "My Coupons",
            label: "My Coupons",
            icon: <RiCoupon3Line />,
            path: "/user/my-coupons",
        },
        {
            key: "My Transactions",
            label: "My Transactions",
            icon: <GrTransaction />,
            path: "/user/my-transactions",
        },
          
      
        {
            key: "My-Wishlist",
            label: "My Wishlist",
            icon: <GrTrophy />,
            path: "/user/wishlist",
        },
        {
            key: "My-Profile",
            label: "My Profile",
            icon: <FaRegUserCircle />,
            path: "/user/my-profile",
        },
        {
            key: "Logout",
            label: "Logout",
            icon: <IoLogOutSharp />,
            path: "/login",
            logout: handleLoggout,
        }
    ];

    let items2;

    if (!isUserLoggedIn && isVendorLoggedIn) {
        items2 = item?.map((values) => {
            return {
                icon: values?.icon,
                key: values?.key,
                label: values?.label,
                path: values?.path,
                logout: values?.logout,
            };
        });
    } else {
        items2 = UserSidebar?.map((values) => {
            return {
                icon: values?.icon,
                key: values?.key,
                label: values?.label,
                path: values?.path,
                logout: values?.logout,
            };
        });
    }



    return (
        <>
            <Sider
                className="sidebar-container"
                style={{
                    background: colorBgContainer,
                }}
                width={318}
            >
                <Menu
                    mode="inline"
                    defaultSelectedKeys={["1"]}
                    defaultOpenKeys={["sub1"]}
                    style={{
                        height: "100%",
                    }}
                >
                    {items2?.map((item) => (
                        <Menu.Item
                            key={item.path}
                            onClick={() =>
                                item.label === "Logout" ? item.logout() : handleMenuClick(item)
                            }
                            className={activeRoute === item.path ? "activeRoute" : ""}
                        >
                            {item.icon}&nbsp;{item.label}
                        </Menu.Item>
                    ))}
                </Menu>
            </Sider>
        </>
    );
};

export default Sidebar;
