// import { configureStore } from '@reduxjs/toolkit';
// import appReducer from './appSlice';

// const store = configureStore({
//     reducer: {
//         app: appReducer,
//     },
// });

// export default store;

import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import appReducer from './appSlice';

// Configure Redux Persist
const persistConfig = {
    key: 'root', 
    storage, 
};

const persistedReducer = persistReducer(persistConfig, appReducer);

const store = configureStore({
    reducer: {
        user: persistedReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

const persistor = persistStore(store);

export { store, persistor };
