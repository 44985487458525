import React, { useState, useEffect, useContext } from "react";
import {
    Row,
    Col,
    Input,
    Card,
    Pagination,
    Button,
    Flex,
    Spin,
    Modal
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import * as getServices from "../../services/getServices";
import { useDispatch } from "react-redux";
import { FaLock } from "react-icons/fa";
import { FaUnlock } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Payment from "../paymentPrcess/index";
import * as uploadService from "../../services/uploadServices";
import Swal from "sweetalert2"
import { IoIosArrowRoundBack } from "react-icons/io";
import { FaRegHeart } from "react-icons/fa6";
import { GoHeartFill } from "react-icons/go";
// import {add_To_Cart} from "../../store/actions/user";
import { add_To_Cart } from "../../StoreRedux/appSlice";
import { useAtom } from "jotai";
import {cartCount} from "../../Helpers/atoms/freeCoursesAtom"
import { CartContext } from "../../Helpers/CartContext";

import {
    featureImg2,
} from "../../constant/images";
import {DEMO_IMAGE_URL} from "../../Helper"
export default function SearchPage() {
    // window.scrollTo(0, 0);
    const userData = useSelector((state) => state.user.userData);
    const isUserLoggedIn = useSelector((state) => state.user.isUserLoggedIn);
    const isVendorLoggedIn = useSelector((state) => state.user.isVendorLoggedIn);
    const categories = useSelector((state) => state.user.categoriesData);
    const cartItems = useSelector((state) => state.user.cart);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let { state } = useLocation();
    const [current, setCurrent] = useState(1);
    const { Search } = Input;
    const pageSize = 6;
    const [paymentDetails, setPaymentDetails] = useState();
    const [searchTerm, setSearchTerm] = useState("");
    const [matchedCourses, setMatchedCourses] = useState(state?.cartegoryPageData);
    const [loader, setLoader] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [paymentCourseData, setPaymentCourseData] = useState();
    const [wishlistData, setWishlistData] = useState([])
    const [cartData, setCartData] = useState([]);
    const [cartCountValue, setCartCountValue] = useAtom(cartCount);
    const { addItemToCart } = useContext(CartContext);


    const getWishlistData = () => {
        dispatch(getServices.GetWistlistDataUser(userData?.user?.id)).then((response) => {
                    setWishlistData(response?.course_details)
                }).catch((error) => {
                    console.error({ error });
                })
    }

    const fetchCartItems = async () => {
        try {
            const response = await dispatch(getServices.getCartData());
            setCartCountValue(response?.data?.length || 0)
            setCartData(response?.data || []);
        } catch (error) {
            console.error("Error fetching cart data", error);
        }
    };


    useEffect(() => {
        dispatch(
            getServices?.getPaymentDetails(userData?.user?.id)
        )
            .then((response) => {
                if (response?.payments) {
                    setPaymentDetails(response?.payments);
                }
            })
            .catch((err) => {
                console.error({ err });
            });

            if (isUserLoggedIn) {
            getWishlistData()
            fetchCartItems()
            }
    }, []);


    const handleWishlistList = (course) => {

        let formData = new FormData();
        formData.append("course_id", course?.id)

        try {

            dispatch(uploadService.addRemoveCourseWishlist(formData)).then((response) => {
                if (isUserLoggedIn) {
                getWishlistData()
                }
                Swal.fire(
                    "Success!",
                    `${response?.message}`,
                    "success"
                )
            }).catch((error) => {
                Swal.fire(
                    "Failed",
                    "Unable to add item to wishlist.",
                    "error"
                )
            })

        } catch (e) {
            Swal.fire(
                "Failed",
                "Unable to add course to wishlist.",
                "error"
            )
        }

    }

    const Category = (category_id) => {
        const category = categories?.find(
            (category) => category?.id === category_id
        );
        return category?.name;
    };

    const handleCourseData = (values) => {
        
        navigate(`/course/${values?.id}/${values?.title}`, {
            state: { course_id: values?.id },
        });
    };

    const handlePurchase = (coursedata) => {
        setPaymentCourseData(coursedata)
        setIsModalOpen(true)
    };

    useEffect(() => {
        const name = state?.courseName || "";
        setSearchTerm(name);
    }, [state]);
    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);

        if (!event.target.value) {
            handleClear();
        }
    };

    const startIndex = (current - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const displayedCourses = matchedCourses?.slice(startIndex, endIndex);

    const onChange = (page) => {
        setCurrent(page);
    };

    const handleSearchClick = () => {
        setLoader(true);
        const querystring = state?.querystring ? `${state?.querystring}&search=${searchTerm}` :`?search=${searchTerm}`;

        dispatch(getServices.getCourses(querystring)).then((response) => {
            setLoader(false); 
            setMatchedCourses(response?.data)
        }).catch((err) => {
            console.error({ err });
        })
    
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const DateFormatter = (date) => {
        const inputDate = date;

        const dateObj = new Date(inputDate);

        const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];

        const day = dateObj.getDate();
        const month = monthNames[dateObj.getMonth()];
        const year = dateObj.getFullYear();

        const formatDate = `${day} ${month} ${year}`;
        return formatDate;
    };
    const handleBack = () => {
        navigate("/");
    }

    const handleClear = () => {
        setSearchTerm("");
        setMatchedCourses([]);


    };

    const handleAddToCart = (data) => {

        if (!isUserLoggedIn && !isVendorLoggedIn) {
            if (data?.is_premium === "free" || data?.fee === 0 || data?.fee === null) {
                data.fee = 0;
            }
            dispatch(add_To_Cart(data))
            addItemToCart(cartItems?.length+1);
        } else {
            const addToCart = (data) => {
                const formData = new FormData()
                formData.append("course_id", data?.id)
                formData.append("course_price",data?.fee === 0 || data?.is_premium === "free" || data?.fee === null ? 0: data?.fee)
                dispatch(uploadService.addToCart(formData)).then((response) => {
                    if (isUserLoggedIn) {
                    fetchCartItems()
                    }
                    Swal.fire(
                        "Success",
                        `${response.message}`,
                        "success"
                    )
                }).catch((error) => {
                    Swal.fire("Failed", error.message);
                })
            }
            addToCart(data)
        }

    }

    const handleFreeCourse = (courseData) => {
        try {
            const formData = new FormData();
            formData.append(
                "course_id", courseData?.id
            );
            formData.append("email", userData?.user?.email);
            formData.append("amount", 0);
            formData.append("course_fee", 0);
            dispatch(uploadService.payment(formData)).then((response) => {
                Swal.fire(
                    "Items Enrolled Successfully",
                    "You have been successfully enrolled in the Items.",
                    "success"
                )

            }).catch((error) => {
                Swal.fire("Failed", error.message);
            })
        } catch (e) {
            console.error('Error in free item purchase', e)
            return
        }
    }

    return (
        <>
            <div className="search-page-outer-container">
                <Button className="back-to-button search mx-5" onClick={handleBack}> <IoIosArrowRoundBack />Back to home</Button>
                <div className="search-page-inner-container container">
                    <div className="search-input-container">

                        <h5>
                            {state?.category?.name}
                            {state?.subCategory?.name && `/${state.subCategory.name}`}
                            {state?.childCategory?.name && `/${state.childCategory.name}`}
                        </h5>

                        <Search
                            value={searchTerm}
                            onChange={handleInputChange}
                            onSearch={() => handleSearchClick()}
                            className="search-input"
                            placeholder="Search"
                            allowClear
                        />

                        <p className="search-result-text">
                            {(matchedCourses)?.filter((course) => course.status !== "pending")?.length} results found
                        </p>
                    </div>
                    <div className="filter-result-container">
                        <Row className="courses-with-filter-container customRowComponent">
                            <Col className="course-list-child-one" md={24}>
                                <Row justify={'center'}>
                                    {loader ? (
                                        <div className="search-loader-container">
                                            <Spin
                                                indicator={
                                                    <LoadingOutlined
                                                        style={{
                                                            fontSize: 48,
                                                        }}
                                                        spin
                                                    />
                                                }
                                            />
                                        </div>
                                    ) : (
                                        <>
                                            {(matchedCourses)?.filter((course) => course.status !== "pending")?.map((course) => {
                                                return (
                                                    <>
                                                        <Col
                                                            className=""
                                                            style={{ backgroundColor: "#FFF" }}
                                                            xl={8}
                                                            md={24}
                                                        >
                                                          
                                                        <h5 className="main-category mb-0">{course?.category?.name ? course?.category?.name : course?.parent_category_name}</h5 >
                                                            <Card
                                                                className="feature-card"
                                                                hoverable
                                                                key={course?.id}
                                                                cover={
                                                                    <img
                                                                        style={{ height: "250px", cursor: "pointer" }}
                                                                        onClick={(e) => handleCourseData(course)}
                                                                        src={
                                                                            course?.image
                                                                                 ? `${DEMO_IMAGE_URL}${course?.image}`
                                                                                : featureImg2
                                                                        }
                                                                        alt="text"
                                                                    />
                                                                }
                                                            >
                                                            <div className="wishlist-icon-div">
                                                        <button
                                                            type="button"
                                                            className="btn wishlist-feature-button add-to-wishlist"
                                                            onClick={() => handleWishlistList(course?.course)}
                                                        >
                                                            {wishlistData?.some((wish) => parseInt(wish?.id) === parseInt(course?.course?.id)) ? (
                                                                <GoHeartFill />
                                                            ) : (
                                                                <FaRegHeart />
                                                            )}
                                                        </button>
                                                    </div>
                                                                <div onClick={(e) => handleCourseData(course)}>
                                                                    <div className="card-position-div">
                                                                        <div
                                                                            style={{
                                                                                // position: "absolute",
                                                                                // left: "0",
                                                                                // top: "2rem",
                                                                                zIndex: "99",
                                                                                padding: "10px 15px",
                                                                                backgroundColor: "#106ab3",
                                                                                borderRadius: "0 10px 10px 0",
                                                                            }}
                                                                            className="expire-container"
                                                                        >
                                                                            {paymentDetails?.some(
                                                                                (item) => item.course_id === course?.id
                                                                            ) ? (
                                                                                <span className="Lock-icon">
                                                                                    <FaUnlock color="#fff" />
                                                                                </span>
                                                                            ) : (
                                                                                <span className="Lock-icon">
                                                                                    <FaLock color="#fff" />
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                        <div
                                                                            style={{

                                                                                zIndex: "99",
                                                                                padding: "10px 10px",
                                                                                backgroundColor: "#106ab3",
                                                                                borderRadius: "10px 0 0 10px",
                                                                            }}
                                                                            className="expire-container"
                                                                        >
                                                                            <div className="start-end-date-container">
                                                                                <p
                                                                                    style={{ color: "#fff", margin: 0 }}
                                                                                    className="start-enddate"
                                                                                >
                                                                                    {course?.course_start &&
                                                                                        course?.course_end
                                                                                        ? `${DateFormatter(
                                                                                            course?.course_start
                                                                                        )} - ${DateFormatter(
                                                                                            course?.course_end
                                                                                        )}`
                                                                                        : "12 July 2024 - 15 August 2025"}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <Flex
                                                                            gap="middle"
                                                                            className="feature-course-subhead-price"
                                                                        >
                                                                            <div>
                                                                                <p
                                                                                    style={{
                                                                                        color: "#106AB3",
                                                                                        fontWeight: 700,
                                                                                    }}
                                                                                >
                                                                                    {Category(course?.category_id) ? Category(course?.category_id) : "Web Development"}
                                                                                </p>
                                                                            </div>
                                                                            <div>
                                                                                <p
                                                                                    style={{
                                                                                        color: "#1EAA39",
                                                                                        fontWeight: 700,
                                                                                    }}
                                                                                >
                                                                                    {course?.fee !== 0 ? `$${course.fee}` : 'Free'}
                                                                                </p>
                                                                            </div>
                                                                        </Flex>
                                                                        <Flex className="feature-course-subhead-price">
                                                                            <h4>{course?.title}</h4>

                                                                        </Flex>
                                                                    </div>
                                                                    <div>
                                                                        <p className="course-about">{course?.about_course}</p>
                                                                    </div>
                                                                </div>
                                                                <Flex className="feature-course-subhead-price">
                                                                    {paymentDetails?.some(
                                                                        (item) => item?.course_id == course?.id
                                                                    ) ? (
                                                                        <>
                                                                            <button
                                                                                type="button"
                                                                                className="btn feature-button view-course-button"
                                                                                onClick={(e) => handleCourseData(course)}
                                                                            >
                                                                                View Items
                                                                            </button>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {/* <button
                                                                                type="button"
                                                                                class="btn feature-button add-to-wishlist"
                                                                                onClick={() => handleWishlistList(course)}
                                                                            >
                                                                                {wishlistData?.some((wish) => parseInt(wish?.id) === parseInt(course?.id))
                                                                                    ? "Remove Wishlist"
                                                                                    : "Add to Wishlist"
                                                                                }
                                                                            </button> */}
                                                                                {
                                                                                    isUserLoggedIn ? (
                                                                                       
                                                                                        cartData && cartData.some(cartItem => cartItem?.course?.id === course?.id) ? (
                                                                                         
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn add-to-cart-button"
                                                                                                onClick={() => navigate("/cart")}
                                                                                            >
                                                                                                Go to cart
                                                                                            </button>
                                                                                        ) : (
                                                                                        
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn add-to-cart-button"
                                                                                                onClick={() => handleAddToCart(course)}
                                                                                            >
                                                                                                Add to cart
                                                                                            </button>
                                                                                        )
                                                                                    ) : (

                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn feature-button enroll-button"
                                                                                            onClick={() => course?.is_premium === "free" ? handleFreeCourse(course) : handlePurchase(course)}
                                                                                        >
                                                                                            Enquiry Now
                                                                                        </button>
                                                                                    )
                                                                                }
                                                                        </>
                                                                    )}
                                                                </Flex>
                                                            </Card>
                                                        </Col>
                                                    </>
                                                );
                                            })}
                                        </>
                                    )}
                                </Row>
                                {(matchedCourses)?.length > 0 ? (
                                    <Pagination
                                        className="pagination"
                                        current={current}
                                        onChange={onChange}
                                        total={displayedCourses?.length}
                                        pageSize={pageSize}
                                    />
                                ) : (
                                    ""
                                )}
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

            <Modal
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                className="paymentModal"
                centered
                width={1140}
                footer={false}
            >
                <Payment paymentCourseData={paymentCourseData} />
            </Modal>
        </>
    );
}
