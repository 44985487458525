import { useEffect, useState } from 'react';
import { Checkbox, Row, Col, Form, Select, Input } from 'antd';
import { IoIosHelpCircleOutline } from 'react-icons/io';
import * as getServices from '../../services/getServices'
import { useDispatch } from 'react-redux';
const { Option } = Select
export default function CoursePolicy({ coursePolicy, setCoursePolicy, handlehelp, type }) {
    const dispatch = useDispatch();
    const [trialDaysOptions, setTrialDaysOptions] = useState([]);

    const handleCheckboxChange = (key) => (e) => {
        let value = key === 'refund_policy' ? e.target.value : e.target.checked ? 1 : 0;
        if (key === 'refundable' && !e.target.checked) {
            setCoursePolicy((prevState) => ({
                ...prevState,
                [key]: value,
                'refund_policy': ""
            }));
        } else if (key === 'trial' && !e.target.checked) {
            setCoursePolicy((prevState) => ({
                ...prevState,
                [key]: value,
                'trial_days': 0
            }));
        } else {
            setCoursePolicy((prevState) => ({
                ...prevState,
                [key]: value,
            }));
        }
    };

    // const onChange = (days) => {
    //     console.info(days, "days")
    //     setCoursePolicy((prevState) => ({
    //         ...prevState,
    //         'trial_days': days,
    //     }));
    // }

    useEffect(() => {
        if (coursePolicy.trial === 1) {
            try {

                dispatch(getServices.getTrailCourse()).then((response) => {
                    setTrialDaysOptions(response.trial_list);
                })

            } catch (error) {
                console.error("Failed to fetch trial days:", error);
            }

        }
    }, [coursePolicy.trial]);

    const { TextArea } = Input;

    return (<>

        <div className="cancellation-pollicy-checkbox-container course_cancelation_policy">
            <Checkbox
                disabled={type === "view" ? true : false}
                defaultChecked={coursePolicy.refundable == 0 ? false : true}
                className="cancellation-pollicy-checkbox"
                onChange={handleCheckboxChange('refundable')}
            >
                <span className="cancellation-policy-text">
                    Add Refund Policy
                </span>
            </Checkbox>
        </div>
        {coursePolicy.refundable == 1 ? (
            <>
                <Row className="form-row">
                    <Col md={24} className="custom-padding">
                        <IoIosHelpCircleOutline onClick={handlehelp} className='course_refund_icon' />
                        <Form.Item
                            className="form-input-class"
                            name="refund_policy"
                            label="Refund Policy"
                        >
                            <TextArea
                                disabled={type === "view" ? true : false}
                                onBlur={handleCheckboxChange('refund_policy')}
                                className="leave-comment-text-area"
                                rows={4}
                                defaultValue={coursePolicy.refund_policy}
                                placeholder="Add cancellation policy"
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </>
        ) : (
            ""
        )}
        <div className="free-course-checkbox-container mb-0">
            <Checkbox
                disabled={type === "view" ? true : false}
                defaultChecked={coursePolicy.is_premium === 1 ? true : false}
                className="free-course-checkbox"
                onChange={handleCheckboxChange('is_premium')}
            >
                <span className="mark-as-free-course-text">
                    Mark as Free
                </span>
            </Checkbox>
        </div>

        <div className="cancellation-pollicy-checkbox-container mb-0">
            <Checkbox
                disabled={type === "view" ? true : false}
                defaultChecked={coursePolicy.trial == 0 ? false : true}
                className="cancellation-pollicy-checkbox"
                onChange={handleCheckboxChange('trial')}
            >
                <span className="cancellation-policy-text">
                    Add available for trial
                </span>
            </Checkbox>
        </div>
        {coursePolicy.trial == 1 ? (
            <Col md={12} className="input-new-course">
                <Form.Item
                    className="form-input-class"
                    name="trial_days"
                    label="Trial Days"
                    rules={[
                        {
                            required: true,
                            message: "Please select trial days.",
                        },
                    ]}
                >
                    
                    <Select
                        disabled={type === "view" ? true : false}
                        className="input-box-login select-max-age"
                        placeholder="Select trial days"
                    // onChange={(days) => onChange(days)}
                    >
                        {trialDaysOptions.map((day) => (
                            <>
                                <Option key={day.trail_days} value={day.trial_days} className="d-flex">
                                    {day.trail_days} days
                                </Option>
                            </>
                        ))}
                    </Select>
                </Form.Item>
            </Col>
        ) : (
            ""
        )}
    </>)
}
