import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import * as getServices from "../../services/getServices";
import {DEMO_IMAGE_URL} from "../../Helper"



export default function CategorySlider() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const categories = useSelector((state) => state.user.categoriesData);

    const settings = {
        dots: false,
        speed: 500,
        arrows: true, 
        slidesToShow: 5,
        slidesToScroll: 1,
        infinite: false,
        autoplay: false,
        autoplaySpeed: 1000,
        arrows: categories?.length >= 5,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    speed: 3000,
                    autoplaySpeed: 5000,
                    autoplay: true,
                    infinite: true,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    autoplay: true,
                    infinite: true,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    autoplay: true,
                    infinite: true,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    autoplay: true,
                    infinite: true,
                    slidesToScroll: 1
                }
            }
        ]
    };


    const handleSliderOptions = (category) => {
        const querystring = `?parent_category=${JSON.stringify([category?.id])}`
        dispatch(getServices.getCourses(querystring)).then((response) => {
            navigate("/course-listing", {
                state: { cartegoryPageData: response?.data , categoryArray:querystring },
            });
        }).catch((err) => {
            console.error({ err });
        })
    };
    const chunkArray = (array, size) => {
        const result = [];
        for (let i = 0; i < array?.length; i += size) {
            result.push(array.slice(i, i + size));
        }
        return result;
    };

    // Chunk the categories into groups of 5
    const categoryChunks = chunkArray(categories, 5)
    return (
        <div className="container">
            <Slider className="slider-outer-1" {...settings}>
                {categories?.map((category) => (
                    <div
                        
                        key={category.id}
                        onClick={() => handleSliderOptions(category)}
                        className="slide-item"
                    >
                        {category?.logo ? (
                            <img src={`${DEMO_IMAGE_URL}${category.logo}`} alt="Category Logo" />
                        ) : (
                           <h6>no logo</h6>
                        )}

                        {/* <img src={category?.logo ? category?.logo : ''}/> */}
                        <h3 className="img-title">{category?.name}</h3>
                    </div>
                ))}
            </Slider>
        </div>
    );
}
