import React, { useState,useContext } from "react";
import { Input, Form, Button, Select } from "antd";
import { Country, State, City } from "country-state-city";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CartContext } from '../../Helpers/CartContext';
import Swal from "sweetalert2";

export default function AddressForm({ onData, payLoader }) {
    // const [showPassword, setShowPassword] = useState(false);

    // Function to handle checkbox change
    // const onCheckboxChange = (e) => {
    //   setShowPassword(e.target.checked);
    // };
    const { cart } = useContext(CartContext);
    const navigate = useNavigate();
    const isUserLoggedIn = useSelector((state) => state.user.isUserLoggedIn);
    // const isVendorLoggedIn = useSelector((state) => state.user.isVendorLoggedIn);
    const userData = useSelector((state) => state.user.userData)
    const allCountries = Country.getAllCountries();
    const [selectedCountry, setSelectedCountry] = useState({
        key: {
            isCode: "SG",
            name: "Singapore",
        },
    });
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);


    const state = State?.getStatesOfCountry(selectedCountry?.key?.isCode);
    const Cities = City.getCitiesOfState(
        selectedCountry?.key?.isCode,
        selectedState?.key?.isCode
    );

    const handleCountryChange = (country, key) => {
        setSelectedCountry(key);
    };

    const handleStateChange = (state, key) => {
        setSelectedState(key);
    };

    const handleCityChange = (city) => {
        setSelectedCity(city);
    };

    const onFinish = (values) => {

        if(cart >0){

            onData(values);
        }else{
            Swal.fire(
                "Empty Cart",
                "Please add items to cart first.",
                "info"
            )
        }
    };

    const onFinishFailed = () => { };
    return (
        <>

            {!isUserLoggedIn ? (
                <div className="aleard-have-account d-flex justifu-content-center align-items-center">
                    <p className="aleady-have-account-text">Already have account ?</p>
                    <button className="account-login-button" onClick={() => navigate("/login")}>Login</button>
                </div>
            ) : (
                <></>
            )}
            <Form
                className="addressForm"
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                layout="vertical"
                initialValues={{
                    country: selectedCountry?.key?.name,
                    state: selectedState?.key.name,
                    city: selectedCity,
                    email: userData?.user?.email,
                    name: userData?.user?.name
                }}
            >
                <div className="row">
                    <div className="col-xl-12 col-md-12">
                        <p className="mt-1  title-info">We'll use this email to send you details and updates about your order.</p>
                    </div>
                    <div className="col-xl-12 col-md-12">
                        <Form.Item
                            name="email"
                            label="Email"
                            rules={[
                                {
                                    required: true,
                                    message: "Email is not valid",
                                    type: "email",
                                },
                            ]}
                        >
                            <Input disabled={isUserLoggedIn ? true : false} className="form-control" placeholder="Email" />
                        </Form.Item>
                    </div>
                    {!isUserLoggedIn ? (
                        <div className="col-xl-12 col-md-12">
                            <Form.Item
                                name="password"
                                label="Password"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input your password!",
                                    },
                                    {
                                        min: 6,
                                        message: "Password must be at least 6 characters long!",
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || /[!@#$%^&*(),.?":{}|<>]/.test(value)) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error(
                                                    "Password must contain at least one special character!"
                                                )
                                            );
                                        },
                                    }),
                                ]}
                            >
                                <Input type="password" className="form-control" placeholder="Password" />
                            </Form.Item>
                        </div>
                    ) : (<></>)}
                </div>
                <h4 className="formTitle mt-1">Billing details</h4>
                <p className="mt-1 title-info">Enter the billing address that matches your payment method.</p>
                <div className="row">
                    <div className="col-xl-12 col-md-12">
                        <Form.Item
                            name="name"
                            label="Name"
                            rules={[{ required: true, message: "Please enter name." }]}
                        >
                            <Input className="form-control" placeholder="Name" />
                        </Form.Item>
                    </div>
                </div>
                {/* <Form.Item
                    name="house_no"
                    label="Flat/House no"
                    rules={[{ required: true, message: "Please enter flat/house no." }]}
                >
                    <Input className="form-control" placeholder="Flat/House no." />
                </Form.Item> */}

                <Form.Item
                    name="address_line1"
                    label="Address"
                    rules={[{ required: true, message: "Please enter address." }]}
                >
                    <Input className="form-control" placeholder="Address" />
                </Form.Item>
                <Form.Item
                    name="address_line2"
                    label="Alternative Address (optional)"
                >
                    <Input className="form-control" placeholder="Address" />
                </Form.Item>
                
                <div className="row">
                    <div className="col-xl-6 col-md-12">
                        <Form.Item name="country" label="Country" rules={[{ required: true, message: "Please select county" }]}>
                            <Select
                                className="formSelect"
                                defaultValue={
                                    selectedCountry ? selectedCountry?.key?.name : allCountries.find((country) => country.name === "Singapore")?.name
                                }
                                onChange={(value, key) => handleCountryChange(value, key)}
                                placeholder="Select Country"
                                options={allCountries.map((item) => ({
                                    label: (
                                        <>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "flex-start",
                                                    alignItems: "center",
                                                    gap: "0.5rem",
                                                }}
                                            >
                                                <img
                                                    src={`https://flagcdn.com/16x12/${item.isoCode.toLocaleLowerCase()}.png`}
                                                    alt=""
                                                    className="custom-option-flag"
                                                />
                                                {item.name}
                                            </div>
                                        </>
                                    ),
                                    value: item.name,
                                    key: { name: item.name, isCode: item?.isoCode },
                                }))}
                            />
                        </Form.Item>
                    </div>
                    <div className="col-xl-6 col-md-12">
                        <Form.Item name="state" label="State" rules={[{ required: true, message: "Please select state" }]}>
                            <Select
                                defaultValue={
                                    selectedState ? selectedState?.key?.name : ""
                                }
                                className="formSelect"
                                onChange={(value, key) => handleStateChange(value, key)}
                                // isDisabled={selectedCountry === null ? true : false}
                                placeholder="Select State"
                                options={state.map((item) => ({
                                    label: (
                                        <>
                                            {item?.name}
                                        </>
                                    ),
                                    value: item?.name,
                                    key: { name: item.name, isCode: item?.isoCode },
                                }))}
                            />
                        </Form.Item>
                    </div>
                    <div className="col-xl-6 col-md-12">
                        <Form.Item name="city" label="City">
                            <Select
                                defaultValue={
                                    selectedCity ? selectedCity : ""
                                }
                                className="formSelect"
                                placeholder="Select City"
                                onChange={handleCityChange}
                                options={Cities.map((item) => ({
                                    label: (
                                        <>
                                            {item?.name}
                                        </>
                                    ),
                                    value: item.name,
                                    key: { name: item.name, isCode: item?.isoCode },
                                }))}
                            />
                        </Form.Item>
                    </div>
                    <div className="col-xl-6 col-md-12">
                        <Form.Item
                            name="zip_code"
                            label="Postal Code"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter postal code.",
                                },
                                {
                                    validator: (_, value) =>
                                        value && value.length === 6
                                            ? Promise.resolve()
                                            : Promise.reject(
                                                new Error("Postal code must be exactly 6 digits.")
                                            ),
                                },
                            ]}
                        >
                            <Input
                                placeholder="Postal Code"
                                className="form-control"
                                maxLength={6}
                                onKeyPress={(e) => {
                                    if (!/[0-9]/.test(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                                onPaste={(e) => {
                                    const paste = (e.clipboardData || window.clipboardData).getData(
                                        "text"
                                    );
                                    if (!/^[0-9]+$/.test(paste)) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </Form.Item>
                    </div>
                </div>
                <Form.Item
                    name="phone_number"
                    label="Phone Number"
                    rules={[
                        {
                            required: true,
                            message: "Please enter phone number.",
                        },
                        {
                            validator: (_, value) =>
                                value && value.length === 10
                                    ? Promise.resolve()
                                    : Promise.reject(
                                        new Error(
                                            "Phone number must be exactly 10 digits."
                                        )
                                    ),
                        }
                    ]}
                >
                    <Input className="form-control" placeholder="Phone Number" maxLength={10}
                        onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                                e.preventDefault();
                            }
                        }}
                        onPaste={(e) => {
                            const paste = (
                                e.clipboardData || window.clipboardData
                            ).getData("text");
                            if (!/^[0-9]+$/.test(paste)) {
                                e.preventDefault();
                            }
                        }} />
                </Form.Item>
                <Form.Item name="landmark" label="Landmark (Optional)">
                    <Input
                        style={{ padding: "10px" }}
                        className="form-control"
                        placeholder="Landmark"
                    />
                </Form.Item>
                <Form.Item className="mb-0" wrapperCol={{ offset: 8, span: 24 }}>
                    <Button disabled={payLoader} className="register-button button-register" htmlType="submit">
                        {payLoader ? "Processing..." : "Pay Now"}
                    </Button>
                </Form.Item>
            </Form>



        </>
    );
}
