import React, { useState, useEffect } from "react";
import { Input, Button, Image, Row, Col } from "antd";
import { useDispatch } from "react-redux";
import * as getServices from "../../services/getServices";
import * as uploadService from "../../services/uploadServices";
import RecentCourse from "../HomePage/RecentCourse";
import { useNavigate } from "react-router-dom";
import {DEMO_IMAGE_URL} from "../../Helper"

export default function Categories() {
  const { Search } = Input;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    dispatch(getServices.getCategories()).then((response) => {
      setCategories(response?.categories || []);
    });
  }, [dispatch]);

  useEffect(() => {
    setFilteredCategories(
      categories.filter((category) =>
        category?.name?.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery, categories]);
  
 

  const handleCategorySearch = (category) =>{
     dispatch(uploadService.getCoursesByCategoryID({parent_id:category?.id})).then((response)=>{
      navigate("/search", {
        state: { cartegoryPageData: response?.data },
      });
     }).catch((err) => {
       console.error({ err });
     })
  }


  return (
    <>
      <div className="categories-container">
        <div className="categories-banner-container">
          <p className="categories-heading">Categories</p>
          <p className="categories-sub-heading">Browse all categories</p>
        </div>

        <div className="our-categories-outer-container">
          <div className="our-categories-inner-container container">
            <div className="our-categories-head-container">
              <div className="text-container">
                <p className="our-categories-head-text">Our Categories</p>
                <p className="our-categories-sub-head-text">
                  Explore our Popular Categories
                </p>
              </div>
              <div className="search-input-container">
                <Search
                  className="search-input"
                  placeholder="Search category"
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>
            <div className="categories-div">
              <div className="inner-categories-container">
                <Row className="categories-list-row ">
                  {(filteredCategories.length > 0 ? filteredCategories : categories)
                    .slice(0, 10)
                    .map((category, index) => (
                      <Col key={index} className="categories-list-col col-xl-3 col-md-4 px-3">
                        <Button className="category-button">
                          <Image
                            className="category-button-icon"
                            preview={false}
                            src={`${DEMO_IMAGE_URL}${category?.logo}`}
                          />
                          <p className="category-button-text">{category?.name}</p>
                          <p className="view-text">
                            <Button onClick={()=> handleCategorySearch(category)}>View</Button>
                          </p>
                        </Button>
                      </Col>
                    ))}
                  {categories.length > 10 && (
                    <Col className="categories-list-col col-xl-3 col-md-4 px-3" >
                      <Button className="view-more-button">
                        <p className="view-text">
                          <a href="/">View more</a>
                        </p>
                      </Button>
                    </Col>
                  )}
                </Row>
              </div>
            </div>
          </div>
        </div>
        <RecentCourse />
      </div>
    </>
  );
}
