import React, { useState, useEffect } from "react";
import { MdOutlinePhone } from "react-icons/md";
import { CiMail } from "react-icons/ci";
import { AiOutlineGlobal } from "react-icons/ai";
import { CiLocationOn } from "react-icons/ci";
import { FaTwitter } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import * as getServices from "../../services/getServices";
import { useDispatch, useSelector } from "react-redux";
import { DownOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import {
    Dropdown,
    Space,
} from "antd";
import { DEMO_IMAGE_URL } from "../../Helper"

const Footer = () => {
    const [pages, setPages] = useState([]);
    const dispatch = useDispatch();
    const logo = useSelector((state) => state?.user?.logoData)
    const user = useSelector((state) => state?.user?.isUserLoggedIn)
    const location = useLocation()
    const currentPath = location.pathname;
    useEffect(() => {
        const fetchData = async () => {
            try {
                const pagesResponse = await dispatch(getServices?.getAllPages());
                setPages(pagesResponse);
            } catch (err) {
                console.error("Error fetching pages:", err);
            }
        };
        fetchData();
    }, []);


    // const pageMenu = (pages) => {
    //     let new_menu = [];
    //     pages.map((page) => new_menu.push({ label: page.title, key: page.id }));
    //     return new_menu;
    // };

    const pageMenu = (pages) => {
        return pages.map((page) => ({
            label: page.title,
            key: page.id,
        }));
    };

    const handleCategoryDropdownSearch = (e) => {
        e.preventDefault();
    }

    return (
        <>
            <footer>
                <div className="footer-grid">
                    <div className="footer-row row">
                        <div className="col-lg-4 col-md-6 mb-3">
                            <div className="grid-item">
                                <div className="footer-logo">
                                    <img
                                        className="well-of-fun-logo"
                                        src={`${DEMO_IMAGE_URL}${logo?.back_logo}`}
                                        alt="alpha97 logo white"
                                    />
                                </div>
                                <p className="footer-text">{logo?.description}</p>
                                <div className="social-link">
                                    <a
                                        href={logo?.twitter}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="twitter-icon-container"
                                    >
                                        <FaTwitter />
                                    </a>
                                    <a
                                        href={logo?.facebook}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="facebook-icon-container"
                                    >
                                        <FaFacebookF />
                                    </a>
                                    <a
                                        href={logo?.instagram}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="insta-icon-container"
                                    >
                                        <FaInstagram />
                                    </a>
                                    <a
                                        href={logo?.github}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="git-icon-container"
                                    >
                                        <FaGithub />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-3">
                            <ul className="grid-item text-decoration-none">
                                <h4 className="item-heading">Our Links</h4>
                                <li style={{ listStyleType: "none" }} className="list-item">
                                    {
                                        user ? (
                                            <a
                                                style={{ textDecoration: "none", color: "#52525B" }}
                                                href="/user/wishlist"
                                            >
                                                Wishlist
                                            </a>
                                        ) : ""
                                    }
                                </li>
                                <li style={{ listStyleType: "none" }} className="list-item">
                                    <a
                                        style={{ textDecoration: "none", color: "#52525B" }}
                                        href="/blogs"
                                    >
                                        Blogs
                                    </a>
                                </li>
                                <li style={{ listStyleType: "none" }} className="list-item">
                                    <a
                                        style={{ textDecoration: "none", color: "#52525B" }}
                                        href="/faq"
                                    >
                                        FAQ
                                    </a>
                                </li>
                                {pages.length > 0 &&
                                    pages?.map((page) => {
                                        return page.child_data.length > -1 ? (
                                            <li key={page.id} style={{ listStyleType: "none" }} className="list-item">
                                                <a
                                                    style={{ textDecoration: "none", color: "#52525B" }}
                                                    href={`/${page.slug}`}>
                                                    {page.title}
                                                  
                                                </a>
                                            </li>
                                        ) : (
                                            <Dropdown key={page.id} menu={{ items: pageMenu(page.child_data) }}>
                                                <a href="/" onClick={(e) => handleCategoryDropdownSearch}>
                                                    <Space>
                                                        <div className="heading">{page.title}</div>
                                                        <DownOutlined />
                                                    </Space>
                                                </a>
                                            </Dropdown>
                                        );
                                    })}
                            </ul>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="cont-address">
                                <h2>Contact Information</h2>
                                <div className="contact-child-1">
                                    <div className="icon-class">
                                        <MdOutlinePhone color="#106AB3" />
                                    </div>
                                    <a href={`tel:${logo?.phone}`}>
                                        <p>{logo?.phone}</p>
                                    </a>
                                </div>
                                <div className="contact-child-1">
                                    <div className="icon-class">
                                        <CiMail color="#106AB3" />
                                    </div>
                                    <a href={`mailto:${logo?.email}`}>
                                        <p>{logo?.email}</p>
                                    </a>
                                </div>
                                <div className="contact-child-1">
                                    <div className="icon-class">
                                        <AiOutlineGlobal color="#106AB3" />
                                    </div>
                                    <a
                                        href={`${logo?.website.startsWith("http")
                                            ? ""
                                            : "https://"
                                            }${logo?.website}`}
                                    >
                                        <p>{logo?.website}</p>
                                    </a>
                                </div>
                                <div className="contact-child-1">
                                    <div className="icon-class">
                                        <CiLocationOn color="#106AB3" />
                                    </div>
                                    <a href="https://maps.app.goo.gl/nc8BzUEVmEq5HGmR9">
                                        <p>{logo?.address}</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copy-right-outer-container">
                    <div className="copy-right-container col-md-12">
                        <p className="copyright">© Copyright 2024, All Rights Reserved</p>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;
