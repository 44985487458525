import React, { useEffect, useState } from "react";
import { Steps } from "antd";
import RegistrationStepOne from "./vendorRegistrationSteps.js/registrationStepOne";
import RegistrationStepTwo from "./vendorRegistrationSteps.js/registrationStepTwo";
import RegistrationStepThree from "./vendorRegistrationSteps.js/registrationStepThree";
import {useDispatch} from "react-redux";
import * as UserServices from "../../services/userServices";
import * as getServices from "../../services/getServices"
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

export default function VendorRegister() {
  const navigate = useNavigate();

  const dispatch = useDispatch()

  const [basicDetails, setBasicDetails] = useState();
  const [schoolDetails, setSchoolDetails] = useState();
  const [additionalDetails, setAdditionalDetails] = useState();
  const [activeStep, setActiveStep] = useState(0);
  const [categories, setCategories] = useState();
  const [subCategories, setSubCategories] = useState();
  const [loader,setLoader] = useState(false)


  useEffect(()=>{
     dispatch(getServices.getCategories()).then((response)=>{
       setCategories(response?.categories)
       response?.categories.map((subCategory) => (
         setSubCategories(subCategory?.sub_categories)
       ))
     }).catch((err)=>{
       console.error({err})
     })
   
  },[dispatch])

  const onFinish = (basicDetails,schoolDetails, additionalDetails) => {
    setLoader(true)

    const formData = new FormData();
    formData.append("email", basicDetails.email)
    formData.append("name", basicDetails.fullName)
    formData.append("password", basicDetails.password)
    formData.append("phone", basicDetails.phone)
    formData.append("school",schoolDetails.school_name)
    formData.append("website_url",schoolDetails.website_url)
    formData.append("address",schoolDetails.address)
    formData.append("business_type",schoolDetails.business_type)
    formData.append("business_identification_type",schoolDetails.business_identification_type)
    formData.append("business_identification_no",schoolDetails.business_identification_no)
    formData.append("gst",schoolDetails.gst_no)
    formData.append("about_school",schoolDetails.about_school)
    formData.append("email_notification",additionalDetails.email_for_notification ? additionalDetails.email_for_notification : "")
    formData.append("refund_cancellation_policy",additionalDetails.refund_policy)
    formData.append("user_type","2")
    formData.append("logo",schoolDetails?.logo)

    dispatch(UserServices.Register(formData))
    .then((response) => { 
      setLoader(false)
      if (response.status === 402) {
        Swal.fire(
          "Email Already Registered",
          "Please try with a different email address!",
          "error"
        );
      } else {
        Swal.fire(
          "Registration Successful!",
          "You have successfully registered with Well Of Fun!",
          "success"
        ).then((result) => {
          // Reload the Page
          navigate("/login")
        });
      }
    })
      .catch((err) => {
      setLoader(false)
        Swal.fire({
          title: `${err?.response?.data?.message}`,
         icon: "error"
      }).then((result) => {
        // Reload the page
        window.location.reload();
      });
    });
  };

  const goBack = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    activeStep === 0 ? setActiveStep(0) : setActiveStep(activeStep - 1);
  };

  const goNext = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    activeStep === 3 ? onFinish() : setActiveStep(activeStep + 1);
  };

  const handleBasicDetailsData = (data) => {
    setBasicDetails(data);
    goNext();
  };

  const handleSchoolDetailsData = (data,about_school,logo) => {
    data.about_school = about_school;
    data.logo=logo
    setSchoolDetails(data);
    goNext(); // Move to the next step after setting the data
  };

  const handleAdditionalDetailsData = (data,refund_policy) => {
    data.refund_policy = refund_policy; 
    setAdditionalDetails(data);
    onFinish(basicDetails,schoolDetails, data)
    // goNext();
  };

  const steps = [
    {
      title: <span className="stepper-title">Basic Details</span>,
      content: <RegistrationStepOne onData={handleBasicDetailsData} basicDetails={basicDetails} />,
      icon: 1,
    },
    {
      title: <span className="stepper-title">Organisation Details</span>,
      content: <RegistrationStepTwo onData={handleSchoolDetailsData} schoolInfo={schoolDetails} goBack={goBack}/>,
      icon: 2,
    },
    {
      title: <span className="stepper-title">Additional Details</span>,
      content: <RegistrationStepThree onData={handleAdditionalDetailsData} additionalInfo={additionalDetails} categories={categories} subCategories={subCategories} goBack={goBack} loader={loader} />,
      icon: 3,
    },
    // {
    //   title: <span className="stepper-title">Payments</span>,
    //   content: <RegistrationStepFour onData={handleBankDetailsData}  bankInfo={bankDetails}  goBack={goBack} Loader={loader} />,
    //   icon: 4,
    // },
  ];

  const items = steps.map((item) => ({
    key: item?.title,
    title: item?.title,
  }));

  return (
    <div>
      <Steps current={activeStep} labelPlacement="vertical" items={items} />
      {steps[activeStep].content}
    </div>
  );
}
