import React, { useState, useRef } from "react";
import { Row, Col, Input, Form, Button,Spin } from "antd";
import { CiMail } from "react-icons/ci";
import { LoadingOutlined } from '@ant-design/icons';
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

import {
	align,
	font,
	fontColor,
	fontSize,
	formatBlock,
	hiliteColor,
	horizontalRule,
	lineHeight,
	list,
	paragraphStyle,
	table,
	template,
	textStyle,
	image,
	link,
} from "suneditor/src/plugins";
import { color } from "highcharts";

export default function RegistrationStepThree({
	onData,
	goBack,
	loader
}) {
	const SunEditorOpts = {
		showPathLabel: false,
		imageMultipleFile: true,
		imageWidth: "150px",
		imageHeight: "150px",
		height: "auto",
		minHeight: "200px",
		requestHeaders: {
			"X-Sample": "sample",
		},
		plugins: [
			align,
			font,
			fontColor,
			fontSize,
			formatBlock,
			hiliteColor,
			horizontalRule,
			lineHeight,
			list,
			paragraphStyle,
			table,
			template,
			textStyle,
			image,
			link,
		],
		buttonList: [
			["undo", "redo"],
			["font", "fontSize", "formatBlock"],
			["paragraphStyle"],
			["bold", "underline", "italic", "strike", "subscript", "superscript"],
			["fontColor", "hiliteColor"],
			["removeFormat"],
			["align", "horizontalRule", "lineHeight"],
			["outdent", "indent"],
			["list"],
			["table", "link", "image"],
			["fullScreen", "showBlocks", "preview"],
		],
		formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
		font: [
			"Arial",
			"Calibri",
			"Comic Sans",
			"Courier",
			"Garamond",
			"Georgia",
			"Impact",
			"Lucida Console",
			"Palatino Linotype",
			"Segoe UI",
			"Tahoma",
			"Times New Roman",
			"Trebuchet MS",
			"Roboto",
		],
	};

	const editorRef = useRef(null);
	const [editorContent, setEditorContent] = useState("");
	const onFinish = (values) => {
		onData(values, editorContent);
	};

	const onFinishFailed = () => {
		// console.info("finish failed");
	};

	const handleEditorChange = (content) => {
		setEditorContent(content);
	};

	return (
		<Row className="step-one-container">
			<h2 className="vendor-register-step-one-head">Additional Information</h2>
			<Form
				className="step-one-form"
				name="basic"
				labelCol={{
					span: 8,
				}}
				wrapperCol={{
					span: 24,
				}}
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				autoComplete="off"
			>

				<Row>
					<Col md={24} className="custom-padding">
						<p
							style={{
								textAlign: "start",
								fontFamily: "POPPINS",
							}}
						>
							Email for Notification
						</p>

						<Form.Item
							className="form-input-class"
							name="email_for_notification"
						>
							<Input
								className="input-box-login"
								placeholder="Enter Email to Get Notifications"
								prefix={<CiMail className="form-icons" />}
							/>
						</Form.Item>
					</Col>
				</Row>

				<Row>
					<Col md={24} className="custom-padding">
						<p
							style={{
								textAlign: "start",
								fontFamily: "POPPINS",
							}}
						>
							Refund & Cancellation policy
						</p>

						<Form.Item
							className="form-input-class"
							name="refund_policy"
						>
							<div className="suneditor-outer-new px-0">
								<SunEditor
									getSunEditorInstance={(editor) => {
										editorRef.current = editor;
									}}
									setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
									setOptions={SunEditorOpts}
									onChange={handleEditorChange}
								/>
							</div>
						</Form.Item>
					</Col>
				</Row>

				<Row>
					<Col md={12}>
						<Form.Item className="mb-0" wrapperCol={{ offset: 8, span: 24 }}>
							<Button
								className="register-button button-register go-back-vendor"
								htmlType="button"
								onClick={goBack}
							>
								Back
							</Button>
						</Form.Item>
					</Col>
					<Col md={12}>
						<Form.Item className="mb-0" wrapperCol={{ offset: 8, span: 24 }}>
							<Button
								className="register-button button-register next-button"
								htmlType="submit"
							>
							{loader ? (
								<div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                            <Spin indicator={<LoadingOutlined style={{ color:"#fff" }} spin />} />
                        </div>
							):("Submit")}
							</Button>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Row>
	);
}
