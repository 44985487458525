// src/store/appSlice.js
import { createSlice } from '@reduxjs/toolkit';

// Initial state 
const initialState = {
    userData: null,
    isUserLoggedIn: false,
    isVendorLoggedIn: false,
    notificationData: null,
    token: null,
    cart: [],
    categoriesData: [],
    logoData: null,
    userType: null,
    userID: null,
    total_item: 0,
    total_amount: 0,
};

const appSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserData: (state, action) => {
            state.userData = action.payload;
        },
        setUserLoggedIn: (state, action) => {
            state.isUserLoggedIn = action.payload;
        },
        setVendorLoggedIn: (state, action) => {
            state.isVendorLoggedIn = action.payload;
        },
        setNotification: (state, action) => {
            state.notificationData = action.payload;
        },
        setToken: (state, action) => {
            state.token = action.payload;
        },
        add_To_Cart: (state, action) => {
            const item = action.payload;
            state.cart.push(item);
            state.total_item += 1;
            state.total_amount += parseInt(item.fee);
        },
        removeFromCart: (state, action) => {

            const itemId = action.payload;
            const itemToRemove = state.cart.find(item => item.id === itemId);
            state.cart = state.cart.filter(item => item.id !== itemId);
            state.total_item -= 1;
            state.total_amount -= itemToRemove ? itemToRemove.fee : 0;
        },
        setCategoriesData: (state, action) => {
            state.categoriesData = action.payload;
        },
        setSettingLogo: (state, action) => {
            state.logoData = action.payload;
        },
    },
});

// Export actions and reducer
export const {
    setUserData,
    setUserLoggedIn,
    setVendorLoggedIn,
    setNotification,
    setToken,
    add_To_Cart,
    removeFromCart,
    setCategoriesData,
    setSettingLogo
} = appSlice.actions;

export default appSlice.reducer;
