import React from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Home from "../pages/HomePage/home";
import Login from "../pages/User/userLogin";
import Register from "../pages/Register/register";
import Dashboard from "../pages/User/Dashboard/dashboard";
import MyRewards from "../pages/User/Dashboard/myRewards";
import MyReviews from "../pages/User/Dashboard/myReviews";
import Wishlist from "../pages/User/Dashboard/wishlist";
import MyCoupons from "../pages/User/Dashboard/myCoupons";
import ContactAdmin from "../pages/User/Dashboard/contactAdmin";
import Blogs from "../pages/blogs/index";
import ContactUs from "../pages/contactUs/index";
import VendorDashboard from "../pages/Vendor/dashboard";
import VendorMyProfile from "../pages/Vendor/myProfile";
import AboutUs from "../pages/aboutUs";
import Faq from "../pages/faq";
import CourseListing from "../pages/courseListing";
import CourseDetails from "../pages/courseDetails";
import MyCourses from "../pages/User/Dashboard/myCourses";
import AddNewCourse from "../pages/Vendor/addNewCourse";
import MyStudents from "../pages/Vendor/myStudents";
import MyCourse from "../pages/Vendor/myCourses";
import AllReviews from "../pages/Vendor/allReviews";
import ReferAFriend from "../pages/referAFriend";
import Categories from "../pages/categories";
import UserMyProfile from "../pages/User/Dashboard/myProfile";
import SearchPage from "../pages/searchPage";
import Vendors from "../pages/vendors";
import VendorDetails from "../pages/vendorDetails";
import ForgetPassword from "../pages/forgetPassword/index";
import ResetPassword from "../pages/resetPassword/index";
import PaymentProcess from "../pages/paymentPrcess/index";
import ChangePassword from "../pages/Vendor/changePassword";
import ViewCourseDetail from "../pages/Vendor/viewCourseDetail";
import Bookings from "../pages/Vendor/bookings";
import Classes from "../pages/Vendor/classes";
import AddClasses from "../pages/Vendor/addClasses";
import PrivacyPolicy from "../pages/privacy policy/Index";
import TermCondition from "../pages/termAndcondition/index";
import WishList from '../pages/wishlist/WishList'
import AddCustom from "../pages/Vendor/AddCustom";
import ThankyouPage from "../pages/thankyouPage/index"
import UserOnBoarding from "../pages/User/userOnBoarding/index";
import ContactAdminVendor from "../pages/Vendor/contactAdmin";
import BlogDetails from "../pages/blogs/blogDetailPage"
import Testimonial from "../pages/testimonial/index";
import CourseContact from "../pages/Vendor/courseEnquiries";
import Header from "../components/Header";
import TopNavbar from "../components/topNavbar"
import Footer from "../components/Footer/footer"
import CopyWriteFooter from "../components/Footer/copywriteFooter";
import CartProvider from '../Helpers/CartContext';
import CookiesPolicy from '../components/cookies/CookiePolicy'
import Cart from "../pages/cart";
import Checkout from "../pages/checkout";
import FreeCourse from '../pages/FreeCourse/index'
import Offer from "../pages/Offer/offer";
import Coupons from '../pages/Vendor/Coupon';
import AllCoupon from "../pages/Vendor/AllCoupon";
import MyTransactions from "../pages/User/Dashboard/myTransactions"

const AppRoute = () => {
    const isUserLoggedIn = useSelector((state) => state.user.isUserLoggedIn);
    const isVendorLoggedIn = useSelector((state) => state.user.isVendorLoggedIn);
    const location = useLocation();

    const getHeader = () => {
        if (isVendorLoggedIn && location.pathname.startsWith('/vendor/')) {
            return {
                header: <TopNavbar />,
                footer: <CopyWriteFooter />
            }
        } else if (isUserLoggedIn && location.pathname.startsWith('/user/')) {
            return {
                header: <TopNavbar />,
                footer: <CopyWriteFooter />
            }
        } else {
            return {
                header: <Header />,
                footer: <Footer />
            }
        }
    };

    return (
        <>
            <CartProvider>
                {getHeader().header}
                <Routes>
                    {!isUserLoggedIn && !isVendorLoggedIn ? (
                        <>
                            {/* Auth Routes */}
                            <Route path="*" element={<Login />} />
                            <Route path="/login" element={<Login />} />
                            <Route path="/register" element={<Register />} />
                            <Route path="/forgot-password" element={<ForgetPassword />} />
                            <Route path="/reset-password/:token" element={<ResetPassword />} />
                        </>
                    ) : ''}

                    <Route path="*" element={<Home />} />
                    <Route path="/" element={<Home />} />
                    <Route path="/blogs" element={<Blogs />} />
                    <Route path="/wishlist" element={<WishList />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/terms-and-conditions" element={< TermCondition />} />
                    <Route path="/contact-us" element={<ContactUs />} />
                    <Route path="/about-us" element={<AboutUs />} />
                    <Route path="/faq" element={<Faq />} />
                    <Route path="/course-listing" element={<CourseListing />} />
                    <Route path="/course/:id/:name" element={<CourseDetails />} />
                    <Route path="/refer-a-friend" element={<ReferAFriend />} />
                    <Route path="/categories" element={<Categories />} />
                    <Route path="/search" element={<SearchPage />} />
                    <Route path="/vendors" element={<Vendors />} />
                    <Route path="/vendor-details" element={<VendorDetails />} />
                    <Route path="/payment/:user_id/:course_id" element={<PaymentProcess />} />
                    <Route path="/thankyou" element={<ThankyouPage />} />
                    <Route path="/connect-stripe-account" element={<UserOnBoarding />} />
                    <Route path="/cookie-policy" element={<CookiesPolicy />} />
                    { !isVendorLoggedIn  ? (
                        <>
                            <Route path="/cart" element={<Cart />} />
                            <Route path="/checkout" element={<Checkout />} />
                        </>
                    ) : (
                        <Route path="/login" element={<Login />} />
                    )}

                    <Route path="/free-courses" element={<FreeCourse />} />
                    <Route path="/offers" element={<Offer />} />
                    <Route path="/blog/:blog_id/:blog_title" element={<BlogDetails />} />
                    {/* <Route path="/terms-and-conditions" element={<TermsAndConditions />
                    } /> */}
                    <Route path="/blog/:blog_title" element={<BlogDetails />
                    } />
                    <Route path="/testimonial" element={<Testimonial />
                    } />

                    {/*=======================User Routes========================== */}
                    {isUserLoggedIn && !isVendorLoggedIn ? (
                        <>
                            <Route path="/user/dashboard" element={<Dashboard />} />
                            <Route path="/user/my-rewards" element={<MyRewards />} />
                            <Route path="/user/my-reviews" element={<MyReviews />} />
                            <Route path="/user/wishlist" element={<Wishlist />} />
                            <Route path="/user/my-coupons" element={<MyCoupons />} />
                            <Route path="/user/contact-admin" element={<ContactAdmin />} />
                            <Route path="/user/my-courses" element={<MyCourses />} />
                            <Route path="/user/my-profile" element={<UserMyProfile />} />
                            <Route path="/user/my-transactions" element={<MyTransactions />} />

                        </>
                    ) : isVendorLoggedIn && !isUserLoggedIn ? (
                        <>
                            {/*=======================Vendor Routes========================== */}
                            <Route path="/vendor/dashboard" element={<VendorDashboard />} />
                            <Route path="/vendor/view-course-detail" element={<ViewCourseDetail />} />
                            <Route path="/vendor/my-profile" element={<VendorMyProfile />} />
                            <Route path="/vendor/add-new-course" element={<AddNewCourse />} />
                            <Route path="/vendor/my-students" element={<MyStudents />} />
                            <Route path="/vendor/my-courses" element={<MyCourse />} />
                            <Route path="/vendor/all-reviews" element={<AllReviews />} />
                            <Route path="/vendor/sales-summary" element={<Bookings />} />
                            <Route path="/vendor/change-password" element={<ChangePassword />} />
                            <Route path="/vendor/my-classes" element={<Classes />} />
                            <Route path="/vendor/add-classes" element={<AddClasses />} />
                            <Route path="/vendor/addcustom" element={<AddCustom />} />
                            <Route path="/vendor/contact-admin" element={<ContactAdminVendor />} />
                            <Route path="/vendor/course-enquiry" element={<CourseContact />} />
                            <Route path="/vendor/coupons" element={<Coupons />} />
                            <Route path="/vendor/all-coupons" element={<AllCoupon />} />

                        </>
                    ) : (
                        <Route path="*" element={<Navigate to="/login" replace />} />
                    )}
                </Routes>

                {getHeader().footer}

            </CartProvider>
        </>
    );
}

export default AppRoute;
