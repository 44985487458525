import React, { useState } from "react";
import { Row, Col, Input, Form, Image, Spin, Button,Upload } from "antd";
import { HiOutlineBuildingOffice } from "react-icons/hi2";
import { CiGlobe } from "react-icons/ci";
import { IoLocationOutline } from "react-icons/io5";
import { BsSuitcaseLg } from "react-icons/bs";
import { FaRegAddressCard } from "react-icons/fa";
import { CiMail } from "react-icons/ci";
import { DollarSack, HashBox } from "../../../constant/images";
import { LoadingOutlined } from '@ant-design/icons';
import SunEditor from "suneditor-react";
import { PlusOutlined } from '@ant-design/icons';
import { DEMO_IMAGE_URL } from "../../../Helper";
import "suneditor/dist/css/suneditor.min.css";
import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
  image,
  link,
} from "suneditor/src/plugins";

export default function SchoolInformation({
  onData,
  vendorDetails,
  goBack,
  loader,
  vendorLogoImage
}) {
  const [editorContent, setEditorContent] = useState("");
  const [previewImage, setPreviewImage] = useState('');
  const [previewOpen, setPreviewOpen] = useState(false);
  const [vendorLogo, setVendorLogo] = useState(
    vendorLogoImage
    ? [
        {
            uid: '-1',
            name: 'courseCoverImage',
            status: 'done',
            url: `${DEMO_IMAGE_URL}${vendorLogoImage}`,
        },
    ]
    : []
);
  const SunEditorOpts = {
    showPathLabel: false,
    imageMultipleFile: true,
    imageWidth: "150px",
    imageHeight: "150px",
    height: "auto",
    minHeight: "200px",
    requestHeaders: {
      "X-Sample": "sample",
    },
    plugins: [
      align,
      font,
      fontColor,
      fontSize,
      formatBlock,
      hiliteColor,
      horizontalRule,
      lineHeight,
      list,
      paragraphStyle,
      table,
      template,
      textStyle,
      image,
      link,
    ],
    buttonList: [
      ["undo", "redo"],
      ["font", "fontSize", "formatBlock"],
      ["paragraphStyle"],
      ["bold", "underline", "italic", "strike", "subscript", "superscript"],
      ["fontColor", "hiliteColor"],
      ["removeFormat"],
      ["align", "horizontalRule", "lineHeight"],
      ["outdent", "indent"],
      ["list"],
      ["table", "link", "image"],
      ["fullScreen", "showBlocks", "preview"],
    ],
    formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
    font: [
      "Arial",
      "Calibri",
      "Comic Sans",
      "Courier",
      "Garamond",
      "Georgia",
      "Impact",
      "Lucida Console",
      "Palatino Linotype",
      "Segoe UI",
      "Tahoma",
      "Times New Roman",
      "Trebuchet MS",
      "Roboto",
    ],
  };


  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });


  const handleEditorChange = (content) => {
    setEditorContent(content);
  };

  const onFinish = (values) => {
    onData(values, editorContent,vendorLogo[0]?.originFileObj);
  };

  const uploadButton = (
    <button
        style={{
            border: 0,
            background: 'none',
        }}
        type="button"
    >
        <PlusOutlined />
        <div
            style={{
                marginTop: 8,
            }}
        >
            Upload
        </div>
    </button>
);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
};

  const handleInstructorProfileImageChange = ({ fileList }) => {
    setVendorLogo(fileList.slice(-1));
};

  const onFinishFailed = () => { };
  return (
    <Row className="step-one-container">
      <h2 className="vendor-register-step-one-head">
        Organisation Details
      </h2>
      {vendorDetails === undefined ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <>
          <Form
            className="step-one-form"
            name="basic"
            initialValues={{
              school_name: vendorDetails?.vendor?.school,
              website_url: vendorDetails?.vendor?.website_url,
              address: vendorDetails?.vendor?.address,
              business_type: vendorDetails?.vendor?.business_type,
              business_identification_type:
                vendorDetails?.vendor?.business_identification_type,
              business_identification_no:
                vendorDetails?.vendor?.business_identification_no,
              gst: vendorDetails?.vendor?.gst,
              email_for_notification: vendorDetails?.vendor?.email_notification,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
          >
            <Row>
              <Col md={12} className="basic-padding px-3">
                <Form.Item
                  className="form-input-class"
                  name="school_name"
                  label="Organisation Name"
                >
                  <Input
                    className="input-box-login"
                    placeholder="Enter school name here"
                    prefix={<HiOutlineBuildingOffice className="form-icons" />}
                  />
                </Form.Item>
              </Col>
              <Col md={12} className="basic-padding px-3" >
                <Form.Item
                  className="form-input-class"
                  name="website_url"
                  label="Website URL"           
                >
                  <Input
                    className="input-box-login"
                    placeholder="www.welloffun.com"
                    prefix={<CiGlobe className="form-icons" />}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col md={12} className="basic-padding px-3">
                <Form.Item
                  className="form-input-class"
                  name="address"
                  label="Address"
                >
                  <Input
                    className="input-box-login"
                    placeholder="Enter address here"
                    prefix={<IoLocationOutline className="form-icons" />}
                  />
                </Form.Item>
              </Col>
              <Col md={12} className="basic-padding px-3">
                <Form.Item
                  className="form-input-class"
                  name="Logo"
                  label="Logo"
                >
                  {/* <label
                    for="logo-upload"
                    className="custom-upload-logo d-flex justify-content-between"
                  > */}
                    {/* <span className="custom-input-logo-left d-flex align-items-center">
                      {" "}
                      <CiImageOn className="form-icons" /> Upload Logo
                    </span>{" "}
                    <span className="custom-input-logo-right">
                      <BsCloudUpload className="form-icons" />
                    </span> */}
                  {/* </label> */}
                  {/* <Input type='file' id='logo-upload' className="input-box-login" placeholder='www.welloffun.com' prefix={<CiImageOn className='form-icons'/>} suffix={<BsCloudUpload className='form-icons' />} hidden  /> */}
                  <Upload
                                action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                                listType="picture-card"
                                fileList={vendorLogo}
                                onPreview={handlePreview}
                                onChange={handleInstructorProfileImageChange}
                                maxCount={1}
                            >
                                {vendorLogo.length > 0 ? null : uploadButton}
                            </Upload>
                            {previewImage && (
                                <Image
                                    wrapperStyle={{
                                        display: 'none',
                                    }}
                                    preview={{
                                        visible: previewOpen,
                                        onVisibleChange: (visible) => setPreviewOpen(visible),
                                        afterOpenChange: (visible) => !visible && setPreviewImage(''),
                                    }}
                                    src={previewImage}
                                />
                            )}
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col md={12} className="basic-padding px-3">
                <Form.Item
                  className="form-input-class"
                  name="business_type"
                  label="Business Type"
                >
                  <Input
                    className="input-box-login"
                    placeholder="Enter business type"
                    prefix={<BsSuitcaseLg className="form-icons" />}
                  />
                </Form.Item>
              </Col>
              <Col md={12} className="basic-padding px-3">
                <Form.Item
                  className="form-input-class"
                  name="business_identification_type"
                  label="Business Identification Type"
                >
                  <Input
                    className="input-box-login"
                    placeholder="Enter Identification Type"
                    prefix={<FaRegAddressCard className="form-icons" />}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col md={12} className="basic-padding px-3">
                <Form.Item
                  className="form-input-class"
                  name="business_identification_no"
                  label="Business Identification No."
                >
                  <Input
                    className="input-box-login"
                    placeholder="Enter Identification no."
                    // prefix={<PiHashFill className="form-icons" />}
                    prefix={<Image preview={false} width={24} height={24} src={HashBox} />}
                  />
                </Form.Item>
              </Col>
              <Col md={12} className="basic-padding px-3">
                <Form.Item
                  className="form-input-class"
                  name="gst"
                  label="GST No."
                >
                  <Input
                    className="input-box-login"
                    placeholder="Enter GST No."
                    // prefix={<FaSackDollar className="form-icons" />}
                    prefix={<Image preview={false} width={26} height={26} src={DollarSack} />}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={24} className="basic-padding px-3">
                <Form.Item
                  className="form-input-class"
                  name="email_for_notification"
                  label="Email for Notification"
                >
                  <Input
                    className="input-box-login"
                    placeholder="Enter Email to Get Notifications"
                    prefix={<CiMail className="form-icons" />}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col md={24} className="basic-padding px-3">
                <Form.Item
                  className="form-input-class"
                  name="business_identification_type"
                  label="About us"
                >
                  <div className="suneditor-outer-new px-0">
                    <SunEditor
                      setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                      setOptions={SunEditorOpts}
                      value={vendorDetails?.vendor?.about_school}
                      onChange={handleEditorChange}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <Row className="form-back-next-button">
              <Col md={12} className="text-center">
                <Form.Item
                  className="mb-0"
                  wrapperCol={{
                    offset: 8,
                    span: 24,
                  }}
                >
                  <Button
                    className="go-back-vendor"
                    htmlType="button"
                    onClick={goBack}
                  >
                    Back
                  </Button>
                </Form.Item>
              </Col>
              <Col md={12} className="text-center">
                <Form.Item
                  className="mb-0"
                  wrapperCol={{
                    offset: 8,
                    span: 24,
                  }}
                >
                  <Button className="go-next-vendor" htmlType="submit">
                    {loader ? (
                      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                            <Spin indicator={<LoadingOutlined spin />} />
                        </div>
                    ):("Save")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </Row>
  );
}
