import React, { useEffect, useState } from "react";
import {  Tabs, Select,  Button } from "antd";
import {
    AllBlogs,
    SpecificBlogs
} from "./blogDetails";
import * as getServices from "../../services/getServices";
import { useDispatch } from "react-redux";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import {DEMO_IMAGE_URL} from "../../Helper";
import Offer from "../Offer/offer"

export default function Blogs() {
    const dispatch = useDispatch()
    const [banner, setBanner] = useState([])
    const [blogs, setBlogs] = useState([])
    const navigate = useNavigate()
    const handleBack = () => {
        navigate("/");
    }
    useEffect(() => {
        const fetchBlogLength = async () => {
            try {
                const response = await dispatch(getServices?.getBlogs());
                setBlogs(response?.blogs);
            } catch (error) {
                console.error({ error });
            }
        };


        dispatch(getServices?.getBanner('blogs'))
            .then((result) => {
                setBanner(result?.banner)
                // dispatch(setSettingLogo(result))
            })
            .catch((err) => {
                console.error({ err });
            })
            // }).finally(() => {
            //     setLoader(false)
            // })
        
        fetchBlogLength()
    }, [])

    const [order, setOrder] = useState("desc")
    const uniqueCategories = [...new Set(blogs.map(blog => blog.category.name))];

    const items = uniqueCategories.map((category, index) => ({
        key: (index + 2).toString(),
        label: category,
        children: <SpecificBlogs order={order} blogs={blogs} category={category} />,
    }));

    items.unshift({
        key: "1",
        label: "All Blogs",
        children: <AllBlogs order={order} />,
    });



    const dropDownMenu = [
        {
            value: "desc",
            label: "latest",
        },
        {
            value: "asc",
            label: "Older",
        },
       
    ];

    const onChange = (key) => {
        // console.log(key);
    };

    const handleChange = (value) => {
        setOrder(value)
    };

   

    return (
        <>
            <div className="blog-container">
             
                {
                    banner ? (
                        <div className="blog-banner-container" style={{ backgroundImage: `url(${DEMO_IMAGE_URL}${banner?.image})` }} key={banner?.id}>
                            <Button className="back-to-button-course-listing back-to-button" onClick={handleBack}><IoIosArrowRoundBack />Back to home</Button>
                            <div className="blog-cover-text">
                                <p className="blog-cover-text-subhead">
                                    {banner?.title}
                                </p>
                                <h2 className="blog-cover-text-heading">{banner?.sub_title}</h2>
                            </div>
                        </div>
                    )
                        : (
                            <div className="blog-banner-container">
                                <Button className="back-to-button-course-listing back-to-button" onClick={handleBack}><IoIosArrowRoundBack />Back to home</Button>
                                <div className="blog-cover-text">
                                    <h2 className="blog-cover-text-heading">Blogs</h2>
                                    <p className="blog-cover-text-subhead">
                                        Explore Our Comprehensive Blogs
                                    </p>
                                </div>
                            </div>
                        )
                }

                <Offer/>
                
                <div className="blogs-tab-container">
                    <div className="tabsRow">
                        <Tabs
                            defaultActiveKey="1"
                            className="blogs-tab"
                            items={items}
                            onChange={onChange}
                        />
                        <div className="dropdownSection">
                            <Select
                                defaultValue="Sort by"
                                style={{
                                    width: 120,
                                }}
                                onChange={handleChange}
                                options={dropDownMenu}
                            />
                        </div>
                    </div>
                </div>
                {/* <div className="blog-news-letter-container">
                    <Image
                        preview={false}
                        className="blog-news-letter-image"
                        src={BlogNewsLetterCover}
                    />
                    <div className="news-letter-text-input">
                        <h2 className="blog-news-join">Join Our Newsletter</h2>
                        <p className="blog-news-sing-up">Sign up for latest lessions!</p>
                        <div className="blogs-input-news-letter-container">
                            <Space.Compact className="news-letter-space">
                                <Input
                                    className="news-letter-input"
                                    placeholder="Email address"
                                    prefix={<CiMail className="blog-news-letter-mail-icon" />}
                                />
                                <Button
                                    className="blogs-news-letter-sign-up-button"
                                    type="primary"
                                >
                                    Sign Up
                                </Button>
                            </Space.Compact>
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    );
}
