import React, { useState, useEffect } from "react";
import { Row, Col, Button, Table, Image, Spin,Pagination } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import Sidebar from "../../components/Sidebar/sidebar";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { Avatar } from "../../constant/images"
import { DEMO_IMAGE_URL } from "../../Helper";
import * as getServices from "../../services/getServices"
import { useDispatch } from "react-redux";

export default function MyStudents() {
	const dispatch = useDispatch();
	const [currentPage, setCurrentPage] = useState(1);
	const [clients, setClients] = useState([]);
	const [loader, setLoader] = useState(false);
	const [totalClients, setTotalClients] = useState(0);
	const [pageSize, setPageSize] = useState(5)

	const fetchClients = (page) => {
		setLoader(true);
		dispatch(getServices.getClients(pageSize, page))
			.then((response) => {
				setLoader(false);
				setClients(response?.data || []);
				setTotalClients(response?.total || 0);
				setCurrentPage(page);
			})
			.catch((error) => {
				setLoader(false);
				console.error({ error: error });
			});
	};

	useEffect(() => {
		fetchClients(currentPage);
	}, [currentPage]);

	const handleChangePage = (page) => {
        setCurrentPage(page);
    };

	const onPageSizeChange = (current, size) => {
        setPageSize(size);
        setCurrentPage(1);
    }

	const generatePageSizeOptions = (total) => {
        const options = [];
        const maxPageSize = Math.min(total, 100);

        for (let i = 5; i <= maxPageSize; i += 5) {
            options.push(i);
        }
        if (total > 5 && !options.includes(10)) {
            options.push(10);
        }

        if (total > 10 && !options.includes(15)) {
            options.push(15);
        }
        return options.sort((a, b) => a - b);
    };

    const pageSizeOptions = generatePageSizeOptions(totalClients);

	const DateFormatter = (date) => {
		const inputDate = date;
		const dateObj = new Date(inputDate);
		const monthNames = [
			"January", "February", "March", "April", "May", "June",
			"July", "August", "September", "October", "November", "December"
		];
		const day = dateObj.getDate();
		const month = monthNames[dateObj.getMonth()];
		const year = dateObj.getFullYear();
		return `${day} ${month} ${year}`;
	};

	const columns = [
		{
			title: "S.No.",
			dataIndex: "sno",
			key: "sno",
			render: (text, record, index) => (
				<p className="review-sno">
					{String(index + 1 + (currentPage - 1) * pageSize).padStart(2, "0")}
				</p>
			),
		},
		{
			title: "Client Name",
			dataIndex: "name",
			key: "name",
			render: (text, record) => (
				<div className="customer-name-container">
					<div className="customer-image-container">
						<Image
							width={50}
							height={50}
							src={record?.images ? `${DEMO_IMAGE_URL}${record?.images}` : Avatar}
							alt="Not Found"
							className="img-fluid customer-image"
							preview={false}
						/>
					</div>
					<div className="customer-name-id">
						<p className="customer-name">{record?.name}</p>
					</div>
				</div>
			),
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
			render: (text, record) => (
				<p className="enrolled-date">{record?.email}</p>
			),
		},
		{
			title: "Enrolled Date",
			dataIndex: "enrolled_date",
			key: "enrolled_date",
			render: (text, record) => (
				<p className="enrolled-date">{DateFormatter(record?.first_purchase_date)}</p>
			),
		},
	];

	const exportToExcel = (data, fileName) => {
		const worksheet = XLSX.utils.json_to_sheet(data);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
		const excelBuffer = XLSX.write(workbook, {
			bookType: "xlsx",
			type: "array",
		});
		const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
		saveAs(blob, `${fileName}.xlsx`);
	};

	const transformData = (data) => {
		return data.map((item, index) => ({
			"S.NO": index + 1,
			Name: item.user_name,
			Email: item.user_email,
			"Enrolled Date":DateFormatter(item?.first_purchase_date)
		}));
	};

	return (
		<>
			<Row>
				<Col xl={4} className="d-xl-block d-md-none">
					<Sidebar />
				</Col>
				<Col xl={20} md={24}>
					<div className="mainContent mystudents">
						<div className="my-customers-container">
							<div className="download-students-container">
								<p className="my-students">My Clients</p>
								<Button
									className="download-students-button"
									onClick={() => exportToExcel(transformData(clients), "studentsData")}
								>
									{loader ? <Spin indicator={<LoadingOutlined style={{ color: "#fff" }} spin />} size="small" /> : "Download client in xlsx/csv"}
								</Button>
							</div>
							<Row className="my-customer-head-row">
								<Col className="my-customer-head-col-three" md={24}>
									<p className="total-customer-text">
										Total Clients
										<span className="total-customer-number">
											({totalClients})
										</span>
									</p>
								</Col>
							</Row>
							<div className="table-container">
								<Table
									className="my-client-table table-responsive"
									columns={columns}
									dataSource={clients}
									pagination={false}
									loading={{
										spinning: loader,
										indicator: <Spin indicator={<LoadingOutlined spin />} />,
									}}
									rowKey="id"
								/>
								<Pagination
                            className="mt-4"
                            current={currentPage}
                            pageSize={pageSize}
                            total={totalClients}
                            onChange={handleChangePage}
                            onShowSizeChange={onPageSizeChange}
                            showSizeChanger
                            pageSizeOptions={pageSizeOptions}
                        />
							</div>
						</div>
					</div>
				</Col>
			</Row>
		</>
	);
}
