import React, { useEffect, useState, useMemo } from "react";
import {
    Row,
    Col,
    Table,
    Rate,
    Select,
    Image,
    Carousel,
    Pagination,
    Tooltip,
    Modal,
    Button,
    Spin,
} from "antd";
import Sidebar from "../../components/Sidebar/sidebar";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { SalesSummaryChart } from "../../Helpers/salesSummaryChart";
import { LoadingOutlined } from "@ant-design/icons";
import { Avatar } from "../../constant/images";
import { useDispatch, useSelector } from "react-redux";
import * as getServices from "../../services/getServices";
import { Circle } from "rc-progress";
import { LuEye } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { StudentEnrollmentChart } from "../../Helpers/studentEnrollmentChart";
import { DEMO_IMAGE_URL } from "../../Helper"
import { MyCOurseImg } from "../../constant/images"

const VendorDashboard = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const pageSize = 5;
    const vendorData = useSelector((state) => state.user.userData);
    const categories = useSelector((state) => state.user.categoriesData);
    const [courseDetail, setCourseDetail] = useState();
    const [reviewDetail, setReviewDetail] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [loader, setLoader] = useState(true);
    const [recentBookings, setRecentBookings] = useState([]);
    const [years, setYears] = useState([]);
    const [categoryId, setCategoryId] = useState(categories[0]?.id)
    const [period, setPeriod] = useState(0)
    const [studentSalesSummary, setStudentSalesSummary] = useState([])
    const [pendingCourses, setPendingCourses] = useState([])


    const categoryList = useMemo(() => categories?.map((item) => ({
        label: item.name,
        value: item.id,
    })), []);

    useEffect(() => {
        dispatch(getServices?.getReviews(vendorData?.vendor?.id))
            .then((response) => {
                setReviewDetail(response?.reviews?.data);
            })
            .catch((err) => {
                // console.log({ err });
            });
    }, [vendorData?.vendor?.id]);

    useEffect(() => {
        setLoader(true)
        dispatch(getServices?.getStudentsAndSalesSummary(period, categoryId))
            .then((response) => {
                setStudentSalesSummary(response.data);
                setLoader(false);
            })
            .catch((err) => {
                setLoader(false);
                console.log({ err });
            });
    }, [categoryId, period])

    useEffect(() => {
        dispatch(getServices.getRecentBookings()).then((response) => {
            setRecentBookings(response?.recent_booking?.data);
        }).catch((err) => {
            console.log({ err });
        })

        dispatch(getServices.getPendingCoursesVendor()).then((response) => {
            setPendingCourses(response.pending_courses);
        }).catch((err) => {
            console.log({ err });
        })

    }, [])

    const handleChangePage = (page) => {
        setCurrentPage(page);
    };
    const DateFormatter = (date) => {
        const inputDate = date;

        const dateObj = new Date(inputDate);

        const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];

        const day = dateObj.getDate();
        const month = monthNames[dateObj.getMonth()];
        const year = dateObj.getFullYear();

        const formatDate = `${day} ${month} ${year}`;
        return formatDate;
    };

    const handleViewClick = (record) => {
        setModalContent(record);
        setIsModalVisible(true);
    };

    const handleModalClose = () => {
        setIsModalVisible(false);
        setModalContent(null);
    };
    const handleCourseNameClick = (courseId) => {
        navigate(`/vendor/view-course-detail`, { state: { data: courseId } });
    };

    const columns = [
        {
            title: "S.No",
            dataIndex: "S.No",
            key: "S.No",
            render: (_, record, index) => (currentPage - 1) * pageSize + index + 1,
        },
        {
            title: "Item Name",
            key: "Course Name",
            sorter: (a, b) => a.course_name.localeCompare(b.course_name),
            render: (_, record, index) => (
                <span
                    style={{ cursor: "pointer" }}
                    onClick={() => handleCourseNameClick(record?.course?.id)}
                >
                    {record?.course_name}
                </span>
            ),
        },
        {
            title: "Booking No.",
            dataIndex: "Booking No.",
            key: "Booking No.",
            sorter: (a, b) => a.id - b.id,
            render: (text, record, index) =>
                `${record?.transaction_id}`,
        },
        {
            title: "Amount",
            key: "Amount",
            dataIndex: "amount",
            render: (text, record, index) =>
                `$${record?.amount}`,
        },
        {
            title: "Start Date",
            key: "Start Date",
            render: (_, record, index) => {
                return DateFormatter(record?.created_at);
            },
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (_, record) => (
                <div className="action-container">
                    <Tooltip key={record.id} title="View and Manage">
                        <button
                            className="btn btnView me-2"
                            onClick={() => handleViewClick(record)}
                        >
                            <LuEye />
                        </button>
                    </Tooltip>
                </div>
            ),
        },
    ];

    const enrolledSelect = [
        { value: 1, label: <p className="weekly-dropdown">Weekly</p> },
        { value: 0, label: <p className="monthly-dropdown">Monthly</p> },
        { value: 2, label: <p className="yearly-dropdown">Yearly</p> },
    ];
    const exportToExcel = (data, fileName) => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        const excelBuffer = XLSX.write(workbook, {
            bookType: "xlsx",
            type: "array",
        });
        const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
        saveAs(blob, `${fileName}.xlsx`);
    };
    const transformData = (data) => {
        return data?.map((item, index) => ({
            "S.NO": index + 1,
            "Course Name": item.course_name,
            "Course Fee": item.amount,
            "User name": item.user_name,
            "User email": item.user_email,
        }));
    };

    return (
        <>
            <Row>
                <Col xl={4} className="d-xl-block d-md-none">
                    <Sidebar />
                </Col>
                <Col xl={20} md={24}>
                    <div className="mainContent mainvendor-dshboard">
                        <div className="mb-4 d-flex justify-content-end align-items-center select-container">
                            <Select
                                className="total-students-enrolled-inner-select"
                                defaultValue={"Monthly"}
                                options={enrolledSelect}
                                placement="bottomLeft"
                                onChange={(value) => {
                                    setPeriod(value)
                                }}
                            />
                            <Select
                                className="total-students-enrolled-inner-select"
                                placeholder="Select"
                                defaultValue={categories[0]?.id}
                                options={categoryList}
                                placement="bottomLeft"
                                onChange={(value) => {
                                    setCategoryId(value)
                                }}
                            />
                        </div>
                        <Row className="vendor-dashboard">
                            <Col className="vendor-dashboard-child-one" md={12}>
                                <div className="vendor-dashboard-child-one-container">
                                    <div className="post-booking-container">
                                        <div className="download-courses-container">
                                            <p className="post-booking-head">Recent Bookings</p>
                                            <Button
                                                className="download-courses-button"
                                                onClick={() =>
                                                    exportToExcel(transformData(recentBookings), "Booking data")
                                                }
                                            >
                                                {loader ? (
                                                    <Spin
                                                        indicator={
                                                            <LoadingOutlined style={{ color: "#fff" }} spin />
                                                        }
                                                        size="small"
                                                    />
                                                ) : (
                                                    "Download Items in xlsx/csv"
                                                )}
                                            </Button>
                                        </div>
                                        <div className="post-booking-table-container table-responsive">
                                            <Table
                                                className="post-booking-table"
                                                columns={columns}
                                                dataSource={recentBookings.slice((currentPage - 1) * pageSize, currentPage * pageSize)}
                                                pagination={false}
                                            />
                                            <Pagination
                                                className="mt-4"
                                                current={currentPage}
                                                pageSize={5}
                                                total={recentBookings.length}
                                                onChange={handleChangePage}
                                            />
                                        </div>
                                    </div>
                                    <div className="sales-summary-container">
                                        <Row className="sales-summary-row">
                                            <Col className="sales-summary-col-1" md={12}>
                                                <p className="sales-summary-head">Sales Summary</p>
                                            </Col>
                                            {/* <Col className="sales-summary-col-2 d-flex justify-content-around ">
                                                <Select
                                                    className="course-enrolled-inner-select"
                                                    placeholder="Select the items"
                                                    options={courseList}
                                                    onChange={(value) => {
                                                        handleChange(value, "courseId");
                                                    }}
                                                />
                                                <Select
                                                    className="total-students-enrolled-inner-select"
                                                    defaultValue={'Monthly'}
                                                    options={enrolledSelect}
                                                    onChange={(value) => {
                                                        setFilterType(value);
                                                        handleChange(value, "filterType");
                                                    }}
                                                />
                                            </Col> */}
                                        </Row>
                                        {loader ? (
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                                <Spin indicator={<LoadingOutlined style={{ fontSize: 38 }} spin />} />
                                            </div>
                                        ) :
                                            (<HighchartsReact
                                                highcharts={Highcharts}
                                                options={SalesSummaryChart(studentSalesSummary?.sales)}
                                            />)
                                        }
                                    </div>
                                    <Carousel>
                                        {reviewDetail?.length ? reviewDetail?.map((item) => {
                                            return (
                                                <div className="vendor-reviews-ratings-container" key={item?.id}>
                                                    <Row className="vendor-reviews-row">
                                                        <Col className="vendor-reviews-col" md={12}>
                                                            <p className="vendor-reviews-ratings-head">
                                                                Ratings & Reviews
                                                            </p>
                                                            <p className="vendor-reviews-ratings-date">
                                                                {DateFormatter(item?.created_at)}
                                                            </p>
                                                            <p className="vendor-reviews-ratings-p-rate">
                                                                <Rate
                                                                    allowHalf={false}
                                                                    defaultValue={parseInt(item?.rating)}
                                                                />
                                                            </p>
                                                        </Col>
                                                        <Col md={12}>
                                                            <Row className="vendor-reviews-pie-row">
                                                                {((parseFloat(item?.rating) / 5) * 100) >= 75 ? (
                                                                    <Col md={8}>
                                                                        <Circle
                                                                            percent={((parseFloat(item?.rating) / 5) * 100)}
                                                                            strokeWidth={10}
                                                                            strokeColor="#1EAA39"
                                                                            trailWidth={10}
                                                                            style={{ width: 80, height: 80 }}
                                                                        />
                                                                        <div className="exclent"
                                                                            style={{
                                                                                position: "absolute",
                                                                                top: "36%",
                                                                                left: "36%",
                                                                                transform: "translate(-50%, -50%)",
                                                                                fontSize: "11px",
                                                                                fontWeight: "bold",
                                                                            }}
                                                                        >
                                                                            {`${item?.rating != null ? ((parseFloat(item.rating) / 5) * 100).toFixed(2) : '0'}%`}
                                                                        </div>
                                                                        <p className="pie-excelent">Excellent</p>
                                                                    </Col>
                                                                ) : ((parseFloat(item?.rating) / 5) * 100) >= 36 && ((parseFloat(item?.rating) / 5) * 100) <= 74 ? (
                                                                    <Col md={8}>
                                                                        <Circle
                                                                            percent={((parseFloat(item?.rating) / 5) * 100)}
                                                                            strokeWidth={10}
                                                                            strokeColor="#F4BF08"
                                                                            trailWidth={10}
                                                                            style={{ width: 80, height: 80 }}
                                                                        />
                                                                        <div
                                                                            className="positive"
                                                                            style={{
                                                                                position: "absolute",
                                                                                top: "36%",
                                                                                left: "36%",
                                                                                transform: "translate(-50%, -50%)",
                                                                                fontSize: "11px",
                                                                                fontWeight: "bold",
                                                                            }}
                                                                        >
                                                                            {`${item?.rating != null ? ((parseFloat(item.rating) / 5) * 100).toFixed(2) : '0'}%`}
                                                                        </div>
                                                                        <p className="pie-positive">Positive</p>
                                                                    </Col>
                                                                ) : (
                                                                    <Col md={8}>
                                                                        <Circle
                                                                            percent={((parseFloat(item?.rating) / 5) * 100)}
                                                                            strokeWidth={10}
                                                                            strokeColor="#DA0734"
                                                                            trailWidth={10}
                                                                            style={{ width: 80, height: 80 }}
                                                                        />
                                                                        <div
                                                                            className="negative"
                                                                            style={{
                                                                                position: "absolute",
                                                                                top: "36%",
                                                                                left: "36%",
                                                                                transform: "translate(-50%, -50%)",
                                                                                fontSize: "11px",
                                                                                fontWeight: "bold",
                                                                            }}
                                                                        >
                                                                            {`${item?.rating != null ? ((parseFloat(item.rating) / 5) * 100).toFixed(2) : '0'}%`}
                                                                        </div>
                                                                        <p className="pie-negative">Negative</p>
                                                                    </Col>
                                                                )}
                                                            </Row>
                                                        </Col>
                                                    </Row>

                                                    <p className="vendor-reviews-ratings-para">
                                                        {item?.comment}
                                                    </p>

                                                    <div className="vendor-rating-profile-div">
                                                        <div className="image-conatiner">
                                                            <Image
                                                                className="reviewer-image"
                                                                width={37}
                                                                height={37}
                                                                src={item?.user?.images ? `${DEMO_IMAGE_URL}${item?.user?.images}` : Avatar}
                                                            />
                                                        </div>
                                                        <div className="name-designation-container">
                                                            <p className="reviewer-image">{item?.user?.name}</p>
                                                            <p className="reviewer-designation">
                                                                {item?.course?.category?.name}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }) : ""}

                                    </Carousel>
                                </div>
                            </Col>
                            <Col className="vendor-dashboard-child-two" md={12}>
                                <Row className="total-students-enrolled-row">
                                    <Col className="total-students-enrolled-container" md={24}>
                                        <Row className="total-students-enrolled-inner-container">
                                            <Col md={12} className="total-students-container">
                                                <p className="total-students-enrolled-head mb-0">
                                                    Total Student Enrolled
                                                </p>
                                            </Col>
                                            <Col md={12} className="select-options-container">
                                                {/* <Select
                                                    className="total-students-enrolled-inner-select"
                                                    defaultValue={"Monthly"}
                                                    options={enrolledSelect}
                                                    placement="bottomLeft"
                                                    onChange={(value) => {
                                                        setInterval(value)
                                                        totalEnrolledStudent(value, "interval");
                                                    }}
                                                />
                                                <Select
                                                    className="total-students-enrolled-inner-select"
                                                    placeholder="Select"
                                                    // defaultValue={`${categoryList?categoryList[0]?.label:''}`}
                                                    options={categoryList}
                                                    placement="bottomLeft"
                                                    onChange={(value) => {
                                                        setCategoryId(value)
                                                        totalEnrolledStudent(value, "category");
                                                    }}
                                                /> */}
                                            </Col>
                                            <Col md={24} className="highchart-container">
                                                {loader ? (
                                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                                        <Spin indicator={<LoadingOutlined style={{ fontSize: 38 }} spin />} />
                                                    </div>
                                                ) : (
                                                    <HighchartsReact
                                                        highcharts={Highcharts}
                                                        options={StudentEnrollmentChart(studentSalesSummary?.users_enrolled)}
                                                    />
                                                )}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="pending-for-approval-outer-container">
                                    <Col className="pending-for-approval-box" md={24}>
                                        <p className="approval-heading">Pending For Approval</p>
                                        <div className="approval-row-outer" id="style-1">
                                            {pendingCourses?.length > 0 ? (
                                                pendingCourses?.map((content) => {
                                                    return (
                                                        <Row className="approval-row" key={content.id}>
                                                            <Col className="approval-image-col" md={5}>
                                                                <Image
                                                                    preview={false}
                                                                    className="approval-image"
                                                                    src={content?.image ? `${DEMO_IMAGE_URL}${content?.image}` : MyCOurseImg}
                                                                />
                                                            </Col>
                                                            <Col className="approval-content" md={19}>
                                                                <p className="approval-course">
                                                                    {content?.category?.name}
                                                                </p>
                                                                <p className="approval-description">
                                                                    {content?.title}
                                                                </p>
                                                            </Col>
                                                        </Row>
                                                    );

                                                    return null;
                                                })
                                            ) : (
                                                <p>No data found</p>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>

            <Modal
                title="View Booking Detail"
                open={isModalVisible}
                onOk={handleModalClose}
                onCancel={handleModalClose}
                className="booking-detail-model"
                footer={null}
            >
                <p>
                    <span>Name:</span> {modalContent?.user_name}
                </p>
                <p>
                    <span>Email:</span> {modalContent?.user_email}
                </p>
                <p>
                    <span>Items Name:</span> {modalContent?.course_name}
                </p>
                <p>
                    <span>Items Fee:</span> ${modalContent?.amount}
                </p>
                <p>
                    <span>Purchase Date:</span>{" "}
                    {DateFormatter(modalContent?.created_at)}
                </p>
                <p>
                    <span>Rewards Points</span> {modalContent?.rewards?.points}
                </p>
            </Modal>
        </>
    );
};

export default VendorDashboard;
