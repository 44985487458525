function StudentEnrollmentChart(data) {

  if ( data.length === 0) {
    return {
      title: {
        text: "No Data Found",
        align: "center",
        verticalAlign: "middle",
        style: {
          fontSize: "16px",
          color: "#555",
        },
      },
      series: [],
    };
  }

  const xAxisCategories = data?.map(item => item?.key);
  const seriesData = data?.map(item => item?.value);

  const options = {
    chart: {
      type: 'column',
    },
    title: {
      text: '',
    },
    exporting: {
      enabled: true,
    },
    plotOptions: {
      series: {
        pointWidth: 50,
      },
      column: {
        colorByPoint: true,
      },
    },
    accessibility: {
      announceNewData: {
        enabled: true,
      },
    },
    xAxis: {
      categories: xAxisCategories,
    },
    yAxis: {
      title: {
        text: 'Total Enrollments',
      },
      labels: {
        formatter: function () {
          return this?.value;
        },
      },
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        name: 'Total Enrollments',
        data: seriesData,
      },
    ],
  };

  return options;
}

export { StudentEnrollmentChart };
