import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Button, Spin } from "antd";
import RecentCourse from "./RecentCourse";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import * as getServices from "../../services/getServices";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BannerSection from "./banner";
import CategorySlider from "./categorySlider";
import SponsoredCourses from "./sponsoredCourese";
import Offer from "../../pages/Offer/offer";
import { LoadingOutlined } from '@ant-design/icons';


export default function Home() {
    const userData = useSelector((state) => state.user.userData);
    const isUserLoggedIn = useSelector((state) => state.user.isUserLoggedIn);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loader, setLoader] = useState(true);
    const [wishlistData, setWishlistData] = useState([])
    // const [paymentCourseData, setPaymentCourseData] = useState();

    useEffect(() => {
        setTimeout(() => {
            setLoader(false);
        }, 3000);
    }, []);

    const getWishlistData = () => {

        dispatch(getServices.GetWistlistDataUser(userData?.user?.id)).then((response) => {
            setWishlistData(response?.course_details)
        }).catch((error) => {
            console.error({ error });
        })
    }

    useEffect(()=>{
        if(isUserLoggedIn){
            getWishlistData()
        }
    },[isUserLoggedIn])
    

    const handleLoadMoreButton = () => {
        navigate(`/course-listing`, { state: { querystring: null } });
    };

    return (
        <>
            {!loader ? (
                <div>
                <BannerSection />
            <div className="homePageCarousal">
                <CategorySlider />
        
                {/* <SponsoredCourses isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen} setPaymentCourseData={setPaymentCourseData} /> */}
                <Offer />    
                <SponsoredCourses isModalOpen={isModalOpen} wishlistData={wishlistData} getWishlistData={getWishlistData}/>
                <div className="load-more-container">
                    <Button onClick={handleLoadMoreButton} className="load-more-button">View more...</Button>
                </div>
            </div>
            <RecentCourse wishlistData={wishlistData} getWishlistData={getWishlistData} />
           
                </div>
            ) : (
                <>
                <div className="d-flex justify-content-center align-items-center mt-4 mb-4">
                        <Spin
                            indicator={
                                <LoadingOutlined
                                    style={{
                                        fontSize: 48,
                                    }}
                                    spin
                                />

                            }
                        />
                    </div>
                </>
            )}
             {/* <Modal
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                className="paymentModal"
                centered
                width={1140}
                footer={false}
            >
                <Payment paymentCourseData={paymentCourseData} />
            </Modal> */}
        </>
    );
}
