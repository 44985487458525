import axios from 'axios';
import { DEMO_URL } from "./Helper";
import {Logout} from "./Helpers/logoutHelper"

// axios.defaults.withCredentials = true;
axios.defaults.baseURL = DEMO_URL;

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axios.interceptors.request.use(function (config) {
    const token = `Bearer ${localStorage.getItem('token')}`;
    config.headers.Authorization = token;
    return config;
});

axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        Logout();
      }
      return Promise.reject(error);
    }
  );

export default axios;