import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Input, Select, Upload, Button, Checkbox, TimePicker, Image, Space, Modal, DatePicker, Spin } from "antd";
import { MdOutlineCategory } from "react-icons/md";
import CoursePolicy from "./coursePolicy";
import { CiDollar } from "react-icons/ci";
import { BsFileCode } from "react-icons/bs";
import { CiUser } from "react-icons/ci";
import { LiaCalendarAltSolid } from "react-icons/lia";
import { BsInfoCircle } from "react-icons/bs";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { FaArrowRightLong } from "react-icons/fa6";
import { PlusOutlined } from '@ant-design/icons';
import dayjs from "dayjs";
import { FaMinusCircle } from "react-icons/fa";
import { IoMdAddCircle } from "react-icons/io";
import { DEMO_IMAGE_URL } from "../../Helper"
import { useDispatch } from 'react-redux';
import * as updateServices from "../../services/updateServices";
import Swal from 'sweetalert2';
import { LuUpload } from "react-icons/lu";
import { LoadingOutlined } from '@ant-design/icons';
import * as getServices from "../../services/getServices"
import { useNavigate } from "react-router-dom";
import * as uploadServices from "../../services/uploadServices";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripePaymentForm from "../paymentPrcess/paymentForm";
import { HiMiniCheckBadge } from "react-icons/hi2";

export default function EditCourse({ courseDetails, categories, classes, setIsUpdateModalOpen, getCourse, pricingCategory }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [editForm] = Form.useForm();
    const { TextArea } = Input;
    const { RangePicker } = TimePicker;
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [fileList, setFileList] = useState([]);
    const [showFAQinput, setShowFAQinput] = useState(false)
    const [showDocumentInput, setShowDocumentInput] = useState(false)
    const [helpModel, setHelpModel] = useState(false)
    const [selectedCategory, setSelectedCategory] = useState(courseDetails?.category_id);
    const [selectedSubCategory, setSelectedSubCategory] = useState(courseDetails?.child_category_id);
    const [selectedChildCategory, setSelectedChildCategory] = useState(courseDetails?.sub_category_id);
    const [filteredSubCategories, setFilteredSubCategories] = useState([]);
    const [childCategories, setChildCategories] = useState('');
    const [classLevel, setClassLevel] = useState(courseDetails?.class_level ? courseDetails?.class_level : null)
    const allDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const [loader, setLoader] = useState(false)
    const [fundingPrices, setFundingPrices] = useState([]);
    // const [isWSQChecked, setIsWSQChecked] = useState(courseDetails?.course_status === 1 ? true : false);
    const [deletedFundingId, setDeletedFundingId] = useState([])
    const [deletedTimeSlotsId, setDeletedTimeSlotsId] = useState([])
    const [sponsoredCourse, setSponsoredCourse] = useState(courseDetails?.sponsored === 1 ? true : false);
    const [sponsorshipData, setSponsorshipData] = useState([]);
    const [sponsorPrice, setSponsorPrice] = useState(0);
    const [isPlanModalVisible, setIsPlanModalVisible] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [paymentForm, setPaymentForm] = useState(false);
    const [isDurationModal, setIsDurationModal] = useState(false);
    const [sponrshipPlan, setSponsorshipPlan] = useState();
    const [clientSecret, setClientSecret] = useState();
    const [days, setDays] = useState("");
    const [courseAmount, setCourseAmount] = useState("");
    const [stripePromise, setStripePromise] = useState(
        loadStripe(
            "pk_test_51NuUdDDhhEVxDG993lu35F4QwDa8YaZcktRtzp4d5f0Mtplas4c5Hc01lJFPH0R9XgshjLP7BWpoE6IYCanmu8JT00Il8HHzf6"
        )
    );
    const [editCourseDetails, setEditCourseDetails] = useState({
        course_faq: [],
        course_document: null,
        course_slots: courseDetails?.time_slots?.length > 0 ? courseDetails?.time_slots?.map(({ week_day, start_time, end_time, id }) => ({
            id: id,
            week_day,
            start_time: start_time,
            end_time: end_time,
        })) : [],
    });
    const [showVideoThumbnailInput, setVideoThumbnailInput] = useState(false)
    const [videoAndThumbnail, setVideoAndThumbnail] = useState({
        video: null,
        thumbnail: null
    })

    const [videolUrl, setVideoUrl] = useState(null);
    const [coursePolicy, setCoursePolicy] = useState({
        refund_policy: courseDetails?.refund_policy ? courseDetails?.refund_policy : "",
        is_premium: courseDetails?.is_premium === "paid" ? 0 : 1,
        trial: courseDetails?.trial === 1 ? 1 : 0,
        trial_days: courseDetails?.trial_days ? parseInt(courseDetails?.trial_days) : 0,
        refundable: courseDetails?.refundable

    });

    const [inputValue, setInputValue] = useState(coursePolicy.is_premium !== 0 ? 0 : courseDetails?.fee);

    const fetchData = async () => {
        if (days !== "") {
            try {
                dispatch(uploadServices.getCalculatedCustom({ days })).then(
                    (response) => {
                        setCourseAmount(response.totalAmount);
                    }
                );
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
    };

    fetchData();

    useEffect(() => {
        if (coursePolicy.is_premium !== 0) {
            editForm.resetFields(['fee']);
            setFundingPrices([]);
            setInputValue('0')
            setDeletedFundingId(courseDetails?.skill_funding?.map((item) => (
                item?.id
            )))
        }
    }, [coursePolicy]);


    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const generateFileList = (imageData) => {
        if (!imageData) return [];

        return imageData.map((image, index) => ({
            uid: index,
            name: `Image ${index + 1}`,
            status: 'done',
            url: `${DEMO_IMAGE_URL}${image}`,
        }));
    };

    const [courseImageCover, setCourseImageCover] = useState(
        courseDetails?.image
            ? [
                {
                    uid: '-1',
                    name: 'courseCoverImage',
                    status: 'done',
                    url: `${DEMO_IMAGE_URL}${courseDetails?.image}`,
                },
            ]
            : []
    );

    const [instructorProfileImage, setInstructorProfileImage] = useState(
        courseDetails?.instructor[0]?.profile_photo
            ? [
                {
                    uid: '-1',
                    name: 'Instructor Profile Image',
                    status: 'done',
                    url: `${DEMO_IMAGE_URL}${courseDetails?.instructor[0]?.profile_photo}`,
                },
            ]
            : []
    );

    const handleDaysChange = (e) => {
        setDays(e.target.value);
    };

    useEffect(() => {
        if (courseDetails?.video === null && courseDetails?.thumbnail === null) {
            setVideoThumbnailInput(true)
        }
    }, [courseDetails])

    useEffect(() => {
        const parsedImageData = courseDetails?.school_images;
        setFileList(generateFileList(parsedImageData));

    }, [courseDetails?.school_images]);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };

    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
    const handleCourseCoverChange = ({ fileList }) => {
        setCourseImageCover(fileList.slice(-1));
    };
    const handleInstructorProfileImageChange = ({ fileList }) => {
        setInstructorProfileImage(fileList.slice(-1));
    };

    const handleModalOk = () => {
        setIsModalVisible(false);
        setIsPlanModalVisible(true);
    };

    const handleModalCancel = () => {
        setIsModalVisible(false);
        navigate("/vendor/my-courses");
    };

    const handlePlanModalOk = () => {
        setIsPlanModalVisible(false);
    };

    const handlePlanModalCancel = () => {
        setIsPlanModalVisible(false);
        navigate("/vendor/my-courses");
    };

    const handleDurationModalOk = () => {
        setPaymentForm(true);
        setIsDurationModal(false);
    };

    const handleDurationModalCancel = () => {
        setIsDurationModal(false);
        setIsPlanModalVisible(true);
    };

    const openDurationModal = () => {
        setIsDurationModal(true);
        setIsPlanModalVisible(false);
    };

    const handlePayment = (value) => {
        setSponsorPrice(
            value?.discounted_price ? value?.discounted_price : value?.price
        );
        setSponsorshipPlan(value);
    };

    const handlePaymentFormCancle = () => {
        setPaymentForm(false);
        navigate("/vendor/my-courses");
    };

    const handlePaymentForm = () => {
        setPaymentForm(false);
    };


    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );


    useEffect(() => {
        if (courseDetails?.vendor_faq?.length > 0) {
            setEditCourseDetails(prevState => ({
                ...prevState,
                course_faq: courseDetails.vendor_faq.map(item => ({
                    id: item.id,
                    question: item.question,
                    answer: item.answer,
                })),
            }));
        } else {
            setEditCourseDetails(prevState => ({
                ...prevState,
                course_faq: [],
            }));
        }
    }, [courseDetails?.vendor_faq]);

    const handleAddInput = () => {
        setEditCourseDetails(prevState => ({
            ...prevState,
            course_faq: [...prevState.course_faq, { id: Date.now(), question: '', answer: '' }],
        }));
    };

    const handleRemoveInput = (id) => {
        setEditCourseDetails(prevState => ({
            ...prevState,
            course_faq: prevState.course_faq.filter(input => input.id !== id),
        }));
    };

    const handleInputChange = (id, field, value) => {
        setEditCourseDetails(prevState => ({
            ...prevState,
            course_faq: prevState.course_faq.map(input =>
                input.id === id ? { ...input, [field]: value } : input
            ),
        }));
    };

    const DatePickerFormatter = (data) => {
        const dateStr = "15 Nov 2024 18:30:00 GMT";
        const dateObj = new Date(dateStr);
        const formattedDate = dateObj.toISOString().split('T')[0];
        return formattedDate
    }

    const getPaymentIntent = (fee) => {
        setIsPlanModalVisible(false);
        setPaymentForm(true);
        setLoader(true);
        dispatch(
            uploadServices.PaymentIntent({
                amount: parseFloat(fee),
                currency: "SGD",
            })
        )
            .then((response) => {
                setLoader(false);
                setClientSecret(response?.clientSecret);
            })
            .catch((err) => {
                setLoader(false);
                console.error(err);
            });
    };

    useEffect(() => {
        dispatch(getServices.getSponsorshipData())
            .then((response) => {
                setSponsorshipData(response?.data);
            })
            .catch((err) => {
                console.error(err);
            });
    }, [sponsoredCourse]);

    useEffect(() => {
        if (sponsorPrice > 0) {
            getPaymentIntent(sponsorPrice);
        }
    }, [sponsorPrice]);


    const onFinish = (values) => {

        setLoader(true)
        try {
            const formData = new FormData();
            formData.append("title", values?.title)
            formData.append("category_id", selectedCategory !== null && selectedCategory !== undefined ? selectedCategory : "");
            formData.append("sub_category_id", selectedChildCategory !== null && selectedChildCategory !== undefined ? selectedChildCategory : "");
            formData.append("child_category_id", selectedSubCategory !== null && selectedSubCategory !== undefined ? selectedSubCategory : "");
            formData.append("visibility", values?.visibility ? values?.visibility : "");
            formData.append("class_level", classLevel ? classLevel : "");
            formData.append("about_course", values?.about_this_course ? values?.about_this_course : "");
            formData.append("fee", inputValue ? inputValue :0);
            formData.append("special_instruction", values?.special_instruction ? values?.special_instruction : "");
            formData.append("course_start", values?.course_start ? DatePickerFormatter(values?.course_start) : "");
            formData.append("course_end", values?.course_end ? DatePickerFormatter(values?.course_end) : "");
            formData.append("instructor_name", values?.instructor_name ? values?.instructor_name : "");
            formData.append("work_experience", values?.work_experience ? values?.work_experience : "");
            formData.append("about", values?.about ? values?.about : "");

            if ((editCourseDetails?.course_faq)?.length > 0) {
                formData.append("faq", JSON.stringify(editCourseDetails?.course_faq))
            } else {
                formData.append("faq", "")
            }

            if ((editCourseDetails?.course_slots)?.length > 0) {
                formData.append("time_slots", JSON.stringify(editCourseDetails?.course_slots));
            } else {
                formData.append("time_slots", "");
            }
            formData.append("refundable", coursePolicy.refundable);
            formData.append("refund_policy", coursePolicy.refund_policy);
            formData.append("trial", coursePolicy.trial);
            formData.append("trial_days", values.trial_days ? values.trial_days : "");
            formData.append("is_premium", coursePolicy.is_premium === 0 ? "paid" : "free");
            formData.append("address", values?.address ? values?.address : "");
            formData.append("postal_code", values?.postal_code ? values?.postal_code : "");
            if (values?.course_code) {
                formData.append("course_code", values?.course_code);
            }
            formData.append("course_status", values?.wsq === true ? 1 : 0)
            formData.append("is_sfc_status", values?.sfc === true ? 1 : 0)
            formData.append("approach", values?.select_approach ? values?.select_approach : "")
            if (fundingPrices?.length > 0) {
                formData.append("skill_funding", JSON.stringify(fundingPrices));
            } else {
                formData.append("skill_funding", JSON.stringify([]));
            }
            formData.append("sponsored", sponsoredCourse ? 1 : 0);

            if (deletedFundingId?.length > 0) {
                formData.append("deleted_funding_id", JSON.stringify(deletedFundingId));
            }

            if (deletedTimeSlotsId?.length > 0) {
                formData.append("deleted_time_slots_id", JSON.stringify(deletedTimeSlotsId));
            } else {
                formData.append("deleted_time_slots_id", JSON.stringify([]));
            }
            if (videoAndThumbnail?.video !== null) {
                formData.append("video", videoAndThumbnail?.video);
            }
            if (videoAndThumbnail?.thumbnail !== null) {
                formData.append("thumbnail", videoAndThumbnail?.thumbnail);
            }
            dispatch(updateServices.updateCourse(courseDetails?.id, formData))
                .then((response) => {
                    setLoader(false)
                    setVideoThumbnailInput(false)
                    setShowFAQinput(false)
                    setShowDocumentInput(false)
                    getCourse()
                    if (sponsoredCourse && courseDetails?.status==="pending"){
                        localStorage.setItem("paymentType", "sponsoredCourse");
                        setIsModalVisible(true);
                    } else {
                        Swal.fire(
                            "Successfully!",
                            `${response.message}`,
                            "success"
                        ).then((result) => {
                            if (result.isConfirmed) {
                                setIsUpdateModalOpen(false)
                                // courseDetails
                            }

                        })
                    }
                })
                .catch((error) => {
                    setLoader(false)
                    Swal.fire(
                        "Failed!",
                        `${error.message}`,
                        "error"
                    )
                });

        } catch (err) {
            setLoader(false)
            Swal.fire(
                "Failed!",
                `${err.message}`,
                "error"
            )
        }


    }
    const handleDownload = () => {
        const fileUrl = `${DEMO_IMAGE_URL}${courseDetails?.document}`
        const link = document.createElement("a");
        link.href = fileUrl;
        link.download = courseDetails?.document;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    useEffect(() => {
        if (selectedCategory) {
            const filtered = categories.flatMap((category) =>
                category.child_category.filter(
                    (item) => item.parent_id === selectedCategory
                )
            );

            const childCategoryOptions = filtered.map((data) => ({
                name: data.name,
                id: data.id,
            }));

            const subCategoryOptions = filtered.flatMap((child) => {
                return (
                    child.sub_categories?.map((subData) => ({
                        name: subData.name,
                        id: subData.id,
                        category_id: subData.parent_id,
                    })) || []
                );
            });

            if (childCategoryOptions.length > 0) {
                setChildCategories(childCategoryOptions);
            }

            setFilteredSubCategories(
                subCategoryOptions.filter(
                    (item) => item.category_id === selectedChildCategory
                )
            );
        }
    }, [categories, selectedCategory, selectedChildCategory]);



    const renderDayRow = (day) => {

        const handleDayChange = (day, checked) => {
            const { course_slots } = editCourseDetails;

            if (checked) {
                setEditCourseDetails((prevDetails) => ({
                    ...prevDetails,
                    course_slots: [...course_slots, { week_day: day, start_time: "", end_time: "" }]
                }));
            } else {
                const existingSlot = course_slots.find((slot) => slot.week_day === day);

                if (existingSlot) {
                    setDeletedTimeSlotsId((prevIds) => [...prevIds, existingSlot.id]);
                }

                setEditCourseDetails((prevDetails) => ({
                    ...prevDetails,
                    course_slots: course_slots.filter((slot) => slot.week_day !== day)
                }));
            }
        };

        const handleTimeChange = (day, time) => {
            const { course_slots } = editCourseDetails;
            const updatedSlots = course_slots.map((slot) => {
                if (slot.week_day === day) {
                    return { ...slot, start_time: time[0].format("HH:mm"), end_time: time[1].format("HH:mm") };
                }
                return slot;
            });

            setEditCourseDetails((prevDetails) => ({
                ...prevDetails,
                course_slots: updatedSlots
            }));
        };


        const dayInfo = editCourseDetails.course_slots.find((slot) => slot.week_day === day);
        const startTime = dayInfo?.start_time ? dayjs(dayInfo.start_time, "HH:mm") : null;
        const endTime = dayInfo?.end_time ? dayjs(dayInfo.end_time, "HH:mm") : null;

        return (
            <Row key={day} gutter={[16, 16]} align="middle" className='mb-3 course_time_picker'>
                <Col sm={24} xl={3}>
                    <Checkbox onChange={(e) => handleDayChange(day, e.target.checked)}
                        checked={!!dayInfo}
                    >
                        {day}
                    </Checkbox>
                </Col>
                <Col sm={24} xl={11}>
                    <Form.Item className='mb-0'>
                        <RangePicker
                            needConfirm={false}
                            format="HH:mm"
                            onChange={(time) => handleTimeChange(day, time)}
                            value={startTime && endTime ? [startTime, endTime] : null}
                        />
                    </Form.Item>
                </Col>
            </Row>
        );
    };

    const handlecategory = (value) => {
        setSelectedCategory(value);
        setSelectedSubCategory(null);
        setSelectedChildCategory(null)
        setFilteredSubCategories([]);
    };

    const handleDocumentUpload = (e) => {
        const selectedFile = e.target.files[0];
        setEditCourseDetails((prevState) => ({
            ...prevState,
            course_document: selectedFile,
        }));
    };

    const handleVideoUpload = ({ fileList }) => {
        const selectedFile = fileList[0]?.originFileObj;
        setVideoAndThumbnail((prevState) => ({
            ...prevState,
            video: selectedFile,
        }));
        if (selectedFile) {
            const videoUrl = URL.createObjectURL(selectedFile);
            setVideoUrl(videoUrl)
        } else {
            setVideoUrl(null)
        }

    }

    const handleThumbnailUpload = ({ fileList }) => {
        const selectedFile = fileList[0]?.originFileObj;
        setVideoAndThumbnail((prevState) => ({
            ...prevState,
            thumbnail: selectedFile,
        }));
    }

    const handlehelp = () => {
        setHelpModel(true)
    }
    const handleHelpCancle = () => {
        setHelpModel(false)
    }
    const handlehelpOk = () => {
        setHelpModel(false)
    }

    const renderFAQs = () => {
        return (
            <>
                <Row>
                    {editCourseDetails.course_faq.map((input) => (
                        <Col md={24} key={input.id}>
                            <Space className='edit_course_faq_box' align="baseline">
                                <Input
                                    placeholder="Enter question"
                                    value={input.question}
                                    onChange={(e) => handleInputChange(input.id, 'question', e.target.value)}
                                    className='edit_course_faq_itmes'
                                />
                                <TextArea
                                    placeholder="Enter answer"
                                    value={input.answer}
                                    rows={6}
                                    onChange={(e) => handleInputChange(input.id, 'answer', e.target.value)}
                                    className='edit_course_faq_itmes'
                                />
                                <Button className='remove_course_faq_btn' type="danger" onClick={() => handleRemoveInput(input.id)}>
                                    <FaMinusCircle />
                                </Button>
                            </Space>
                        </Col>
                    ))}
                </Row>
                <Button type="dashed" onClick={handleAddInput} style={{ width: '100%' }}>
                    Add more <span><IoMdAddCircle /></span>
                </Button>
            </>
        )
    }

    const handleCheckboxChange = (e) => {
        if (e.target.checked) {
            setSponsoredCourse(true);
        } else {
            setSponsoredCourse(false);
        }
    };

    const approachOptions = [
        {
            label: 'Online',
            value: 1,
        },
        {
            label: 'Physical',
            value: 2,
        },
        {
            label: 'Combined',
            value: 3,
        },
    ]

    useEffect(() => {
        if (courseDetails?.skill_funding?.length > 0) {
            const initialPrices = courseDetails?.skill_funding?.map(item => ({
                id: item?.id,
                price_category_id: item?.price_category_id,
                discount: item?.discount,
            }));
            setFundingPrices(initialPrices);
        }
    }, [courseDetails]);

    const renderFundingPrice = () => {
        const addRow = () => {
            setFundingPrices([...fundingPrices, { price_category_id: null, discount: '' }]);
        };

        const removeRow = (index) => {
            setFundingPrices((prevFundingPrices) => {
                const removedItem = prevFundingPrices[index];
                if (removedItem?.id) {
                    setDeletedFundingId((prevRemovedIds) => [
                        ...prevRemovedIds,
                        removedItem.id,
                    ]);
                }

                return prevFundingPrices.filter((_, idx) => idx !== index);
            });
        };

        const handleSelectChange = (value, index) => {
            setFundingPrices(fundingPrices.map((row, idx) => idx === index ? { ...row, price_category_id: value } : row));
        };

        const handleInputChange = (e, index) => {
            setFundingPrices(fundingPrices.map((row, idx) => idx === index ? { ...row, discount: e.target.value } : row));
        };

        const getFilteredOptions = (currentIndex) => {
            const selectedValues = fundingPrices
                .filter((_, idx) => idx !== currentIndex)
                .map(row => row.price_category_id);

            return pricingCategory
                .filter(option => !selectedValues.includes(option.id))
                .map(option => ({
                    value: option.id,
                    label: option.name,
                }));
        };

        return (
            <>
                <p
                    style={{
                        textAlign: "start",
                        fontFamily: "poppins",
                        fontSize: "16px",
                        marginBottom: "16px",
                        fontWeight: "500",
                        color:"#434343"
                    }}
                >
                    Funding Price
                </p>
                {fundingPrices.map((row, index) => (
                    <Row className="funding-price-row row" key={index}>
                        <Col className="funding-price-col" md={24}>

                            <Row>
                                <Col md={12} className="input_right_spacing">
                                    <Select
                                        placeholder="Select funding category"
                                        className="input-box-login funding-select"
                                        options={getFilteredOptions(index)}
                                        value={row.price_category_id}
                                        onChange={(value) => handleSelectChange(value, index)}
                                        required
                                        status={!row.price_category_id && row.touched ? 'error' : ''}
                                    />
                                </Col>
                                <Col md={12} className="input_left_spacing ">
                                    <Input
                                        type="number"
                                        placeholder="Enter skill funding"
                                        value={row.discount}
                                        onChange={(e) => handleInputChange(e, index)}
                                        className='funding-price-input'
                                        onKeyPress={(e) => {
                                            if (e.key === '-' || e.key === 'e') {
                                                e.preventDefault();
                                            }
                                        }}
                                        min="0"
                                        required
                                        status={!row.price && row.touched ? 'error' : ''}
                                    />
                                    <Button
                                        className='remove_course_faq_btn'
                                        type="danger"
                                        onClick={() => removeRow(index)}
                                    >
                                        <FaMinusCircle />
                                    </Button>
                                </Col>

                            </Row>
                        </Col>
                    </Row>

                ))}
                {fundingPrices.length < pricingCategory.length && (
                    <Button type="dashed" style={{ width: '100%' }} onClick={addRow}>
                        Add more <span><IoMdAddCircle /></span>
                    </Button>
                )}
            </>
        );
    }
    return (
        <div>
            <Form
                form={editForm}
                name="basic"
                initialValues={{
                    title: courseDetails?.title,
                    category: courseDetails?.parent_category_name,
                    sub_category: courseDetails?.sub_category_name,
                    child_category: courseDetails?.child_category_name,
                    visibility: courseDetails?.visibility,
                    course_class: courseDetails?.class_level ? parseInt(courseDetails?.class_level) : null,
                    about_this_course: courseDetails?.about_course,
                    fee: coursePolicy?.is_premium !== 0 ? 0 : courseDetails?.fee,
                    special_instruction: courseDetails?.special_instruction,
                    instructor_name: courseDetails?.instructor[0]?.instructor_name,
                    work_experience: courseDetails?.instructor[0]?.work_experience,
                    about: courseDetails?.instructor[0]?.about,
                    course_start: courseDetails?.course_start !== null ? dayjs(courseDetails?.course_start).startOf('day') : null,
                    course_end: courseDetails?.course_end !== null ? dayjs(courseDetails?.course_end).startOf('day') : null,
                    postal_code: courseDetails?.postal_code,
                    address: courseDetails?.address,
                    course_code: courseDetails?.course_code,
                    wsq: courseDetails?.course_status == 1 ? true : false,
                    sfc: courseDetails?.is_sfc_status == 1 ? true : false,
                    trial_days: courseDetails?.trial_days,
                    select_approach: courseDetails?.approach === 0 ? undefined : courseDetails?.approach,

                }}
                autoComplete="off"
                className="add-new-course-form"
                layout="vertical"
                onFinish={onFinish}
            >
                <Row className="form-row">
                    <Col md={24} className="custom-padding laptop-padd">
                        <Form.Item
                            className="form-input-class"
                            label="Title"
                            name="title"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter title.",
                                },
                            ]}
                        >
                            <Input

                                value={courseDetails?.title}
                                className="input-box-login"
                                placeholder="Type here....."
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col md={8} className="input-new-course">
                        <Form.Item
                            className="form-input-class"
                            name="category"
                            label="Select Categories"
                        >
                            <Select
                                className="input-box-login select-category"
                                placeholder="Category"
                                prefix={<MdOutlineCategory />}
                                onChange={(value) => handlecategory(value)}
                            >
                                {categories &&
                                    categories.map((category) => (
                                        <Select.Option
                                            key={category.id}
                                            value={category.id}
                                        >
                                            {category.name}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col md={8} className="input-new-course">
                        <Form.Item
                            className="form-input-class"
                            name="sub_category"
                            label="Select Sub-Categories"
                        >
                            <Select
                                className="input-box-login sub-select-category"
                                placeholder="Sub-Categories"
                                prefix={<MdOutlineCategory />}
                                onChange={(value) =>
                                    setSelectedChildCategory(value)
                                }
                            >
                                {childCategories &&
                                    childCategories.map((childCategory) => (
                                        <Select.Option
                                            key={childCategory.id}
                                            value={childCategory.id}
                                        >
                                            {childCategory.name}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col md={8} className="input-new-course">
                        {/* as per the data this id sub Categories */}
                        <Form.Item
                            className="form-input-class"
                            name="child_category"
                            label="Select Child Categories"
                        >
                            <Select
                                className="input-box-login sub-select-category"
                                placeholder="Child-Categories"
                                prefix={<MdOutlineCategory />}
                                value={selectedSubCategory}
                                onChange={(value) => setSelectedSubCategory(value)}
                            >
                                {filteredSubCategories &&
                                    filteredSubCategories.map((subCategory) => (
                                        <Select.Option
                                            key={subCategory.id}
                                            value={subCategory.id}
                                            disabled={
                                                selectedSubCategory === subCategory.id
                                            }
                                        >
                                            {subCategory.name}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col md={8} className="custom-padding fee-instruction-upload-col special-instruction">
                        <Form.Item
                            className="form-input-class"
                            name="visibility"
                            label="Visibility"
                        >
                            <Select
                                className="input-box-login select-category"
                                placeholder="Choose Visibility"
                                prefix={<MdOutlineCategory />}
                            >
                                <Select.Option value="public">Public</Select.Option>
                                <Select.Option value="private">Private</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col md={8} className="input-new-course">
                        <Form.Item
                            className="form-input-class"
                            name="course_class"
                            label="Select Session"
                        >
                            <Select
                                className="input-box-login select-class"
                                placeholder="Class"
                                prefix={<MdOutlineCategory />}
                                onChange={(e) => setClassLevel(e)}
                            >
                                {classes &&
                                    classes.map((classe) => (
                                        <Select.Option key={classe.id} value={classe.id}>
                                            {classe.name}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col md={8} className="input-new-course input_left_spacing input_right_spacing">
                        <Form.Item
                            className="form-input-class"
                            name="select_approach"
                            label="Select Approach"
                        >
                            <Select
                                placeholder="Select Approach"
                                className="input-box-login select-class"
                                options={approachOptions}
                            />
                        </Form.Item>
                    </Col>

                </Row>

                <Row className="form-row">
                    <Col md={24} className="custom-padding laptop-padd">
                        <Form.Item
                            className="form-input-class"
                            name="about_this_course"
                            label="Description"
                        >
                            <TextArea

                                className="leave-comment-text-area"
                                rows={4}
                                placeholder="Type here....."
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row className="form-row">
                    <Col md={24} className="custom-padding laptop-padd">
                        <Form.Item
                            className="form-input-class"
                            name="school_images"
                            valuePropName="file"
                            label="Organisation Image"
                        >
                            {courseDetails?.school_images?.length > 0 ? (
                                <>
                                    <Upload
                                        action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                                        listType="picture-card"
                                        fileList={fileList}
                                        onPreview={handlePreview}
                                        onChange={handleChange}
                                    >
                                        {fileList.length >= 8 ? null : uploadButton}
                                    </Upload>

                                    {previewImage && (
                                        <Image
                                            wrapperStyle={{
                                                display: 'none',
                                            }}
                                            preview={{
                                                visible: previewOpen,
                                                onVisibleChange: (visible) => setPreviewOpen(visible),
                                                afterOpenChange: (visible) => !visible && setPreviewImage(''),
                                            }}
                                            src={previewImage}
                                        />
                                    )}
                                </>
                            ) : (<><p className='none_info_title'>No image added by the vendor</p></>)}

                        </Form.Item>
                    </Col>
                </Row>

                <Row className="form-row fee-instruction-upload-row">
                    <Col md={12}
                        className="custom-padding fee-instruction-upload-col fee-include"
                    >
                        <Form.Item
                            className="form-input-class"
                            name="fee"
                            label='Fee'
                        >
                            <Input
                                disabled={coursePolicy.is_premium !== 0}
                                type="number"
                                className="input-box-login"
                                placeholder="Type here....."
                                prefix={<CiDollar />}
                                value={inputValue}
                                onChange={(e) => {
                                    if (coursePolicy.is_premium === 0) {
                                        setInputValue(e.target.value);
                                    }
                                }}
                            />
                        </Form.Item>

                    </Col>
                    <Col md={12}
                        className="custom-padding fee-instruction-upload-col special-instruction"
                    >
                        <Form.Item
                            className="form-input-class"
                            name="special_instruction"
                            label=" Special Instruction (Optional)"
                        >
                            <Input
                                placeholder="Enter Instructions If Any?"
                                className="input-box-login"
                                prefix={<BsFileCode />}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={12}
                        className="custom-padding fee-instruction-upload-col fee-include"
                    >
                        <Form.Item
                            name="course_start"
                            label='Start Date'
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: "Please select the course start date.",
                        //     },
                        // ]}
                        >
                            <DatePicker format="YYYY-MM-DD" />
                        </Form.Item>
                    </Col>

                    <Col
                        md={12}
                        className="custom-padding fee-instruction-upload-col fee-include"
                    >
                        <Form.Item
                            name="course_end"
                            label='End Date'

                        >
                            <DatePicker format="YYYY-MM-DD" />
                        </Form.Item>
                    </Col>
                    <Col md={12} className='input-new-course'>
                        <Form.Item
                            className="form-input-class"
                            name="postal_code"
                            label='Postal Code'
                            rules={[
                                {
                                    pattern: /^\d{6}$/,
                                    message: "Postal code must be exactly 6 digits.",
                                },
                            ]}
                        >
                            <Input
                                className="input-box-login"
                                placeholder="Enter postal code"
                                maxLength={6}
                                onKeyPress={(e) => {
                                    if (!/[0-9]/.test(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                                onPaste={(e) => {
                                    const paste = (e.clipboardData || window.clipboardData).getData(
                                        "text"
                                    );
                                    if (!/^[0-9]+$/.test(paste)) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={12} className='input-new-course'>
                        <Form.Item
                            className="form-input-class"
                            name="address"
                            label='Address'
                        >
                            <Input
                                className="input-box-login"
                                placeholder="Enter address"
                            />
                        </Form.Item>
                    </Col>
                    <Col md={12} className='input-new-course'>
                        <Form.Item
                            className="form-input-class"
                            name="course_code"
                            label='Item Code'
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter item code.",
                                },
                            ]}
                        >
                            <Input
                                disabled={courseDetails?.course_code ? true : false}
                                className="input-box-login"
                                placeholder="Item Code"
                            />
                        </Form.Item>
                    </Col>
                    <Col md={12} className="input-new-course fee-instruction-upload-col upload-image">
                        <Form.Item
                            className="form-input-class"
                            name="course_image"
                            label="Item Image"
                        >
                            <Upload
                                action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                                listType="picture-card"
                                fileList={courseImageCover}
                                onPreview={handlePreview}
                                onChange={handleCourseCoverChange}
                                maxCount={1}
                            >
                                {courseImageCover.length > 0 ? null : uploadButton}
                            </Upload>
                            {previewImage && (
                                <Image
                                    wrapperStyle={{
                                        display: 'none',
                                    }}
                                    preview={{
                                        visible: previewOpen,
                                        onVisibleChange: (visible) => setPreviewOpen(visible),
                                        afterOpenChange: (visible) => !visible && setPreviewImage(''),
                                    }}
                                    src={previewImage}
                                />
                            )}
                        </Form.Item>
                    </Col>
                </Row>

                {/* course divder */}
                <div className='course_details_divider'>
                </div>
                <Row className="form-row eligibility_row">
                    <Col md={24} className='input_left_spacing'>
                        <div className="d-flex justify-content-start gap-4 align-items-center">
                            <p className="eligibility_heading mb-0">
                                Eligibility
                            </p>
                            <div className="d-flex justify-content-start gap-2 align-items-center">
                                <Form.Item
                                    className="form-input-class mb-0"
                                    name="wsq" valuePropName="checked" >
                                    <Checkbox
                                        key="wsq"
                                        value="wsq"
                                        // onChange={(e) => setIsWSQChecked(e.target.checked)}
                                    >
                                        WSQ
                                    </Checkbox>
                                </Form.Item>
                                <Form.Item
                                    className="form-input-class mb-0"
                                    name="sfc" valuePropName="checked" >
                                    <Checkbox
                                        key="sfc"
                                        value="sfc"
                                    >
                                        SFC
                                    </Checkbox>
                                </Form.Item>
                            </div>
                        </div>
                    </Col>
                    <Col md={24} className="fee-instruction-upload-col fee-include funding_col_main">
                        {coursePolicy?.is_premium === 0 ? renderFundingPrice() : <></>}
                    </Col>
                </Row>
                {/* course divder */}
                <div className='course_details_divider'>
                </div>
                <Row className="form-row instructor-details-container">
                    <Col md={24} className='custom-padding'>
                        <p className="instructor-detains">
                            Instructor’s Detail
                        </p>
                    </Col>
                    <Col md={24}
                        className="input-new-course fee-instruction-upload-col upload-image"
                    >
                        <Form.Item
                            className="form-input-class"
                            name="course_image"
                            label="Profile Photo"
                        >
                            <Upload
                                action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                                listType="picture-card"
                                fileList={instructorProfileImage}
                                onPreview={handlePreview}
                                onChange={handleInstructorProfileImageChange}
                                maxCount={1}
                            >
                                {instructorProfileImage.length > 0 ? null : uploadButton}
                            </Upload>
                            {previewImage && (
                                <Image
                                    wrapperStyle={{
                                        display: 'none',
                                    }}
                                    preview={{
                                        visible: previewOpen,
                                        onVisibleChange: (visible) => setPreviewOpen(visible),
                                        afterOpenChange: (visible) => !visible && setPreviewImage(''),
                                    }}
                                    src={previewImage}
                                />
                            )}
                        </Form.Item>
                    </Col>
                    <Col
                        md={12}
                        className="input-new-course fee-instruction-upload-col upload-image"
                    >
                        <Form.Item
                            className="form-input-class"
                            name="instructor_name"
                            label="Instructor Name"
                        >
                            <Input

                                className="input-box-login"
                                prefix={<CiUser color="#7c8597" />}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={12} className="input-new-course">
                        <Form.Item
                            className="form-input-class"
                            name="work_experience"
                            label="Work Experience"
                        >
                            <Input

                                type="number"
                                className="input-box-login"
                                prefix={<LiaCalendarAltSolid color="#7c8597" />}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={24} className="input-new-course">
                        <Form.Item
                            className="form-input-class"
                            name="about"
                            label="About"
                        >
                            <Input

                                className="input-box-login"
                                placeholder="Type something"
                                prefix={<BsInfoCircle color="#7c8597" />}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                {/* course divder */}
                <div className='course_details_divider'>
                </div>
                <div className="course-faq-container custom-padding mb-0 mt-0">
                    <p className="frequently-asked-que mb-1">
                        Frequently Asked Questions (FAQ’s)❓
                    </p>
                    {courseDetails?.vendor_faq?.length > 0 ? (
                        <Button onClick={() => setShowFAQinput(true)}>Edit FAQ's</Button>
                    ) : (
                        <Button onClick={() => setShowFAQinput(true)}>Add FAQ's</Button>
                    )}
                </div>

                {showFAQinput ? renderFAQs() : (
                    <>
                        {courseDetails?.vendor_faq?.length > 0 ? (
                            <>
                                <Row className="faq-box-shadow-none">
                                    {courseDetails?.vendor_faq?.map((item) => (
                                        <Col md={12}>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<FaArrowRightLong />}
                                                    aria-controls="panel1-content"
                                                    id={item.key}
                                                >
                                                    {item.question}
                                                </AccordionSummary>
                                                <AccordionDetails>{item.answer}</AccordionDetails>
                                            </Accordion>
                                        </Col>
                                    ))}
                                </Row>
                            </>
                        ) : (
                            <p className='nothing_added_txt custom-padding'>NO FAQ added by the vendor</p>
                        )}
                    </>
                )}

                {/* course divder */}
                <div className='course_details_divider'>
                </div>

                <CoursePolicy setCoursePolicy={setCoursePolicy} coursePolicy={coursePolicy} handlehelp={handlehelp} courseDetails={courseDetails} type={"edit"} />
                <div className="cancellation-pollicy-checkbox-container p-0">
                    {/* <p className="instructor-detains">Mark as Sponsored Course</p> */}
                    <Checkbox
                        className="cancellation-pollicy-checkbox"
                        onChange={handleCheckboxChange}
                        checked={ sponsoredCourse ? true : false}
                        disabled={courseDetails?.sponsored === 1 && courseDetails?.status!=="pending" ? true : false}
                    >
                        <span className="cancellation-policy-text">
                            Is this items is sponsored items ?
                        </span>
                    </Checkbox>
                </div>
                {/* course divder */}
                <div className='course_details_divider'>
                </div>

                <Form.Item className='custom-padding'>
                    <p className="course_main_title">
                        Item Slots
                    </p>
                    {allDays.map((day) => renderDayRow(day))}
                </Form.Item>

                {/* course divder */}
                <div className='course_details_divider'>
                </div>

                <Row className="form-row">
                    <Col md={24} className="input-new-course upload-documents">
                        <p className="course_main_title">
                            Document
                        </p>
                        <Form.Item
                            name="course_document"
                        >
                            {courseDetails?.document ? (
                                <Button className='download_docs_btn me-3' onClick={() => {
                                    setShowDocumentInput(true); setVideoAndThumbnail({
                                        video: null,
                                        thumbnail: null,
                                    });
                                }}>Edit Document</Button>
                            ) : (<Button onClick={() => setShowDocumentInput(true)}>Add Document</Button>)}

                            {showDocumentInput ? (
                                <>
                                    <label
                                        for="logo-document"
                                        className="custom-upload-logo d-flex justify-content-center upload-document-label mt-3 py-md-4 py-3 h-100"
                                    >
                                        <span className="custom-input-logo-left d-flex align-items-center gap-2">
                                            <LuUpload className="form-icons" />
                                            {editCourseDetails?.course_document
                                                ? editCourseDetails?.course_document?.name
                                                : "Drop or Upload Document and Photos"}
                                        </span>
                                    </label>
                                    <Input
                                        style={{ display: "none !important" }}
                                        type="file"
                                        id="logo-document"
                                        className="input-box-login upload-document-input"
                                        placeholder="www.welloffun.com"
                                        hidden
                                        onChange={(e) => handleDocumentUpload(e)}
                                    />
                                </>
                            ) : (
                                courseDetails?.document ? (
                                    <Button className='download_docs_btn' onClick={handleDownload}>Download Document</Button>
                                ) : (<p className='nothing_added_txt'>No document uploaded by the vendor</p>)
                            )}

                            { }
                        </Form.Item>
                    </Col>
                </Row>
                {/* course divder */}
                <div className='course_details_divider'>
                </div>
                <Row className="form-row">
                    <Col md={24} className="input-new-course edit_course_video">
                        <p
                            style={{
                                textAlign: "start",
                                fontFamily: "POPPINS",
                            }}
                        >
                            Video and Thumbnail
                        </p>
                        {courseDetails?.video ? (
                            <Button className='edit_uploaded_video_btn' onClick={() => {

                                setVideoThumbnailInput(true)
                            }
                            }>Edit Video</Button>
                        ) : (
                            ""
                        )}
                        {showVideoThumbnailInput ? (
                            <>
                                <Upload
                                    listType="text"
                                    maxCount={1}
                                    accept="video/*"
                                    onChange={(e) => handleVideoUpload(e)}
                                >
                                    <Button className="video-upload">Click to Upload Video</Button>
                                </Upload>

                                {videolUrl && (
                                    <div style={{ marginTop: 20, marginBottom: 20 }}>
                                        <video width="400" controls>
                                            <source src={videolUrl} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                )}
                                <Upload
                                    listType="picture"
                                    maxCount={1}
                                    accept="image/*"
                                    onChange={(e) => handleThumbnailUpload(e)}
                                >
                                    <Button className="thumbnail-upload">
                                        Click to Upload Thumbnail
                                    </Button>
                                </Upload>
                            </>
                        ) : (
                            <video width="400" controls poster={`${DEMO_IMAGE_URL}${courseDetails?.thumbnail}`}>
                                <source src={`${DEMO_IMAGE_URL}${courseDetails?.video}`} />
                            </video>
                        )}


                    </Col>
                </Row>
                <Form.Item
                    className="mb-0 add-course-form-item"
                    wrapperCol={{
                        offset: 8,
                        span: 24,
                    }}
                >
                    <Button htmlType="submit" disabled={loader} className="register-button">
                        {loader ? <Spin indicator={<LoadingOutlined  spin />} /> : 'Update Items'}
                    </Button>
                </Form.Item>

            </Form>


            {/* Plan Select Modal */}
            <Modal
                title="Unlock premium exposure by subscribing to our exclusive plan!"
                open={isModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
                centered
                className="planModal"
                okText="Yes"
            >
                <div className="planBody">
                    <p className="upperText">
                        With a subscription, you can list your items in the high-visibility
                        Sponsorship section and reach more students than ever before.
                    </p>
                    <p className="mainText">
                        Would you like to proceed with marking this items as sponsored?
                    </p>
                </div>
            </Modal>

            {/*PaymentForm */}

            <Modal
                title="Payment"
                open={paymentForm}
                onOk={handlePaymentForm}
                onCancel={handlePaymentFormCancle}
                centered
                className="paymentModal"
                okText="Done"
                width={980}
            >
                {loader ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                        }}
                    >
                        <Spin
                            indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
                        />
                    </div>
                ) : (
                    <Elements stripe={stripePromise} options={{ clientSecret }}>
                        <StripePaymentForm
                            paymentCourseData={courseDetails}
                            sponrshipPlan={sponrshipPlan}
                        />
                    </Elements>
                )}
            </Modal>

            {/* Custom Duration Modal */}
            <Modal
                title="Add Custom Duration"
                open={isDurationModal}
                onOk={handleDurationModalOk}
                onCancel={handleDurationModalCancel}
                centered
                className="planModal"
                okText="Pay"
            >
                <div className="planBody">
                    <p className="upperText text-center">
                        Set a custom item duration to tailor the learning experience to your
                        students' needs. Adjust start and end dates now.
                    </p>
                </div>

                <Row>
                    <Col span={12}>
                        <Input
                            placeholder="Enter days count"
                            type="number"
                            value={days}
                            onChange={(e) => setDays(e.target.value)}
                            onKeyUp={handleDaysChange}
                            style={{ marginBottom: "1rem", width: "90%" }}
                        />
                    </Col>
                    <Col span={12}>
                        <Input
                            placeholder="item amount"
                            value={courseAmount}
                            onChange={(e) => setCourseAmount(e.target.value)}
                            style={{ width: "90%" }}
                        />
                    </Col>
                </Row>
            </Modal>

            {/* Plan Pricing Modal */}
            <Modal
                open={isPlanModalVisible}
                onOk={handlePlanModalOk}
                onCancel={handlePlanModalCancel}
                centered
                className="planPriceModal"
                footer={false}
                width={1140}
            >
                <div className="modalHeader">
                    <div className="textSection">
                        <h4 className="modalTitle">
                            Unlock premium exposure by subscribing to our exclusive plan!
                        </h4>
                        <p className="modalSubTItle">Boost Your item with Sponsorship</p>
                    </div>
                    <div className="btnSection">
                        <Button
                            className="durationButton"
                            onClick={() => openDurationModal()}
                        >
                            Custom Duration
                        </Button>
                    </div>
                </div>
                <div className="modalBody">
                    <div className="row d-flex justify-content-center">
                        {sponsorshipData &&
                            sponsorshipData?.map((sponsorData, index) => {
                                return (
                                    <div className="col-xl-4 col-md-6 mb-4" key={sponsorData?.id}>
                                        <div
                                            className={
                                                index === 0
                                                    ? "cards priceCard starterCard"
                                                    : index === 1
                                                        ? "cards priceCard proCard"
                                                        : "cards priceCard eliteCard"
                                            }
                                        >
                                            <div className="cardBody">
                                                <div className="cardTopSection">
                                                    <h4 className="cardTitle">{sponsorData?.name}</h4>
                                                    <p className="cardSubText">
                                                        {sponsorData?.desciption
                                                            ?.replace(/<\/?p>/g, "")
                                                            .replace(/&nbsp;/g, "\n")}
                                                    </p>
                                                    <div className="cardDetailList">
                                                        {sponsorData?.benefits?.map((benefit, index) => (
                                                            <div className="cardDetailListItem" key={index}>
                                                                <span className="icon">
                                                                    <HiMiniCheckBadge />
                                                                </span>
                                                                <span className="dtlText">{benefit}</span>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className="cardBottomSection">
                                                    <button
                                                        type="button"
                                                        className="btn buyNowButton"
                                                        onClick={(e) => handlePayment(sponsorData)}
                                                    >
                                                        Buy Now
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </Modal>

            <Modal
                title="Standard list"
                open={helpModel}
                onOk={handlehelpOk}
                onCancel={handleHelpCancle}
                centered
                className='course_policy_popup'

            >
                <div>
                    <h3>Eligibility for Refund</h3>
                    <p><strong>Timeframe:</strong> Refunds are available if the request is made within [X] days of purchase or before the course starts.</p>
                    <p><strong>Course Access:</strong> No refunds will be issued after the course has started or if access to course materials has been granted.</p>

                    <h3>Refund Request Process</h3>
                    <p><strong>How to Request:</strong> To request a refund, please contact our support team at [support email/phone number] with your order details and reason for the refund.</p>
                    <p><strong>Required Information:</strong> Include your order number, the date of purchase, and any other relevant information to process your request.</p>

                    <h3>Refund Amount</h3>
                    <p><strong>Full Refund:</strong> A full refund is available if the request is made within [X] days of purchase and before accessing the course materials.</p>
                    <p><strong>Partial Refund:</strong> A partial refund may be available if the request is made after accessing the course but before [specific time or condition].</p>
                    <p><strong>Non-Refundable:</strong> Certain fees, such as administrative or processing fees, may be non-refundable.</p>

                    <h3>Processing Time</h3>
                    <p><strong>Refund Processing:</strong> Refunds will be processed within [X] business days of approval. The time it takes for the refund to appear in your account may vary depending on your payment method and financial institution.</p>

                    <h3>Non-Eligibility for Refund</h3>
                    <p><strong>No Show:</strong> No refunds will be issued for failure to attend the course without prior notice.</p>
                    <p><strong>Content Downloads:</strong> Once downloadable content has been accessed or downloaded, it may not be eligible for a refund.</p>

                    <h3>Cancellation by Provider</h3>
                    <p><strong>Course Cancellation:</strong> If we cancel the course for any reason, a full refund will be issued, or you will be given the option to transfer to a different course.</p>
                    <p><strong>Rescheduling:</strong> If the course is rescheduled, you will be offered the option to attend the rescheduled course or request a refund.</p>

                    <h3>Refund Policy Changes</h3>
                    <p><strong>Policy Updates:</strong> We reserve the right to change this refund policy at any time. Any changes will be communicated to you via email or posted on our website.</p>

                    <h3>Contact Information</h3>
                    <p><strong>Support Contact:</strong> For any questions or concerns regarding the refund policy, please contact us at [support email/phone number].</p>
                </div>

            </Modal>
        </div>
    )
}
