import React, { useState } from 'react';
import { Form, Row, Col, Input, Select, Button } from "antd";
import { CiUser } from "react-icons/ci";
import { CiMail } from "react-icons/ci";
import { BsTelephone } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import * as uploadServices from "../../services/uploadServices";
import Swal from "sweetalert2";


export default function CourseEnquiry({course_details,setModalOpen }) {
    const dispatch = useDispatch();
    const userdata = useSelector((state) => state.user.userData);

    const { TextArea } = Input;
    const [form] = Form.useForm();

    const onFinish = (values) => {
        try{
            const formData = new FormData();
            formData.append("course_details", JSON.stringify(course_details));
            formData.append("user_id", userdata?.user?.id);
            formData.append("name", values.fullName);
            formData.append("email", values.email);
            formData.append("phone", values.phone);
            formData.append("message", values.message);
            formData.append("typeOfEnquiry", values.typeOfEnquiry);

           dispatch(uploadServices.contactAdmin(formData)).then((response)=>{
                form.resetFields()
                if(setModalOpen !== undefined){
                    setModalOpen(false)
                }
                Swal.fire(
                    "Success!",
                    `${response?.message}`,
                    "success"
                )
                
           }).catch((error)=>{
            if(setModalOpen !== undefined){
                setModalOpen(false)
            }
                Swal.fire(
                    "Failed!",
                     `${error?.message}`,
                    "error"
                )
                form.resetFields()
           })
        }catch(e) {
            if(setModalOpen !== undefined){
                setModalOpen(false)
            }
            Swal.fire(
                "Failed!",
                "Unable to submit enquiry. Please try again later.",
                "error"
            )
            form.resetFields()
        }
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }
    
    return (
        <div>
        <h3 className='text-center course-enquiry-heading'>Course Enquiry</h3>
            <Form
                name="basic"
                layout="vertical"
                initialValues={{
                    fullName: userdata?.user?.name,
                    email: userdata?.user?.email,
                    phone: userdata?.user?.phone
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                form={form}
            >
                <Row>
                    <Col md={12} className="custom-padding">
                        <Form.Item
                            className="form-input-class"
                            name="fullName"
                            label="Full Name"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter full name.",
                                },
                            ]}
                        >
                            <Input
                                disabled
                                className="input-box-login"
                                placeholder="Enter your name here"
                                prefix={<CiUser />}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={12} className="custom-padding">
                        <Form.Item
                            className="form-input-class"
                            label="Email"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: "Email is not valid",
                                    type: "email",
                                },
                            ]}
                        >
                            <Input
                                disabled
                                className="input-box-login"
                                placeholder="Johndoe@gmail.com"
                                prefix={<CiMail />}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col md={12} className="custom-padding">
                        <Form.Item
                            className="form-input-class"
                            name="phone"
                            label="Phone Number"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter phone number.",
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        const initialPhoneNumber = getFieldValue('phone');

                                        if (initialPhoneNumber === value) {
                                            return Promise.resolve();
                                        }
                                        if (value && value.length === 10) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            new Error("Phone number must be exactly 10 digits.")
                                        );
                                    },
                                }),
                            ]}
                        >
                            <Input
                            disabled
                                className="input-box-login"
                                placeholder="please input phone number"
                                prefix={<BsTelephone />}
                                onKeyPress={(e) => {
                                    if (!/[0-9]/.test(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                                onPaste={(e) => {
                                    const paste = (e.clipboardData || window.clipboardData).getData("text");
                                    if (!/^[0-9]+$/.test(paste)) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={12} className="custom-padding">
                        <Form.Item
                            className="form-input-class type-of-enquiry"
                            name="typeOfEnquiry"
                            label="Type Of Enquiry"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select Enquiry.",
                                },
                            ]}
                        >
                            <Select
                                className="input-box-login type-of-enquiry"
                                placeholder="Select type of enquiry"
                            >
                                <Select.Option value="Course Enquiry">Course Enquiry</Select.Option>
                                <Select.Option value="Payment Enquiry">Payment Enquiry</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col md={24} className="custom-padding">
                        <Form.Item
                            className="mb-0"
                             name="message"
                            label="Message"
                            wrapperCol={{
                                offset: 8,
                                span: 24,
                            }}
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter message.",
                                },
                            ]}
                        >
                            <div className="suneditor-outer">
                                <TextArea rows={6} />
                            </div>
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item
                    className="mb-0 text-center"
                    wrapperCol={{
                        offset: 8,
                        span: 24,
                    }}
                >
                    <Button
                    disabled={course_details.length ===0 ?true : false}
                        className="register-button button-register"
                        htmlType="submit"
                    >
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}
