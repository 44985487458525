import React, { useEffect, useState } from "react";
import Sidebar from "../../../components/Sidebar/sidebar";
import { Row, Col, Image, Spin } from "antd";
import { MdWavingHand } from "react-icons/md";
import { RewardTrophy, featureImg2 } from "../../../constant/images";
import { useSelector, useDispatch } from "react-redux";
import * as getServices from "../../../services/getServices";
import { useNavigate } from "react-router";
import { LoadingOutlined } from "@ant-design/icons";
import {DEMO_IMAGE_URL} from "../../../Helper"

export default function Dashboard() {
    const userData = useSelector((state) => state.user.userData);
    const dispatch = useDispatch();

    const [paymentDetails, setPaymentDetails] = useState([]);
    // eslint-disable-next-line
    const [loader, setLoader] = useState(true);
    const [totalPoints, setTotalPoints] = useState(0);
    const [latestReward, setLatestReward] = useState(null);
    const [recommendedItem, setRecommendItem] = useState([])
    const navigate = useNavigate()
    useEffect(() => {
        dispatch(
            getServices.getPaymentDetails(userData?.user?.id)
        )
            .then((response) => {
                if (response?.payments) {
                    setPaymentDetails(response?.payments);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, [userData]);


    const recommendedItems = () => {
        setLoader(true)
        const queryString = `?recommended-courses=true`
        dispatch(getServices.getUserCourses(queryString))
            .then((response) => {
                setLoader(false)

                setRecommendItem(response?.data);
            }).catch((error) => {
                setLoader(false)
                console.error(error)

            })
    }
    useEffect(() => {
        recommendedItems()
    }, []);

    const handleCourseData = (values) => {

        navigate(`/course/${values?.id}/${values?.title}`, {
            state: { course_id: values?.id },
        });
    };

    useEffect(() => {
        // Calculate total points
        const points = paymentDetails.reduce((sum, payment) => {
            return payment?.reward ? sum + payment?.reward?.points : sum;
        }, 0);
        setTotalPoints(points);

        // Find the latest reward
        const latest = paymentDetails.reduce((latest, payment) => {
            if (!payment?.reward) return latest;
            return new Date(payment?.reward?.created_at) > new Date(latest?.reward?.created_at) ? payment : latest;
        }, paymentDetails[0]);
        setLatestReward(latest?.reward);
    }, [paymentDetails]);

    return (
        <>
            <Row>
                <Col xl={4} className="d-xl-block d-md-none ">
                    <Sidebar />
                </Col>
                <Col xl={20} md={24}>
                    <div className="main-section mb-md-4 mb-3">
                        <Row className="first-section-div">
                            <Col className="px-2" md={4}>
                                <div className="first-section-col-one">
                                    <Row className="first-section-first-row">
                                        <Col md={24} className="first-div-dashboard">
                                            <p className="hey">
                                                Hey <MdWavingHand />
                                            </p>
                                            <p className="good-morning">
                                                Welcome{" "}
                                                {userData?.user?.name}
                                                !
                                            </p>
                                        </Col>


                                    </Row>
                                </div>
                            </Col>
                            <Col md={20}>
                                <Row className="reward-point-section">
                                    <Col className="reward-col" md={8}>
                                        <p className="two-hund">{latestReward ? latestReward.points : 0}</p>
                                        <p className="rew-point">Reward Points Earned</p>
                                        <button type="button" className="btn btn-success">
                                            Redeem Now
                                        </button>
                                    </Col>
                                    <Col md={16} className="rev-col-mobile-full">
                                        <Row className="row-bg-gif-image">
                                            <Col className="total-reward-points-col" md={12}>
                                                <p className="total">You have total</p>
                                                <p className="reward-points">{totalPoints ? totalPoints : 0} Reward Points</p>
                                            </Col>
                                            <Col className="trophy-img-container" md={12}>
                                                <Image
                                                    width={256}
                                                    height={200}
                                                    src={RewardTrophy}
                                                    alt="Not Found"
                                                    className="img-fluid"
                                                    preview={false}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={24} sm={24} className="px-2 small-full-width">
                                {loader ? (
                                    <div className="d-flex justify-content-center align-items-center mt-4 mb-4">
                                        <Spin
                                            indicator={
                                                <LoadingOutlined
                                                    style={{
                                                        fontSize: 48,
                                                    }}
                                                    spin
                                                />
                                            }
                                        />
                                    </div>
                                ) : (
                                    <div className="third-div-dashboard">
                                        <p className="my-course">Recommended Item</p>
                                        {recommendedItem.length > 0 ? (
                                            recommendedItem &&
                                            recommendedItem.map((course) => {
                                                return (
                                                    <Row className="my-courses-row" onClick={() => handleCourseData(course)} key={course?.id}>
                                                        <Col className="my-courses-col-one" md={4}>
                                                            <Image preview={false} src={course?.image ? `${DEMO_IMAGE_URL}${course?.image}` : featureImg2} />
                                                        </Col>
                                                        <Col className="my-courses-col-two" md={20}>
                                                            <p className="course-name">
                                                                {
                                                                    course?.parent_category_name !== null ? course?.parent_category_name : ''
                                                                }

                                                            </p>
                                                            <p className="coures-sub-head">{course?.title}</p>

                                                        </Col>
                                                    </Row>
                                                );
                                            })
                                        ) : (
                                            <div className="spinner-container">
                                                <p>No Data</p>
                                            </div>
                                        )}
                                    </div>
                                )}


                            </Col>
                        </Row>

                    </div>
                </Col>
            </Row>
        </>
    );
}
