import React from "react";
import { Col, Row, Input, Form, Button } from "antd";
import { CiUser } from "react-icons/ci";
import { CiMail } from "react-icons/ci";
import { CgNotes } from "react-icons/cg";
import { MdOutlinePhone } from "react-icons/md";
import { CiGlobe } from "react-icons/ci";
import { IoLocationOutline } from "react-icons/io5";
import { FaTwitter } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FiInstagram } from "react-icons/fi";
import { FaGithub } from "react-icons/fa";



export default function ContactUs() {
  const { TextArea } = Input;
  const onFinish = () => {};

  const onFinishFailed = () => {};
  return (
    <>
    <div className="contact-us-container">
      <div className="contact-us-banner-div">
        <div className="contact-us-banner-text">
          <h2 className="contact-us-banner-text-header">Contact Us</h2>
          <p className="contact-us-banner-text-sub-head">
            Any question or remarks? Just write us a message!
          </p>
        </div>
        {/* <Image
          className="contact-us-banner-image"
          src={ContactUsBannner}
          preview={false}
        /> */}
      </div>
      <div className="contact-us-form-container">
        <p className="contact-us-form-header">
          Don’t Worry, Just fill the form and{" "}
        </p>
        <p className="contact-us-form-header-connect border-bottom-heading bottom-border-center">Let’s Connect!</p>
        <div className="form-container-with-image-main">
          <Row className="form-container-with-image" gutter={0}>
            <Col md={10}>
              <div className="contact-form-image-container">
                <div className="contact-form-image-text">
                  <p className="contact-form-image-text-head"> Contact</p>
                  <p className="contact-form-image-text-info">Information!</p>
                  <p className="contact-form-image-text-query">
                    Connect if have any query!
                  </p>
                  <div className="contact-form-image-text-icon-with-info">
                    <span className="contact-form-image-text-icon-with-info-icon">
                      <MdOutlinePhone />
                    </span>
                    <p className="contact-form-image-text-icon-with-info-text">
                      +91 9876543210
                    </p>
                  </div>
                  <div className="contact-form-image-text-icon-with-info">
                    <span className="contact-form-image-text-icon-with-info-icon">
                      <CiMail />
                    </span>
                    <p className="contact-form-image-text-icon-with-info-text">
                      info@welloffun.com
                    </p>
                  </div>
                  <div className="contact-form-image-text-icon-with-info">
                    <span className="contact-form-image-text-icon-with-info-icon">
                      <CiGlobe />
                    </span>
                    <p className="contact-form-image-text-icon-with-info-text">
                      www.welloffun.com
                    </p>
                  </div>
                  <div className="contact-form-image-text-icon-with-info">
                    <span className="contact-form-image-text-icon-with-info-icon">
                      <IoLocationOutline />
                    </span>
                    <p className="contact-form-image-text-icon-with-info-text breakText ">
                      203, St. Mountain View, San Francisco, California, USA
                    </p>
                  </div>
                  <div className="contact-form-image-social-links">
                    <p className="contact-form-image-social-text">
                      Social Links:
                    </p>
                    <div className="contact-form-image-social-links-icons">
                      <span className="contact-form-image-social-links-icons-span">
                        <FaTwitter
                          className="contact-us-social-icons"
                          color="#fff"
                        />
                      </span>
                      <span className="contact-form-image-social-links-icons-span">
                        <FaFacebookF
                          className="contact-us-social-icons"
                          color="#fff"
                        />
                      </span>
                      <span className="contact-form-image-social-links-icons-span">
                        <FiInstagram
                          className="contact-us-social-icons"
                          color="#fff"
                        />
                      </span>
                      <span className="contact-form-image-social-links-icons-span">
                        <FaGithub
                          className="contact-us-social-icons"
                          color="#fff"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col className="contact-form-input-container" md={14}>
              <div className="contact-form-input-inner-container">
                <Form
                  className="contact-us-form-fields"
                  name="basic"
                  labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    span: 24,
                  }}
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Row>
                    <Col md={12} className="custom-padding">
                      <p
                        style={{
                          textAlign: "start",
                          fontFamily: "POPPINS",
                        }}
                      >
                        Full Name
                      </p>

                      <Form.Item
                        className="form-input-class"
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter name.",
                          },
                        ]}
                      >
                        <Input
                          className="input-box-login"
                          placeholder="Name"
                          prefix={<CiUser color="#667085" />}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={12} className="custom-padding">
                      <p
                        style={{
                          textAlign: "start",
                          fontFamily: "POPPINS",
                        }}
                      >
                        Email
                      </p>

                      <Form.Item
                        className="form-input-class"
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "Email is not valid",
                          },
                        ]}
                      >
                        <Input
                          className="input-box-login"
                          placeholder="Email"
                          prefix={<CiMail color="#667085" />}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={24} className="custom-padding">
                      <p
                        style={{
                          textAlign: "start",
                          fontFamily: "POPPINS",
                        }}
                      >
                        Subject
                      </p>

                      <Form.Item
                        className="form-input-class"
                        name="subject"
                        rules={[
                          {
                            required: true,
                            message: "Please enter subject.",
                          },
                        ]}
                      >
                        <Input
                          className="input-box-login"
                          placeholder="Type here..."
                          prefix={<CgNotes color="#667085" />}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={24} className="custom-padding">
                      <p
                        style={{
                          textAlign: "start",
                          fontFamily: "POPPINS",
                        }}
                      >
                        Message
                      </p>

                      <Form.Item
                        className="form-input-class"
                        name="fullName"
                        rules={[
                          {
                            required: true,
                            message: "Please enter full name.",
                          },
                        ]}
                      >
                        <TextArea
                          className="contact-form-text-area"
                          rows={8}
                          placeholder="Type here..."
                          maxLength={6}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item
                    className="mb-0"
                    wrapperCol={{
                      offset: 8,
                      span: 24,
                    }}
                  >
                    <Button className="contact-us-form-button" htmlType="submit">
                      Send Message
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
    </>
  );
}
