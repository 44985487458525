// Import necessary dependencies
import React from "react";
// import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
// import { createStore, applyMiddleware } from "redux";
// import {thunk} from "redux-thunk";
// import { persistStore, persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage";
import { PersistGate } from "redux-persist/integration/react";
// import rootReducer from "./store/reducers/rootReducer";
import { createRoot } from "react-dom/client";
import { store, persistor } from './StoreRedux/reducer'


// Configure Redux Persist
// const persistConfig = {
//   key: "root",
//   storage,
// };
// const persistedReducer = persistReducer(persistConfig, rootReducer);

// const store = createStore(persistedReducer, applyMiddleware(thunk));
// const persistor = persistStore(store);
const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,

);
