import React, { useState, useEffect } from "react";
import { Row, Col, Image } from "antd";
import {
  OurMissionIcon,
  OurVisionIcon,
  PlatformHistoryImage,
  AboutUsLastImage,
} from "../../constant/images";
import * as getServices from "../../services/getServices";
import { useDispatch } from "react-redux";


export default function AboutUs() {
  const dispatch = useDispatch();
  const [pageData, setPageData] = useState();

  useEffect(() => {
    dispatch(getServices?.getPageDetails('about-us'))
      .then((response) => {
       
        setPageData(response?.related_pages);
      })
      .catch((err) => {
        console.error({ err });
      });
  }, []);



  return (
    <>
      <div className="about-us-container">
        <div className="about-banner-container">
          <div className="about-banner-overlay"></div>
          <p className="about-banner-heading">About Us</p>
        </div>
        <div className="our-mission-outer">
          <div className="our-mission container">
            <p className="our-mission-heading border-bottom-heading bottom-border-center">
              Our Mission And Vision
            </p>
            <Row className="our-mission-row d-flex justify-content-space row">
              <Col md={12} className="col-md-6">
                <div className="our-mission-col">
                  <div className="our-mission-vision-icon-container">
                    <Image
                      width={64}
                      height={64}
                      preview={false}
                      className="our-mission-icon"
                      src={OurMissionIcon}
                    />
                  </div>
                  <p className="our-mission-col-heading">Our Mission</p>
                  <p className="our-mission-col-description">
                    {pageData?.map((data) => {
                      if (data?.slug === "our-mission") {
                        return data?.description.replace(/<\/?p>/g, "");
                      }
                      return null;
                    })}
                  </p>
                </div>
              </Col>
              <Col md={12} className="col-md-6">
                <div className="our-mission-col">
                  <div className="our-mission-vision-icon-container">
                    <Image
                      preview={false}
                      width={64}
                      height={64}
                      className="our-vision-icon"
                      src={OurVisionIcon}
                    />
                  </div>
                  <p className="our-mission-col-heading">Our Vision</p>
                  <p className="our-mission-col-description">
                  {pageData?.map((data) => {
                      if (data?.slug === "our-vision") {
                        return data?.description.replace(/<\/?p>/g, "");
                      }
                      return null;
                    })}
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="platform-history-div">
          <Row className="platform-history-row">
            <Col className="platform-history-col-one" md={12}>
              <div className="platform-history-content">
                <p className="platform-history-heading border-bottom-heading bottom-border">Platform History</p>
                <p className="platform-history-description">
                {pageData?.map((data) => {
                      if (data?.slug === "platform-history") {
                        return <span dangerouslySetInnerHTML={{ __html: data.description }} />;
                      }
                      return null;
                    })}
                 
                </p>
              </div>
            </Col>
            <Col className="platform-history-col-two" md={12}>
              <Image
                className="platform-history-image"
                preview={false}
                src={PlatformHistoryImage}
              />
            </Col>
          </Row>
        </div>
        <div className="about-last-div-container">
          <Row className="about-last-div-row">
            <Col className="about-last-div-col-one" md={12}>
              <Image
                className="about-last-div-image"
                preview={false}
                src={AboutUsLastImage}
              />
            </Col>
            <Col className="about-last-div-col-two" md={12}>
              <div className="about-last-div-content">
                <p className="about-last-div-description">
                {pageData?.map((data) => {
                      if (data?.slug === "about-page-last-section") {
                        return data?.description.replace(/<\/?p>/g, "");
                      }
                      return null;
                    })}
                </p>
              </div>
            </Col>
          </Row>
        </div>
      
      </div>
    </>
  );
}
