import React, { useEffect, useState } from "react";
import { FaRegCalendar } from "react-icons/fa";
import { BiSolidComment } from "react-icons/bi";
import { FaFacebookF } from "react-icons/fa";
import { FaPinterestP } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { Form, Input, Rate, Carousel, Spin } from "antd";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as uploadServices from "../../services/uploadServices";
import * as getServices from "../../services/getServices";
import Swal from "sweetalert2";
import { DEMO_IMAGE_URL } from "../../Helper"
import { LoadingOutlined } from '@ant-design/icons';

export default function BlogDetailPage() {
    const userData = useSelector((state) => state.user.userData);
    const currentUrl = window.location.href;

    const { TextArea } = Input;
    const [form] = Form.useForm();
    let { state } = useLocation();
    const dispatch = useDispatch();
    const isUserLoggedIn = useSelector((state) => state.user.isUserLoggedIn);
    const isVendorLoggedIn = useSelector((state) => state.user.isVendorLoggedIn);
    const [blogRate, setBlogRate] = useState(0)
    const [blogDetail, setBlogDetail] = useState([])
    const [loader, setLoader] = useState(false)

    const onFinishFailed = (errorInfo) => {
        // console.log("Form failed:", errorInfo);
    };

    const getBlogDetails = () => {
        if (state?.blogId) {
            setLoader(true)
            try {
                dispatch(getServices.getBlogDetails(state?.blogId)).then((response) => {
                    setLoader(false)
                    setBlogDetail(response?.blog)
                }).catch((error) => {
                    setLoader(false)
                    console.error({ error })
                })
            } catch (e) {
                setLoader(false)
                console.error({ e })
            }
        }
    }


    useEffect(() => {
        getBlogDetails()
    }, [])

    const onFinish = (values) => {
        try {
            const formData = new FormData();
            formData.append("blog_id", state?.blogId);
            formData.append("name", values?.fullName);
            formData.append("email", values?.email);
            formData.append("comment", values?.comment);
            formData.append("rating", blogRate);
            dispatch(uploadServices.addBlogComment(formData)).then((response) => {
                getBlogDetails()
                form.resetFields()
                setBlogRate(0)
                Swal.fire(
                    "Success",
                    `${response?.message}`,
                    "success"
                )
            }).catch((error) => {
                Swal.fire(
                    "Failed",
                    `${error?.message}`,
                    "error"
                )
            })
        } catch (error) {
            console.error({ error })
        }
    };

    const DateFormatter = (date) => {
        const inputDate = date;

        const dateObj = new Date(inputDate);

        const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];

        const day = dateObj.getDate();
        const month = monthNames[dateObj.getMonth()];
        const year = dateObj.getFullYear();

        const formatDate = `${day} ${month} ${year}`;
        return formatDate;
    };

    const BlogContent = ({ content }) => {
        let formattedContent = content?.replace(/\n/g, '<br />');

        return (
            <div
                dangerouslySetInnerHTML={{
                    __html: formattedContent,
                }}
            />
        );
    };
    const openShareWindow = (url) => {
        window.open(
            url,
            'popup',
            'width=800,height=800,scrollbars=no,resizable=no'
        );
    };

    useEffect(() => {
        // Load the Instagram Embed.js script
        const script = document.createElement('script');
        script.src = '//www.instagram.com/embed.js';
        script.async = true;
        script.onload = () => {
            // Initialize Instagram embeds after script loads
            window.instgrm.Embeds.process();
        };
        document.body.appendChild(script);

        // Clean up the script when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    // const imageUrl = state?.blogData?.images?.map(item => `${DEMO_IMAGE_URL}${item.url}`) || [];
    // const handleShareToInstagram = () => {
    //     const shareUrl = `https://www.instagram.com/create/select/?url=${encodeURIComponent(currentUrl)}&image=${encodeURIComponent(imageUrl)}&caption=${encodeURIComponent(title)}`;
    //     window.open(shareUrl, '_blank');
    // };
    return (
        <>
            {loader ? (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 28 }} spin />} />
                </div>
            ) : (
                <div className="blog-details-container container">
                    <p className="blog-heading">{blogDetail?.title}</p>
                    <div className="blog-created-date-comment">
                        <p className="blog-created-date">
                            <span>
                                <FaRegCalendar className="calendar-icon" />
                            </span>
                            {blogDetail?.created_at !== undefined ? (
                                DateFormatter(blogDetail?.created_at)
                            ) : ''}

                        </p>
                        <p className="blog-total-comments">
                            <span>
                                <BiSolidComment className="comment-icon" />
                            </span>
                            {blogDetail?.blog_comments?.length}
                        </p>
                    </div>
                    <div className="blog-image-container">
                        <Carousel autoplay>
                            {blogDetail?.images && blogDetail?.images?.map((item, index) => (
                                <img className="blog-image" src={`${DEMO_IMAGE_URL}${item}`} key={index} />
                            ))}
 
                        </Carousel>
                    </div>
                    <div className="blog-content-container">

                        <BlogContent content={blogDetail?.content} />
                    </div>
                    <div className="socail-share-container">
                        <p className="share-text">Share:</p>
                        <div className="social-icon-container">
                            <p onClick={() => openShareWindow(`https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`)}>
                                <FaFacebookF className="facebook" />
                            </p>
                            <p onClick={() => openShareWindow(`https://pinterest.com/pin/create/button/?url=${currentUrl}`)}>
                                <FaPinterestP className="pinterest" />
                            </p>
                            <p onClick={() => openShareWindow(`https://twitter.com/intent/tweet?url=${currentUrl}`)}>
                                <FaXTwitter className="twitter" />
                            </p>



                            {/*                         
                        <InstapaperShareButton url={currentUrl} ><RiInstagramFill /></InstapaperShareButton>
                       
                        <p onClick={() => openShareWindow(currentUrl)}>
                            <FaYoutube className="youtube" />
                        </p> */}
                        </div>
                    </div>

                    {isUserLoggedIn || isVendorLoggedIn ? (
                        <div className="row form-row">
                            <div className="col-xl-6 col-md-10 form-col">
                                <div className="blog-form-container">
                                    <p className="leave-comment-heading border-bottom-heading">Leave a comment</p>
                                    <p className="blog-form-sub-heading">
                                        Your email address will not be published. Required fields are
                                        marked *
                                    </p>
                                    <Form
                                        name="blog-comment"
                                        onFinish={onFinish}
                                        onFinishFailed={onFinishFailed}
                                        initialValues={{
                                            fullName: userData?.user?.name,
                                            email: userData?.user?.email,

                                        }}
                                        form={form}
                                        className="blog-comment-form"
                                        autoComplete="off"
                                    >
                                        <div className="row form-row">
                                            <div className="col-md-4 form-col">
                                                <Form.Item
                                                    name="fullName"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please enter full name.",
                                                        },
                                                    ]}
                                                >
                                                    <Input disabled placeholder="Name*" className="name-input" />
                                                </Form.Item>
                                            </div>
                                            <div className="col-md-8 form-col">
                                                <Form.Item
                                                    name="email"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please enter a valid email address.",
                                                        },
                                                    ]}
                                                >
                                                    <Input disabled type="email" placeholder="Email*" className="email-input" />
                                                </Form.Item>
                                            </div>
                                            <div className="col-md-12 form-col">
                                                <Form.Item name="comment" rules={[
                                                    {
                                                        required: true,
                                                        message: "Please add comment.",
                                                    },
                                                ]}>
                                                    <TextArea
                                                        rows={4}
                                                        placeholder="Add Comment here..."
                                                        className="comment-input"
                                                    />
                                                </Form.Item>
                                            </div>
                                            <div className="col-md-6 form-col">
                                                <Form.Item name="upload-button">
                                                    <button type="submit" className="post-comment-button">
                                                        Post Comment
                                                    </button>
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                            <div className="col-xl-6 col-md-2 text-center ">
                                <div className="blog-form-container">
                                    <p className="leave-comment-heading">Rating</p>
                                </div>

                                <Rate className="blog-detail-rating mt-5" onChange={(e) => setBlogRate(e)} />
                            </div>
                        </div>
                    ) : (<></>)}

                    <div className="blog-comments-container">
                        {blogDetail?.blog_comments?.length > 0 ? (
                            <div className="header d-flex justify-content-between align-items-center">
                                <p className="comment-heading border-bottom-heading">Comments</p>
                                <p className="total-comments">{blogDetail?.blog_comments?.length} Comments</p>
                            </div>
                        ) : (<></>)}


                        {blogDetail?.blog_comments?.length > 0 && blogDetail?.blog_comments?.map((item) => (
                            <div className="comment-list-container" key={item?.id}>
                                <div className="row comment-row">
                                    <div className="col-xl-1 col-md-2 comment-col">
                                        <img className="user-profile" src={`${DEMO_IMAGE_URL}${item?.userImages}`} />
                                    </div>
                                    <div className="col-xl-11 col-md-10 comment-col">
                                        <div className="d-flex justify-content-between align-items-center name-date-container">
                                            <p className="name">{item?.name}</p>
                                            <p className="date">{DateFormatter(item?.created_at)}</p>
                                        </div>
                                        <Rate disabled defaultValue={item?.rating} className="rating" />
                                        <p className="comment-text">
                                            {item?.comment}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}

        </>
    );
}
