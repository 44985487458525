import React, { useState,useEffect, useContext } from 'react';
import * as getServices from "../../services/getServices";
import { useDispatch,useSelector } from 'react-redux';
import * as uploadServices from "../../services/uploadServices";
import { Row, Col, Pagination, Card, Flex, Modal} from 'antd'
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { FaLock } from "react-icons/fa";
import { FaRegHeart } from "react-icons/fa6";
import { GoHeartFill } from "react-icons/go";
// import { add_To_Cart } from "../../store/actions/user";
import { add_To_Cart } from '../../StoreRedux/appSlice';
import {  arrowUpRight, featureImg2 } from "../../constant/images";
import CourseEnquiry from '../courseEnquiry';
import {useAtom} from "jotai";
import {cartCount} from "../../Helpers/atoms/freeCoursesAtom"
import {DEMO_IMAGE_URL} from "../../Helper"
import { CartContext } from "../../Helpers/CartContext"

const FreeCourse = () => {
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.user.userData);
    const isUserLoggedIn = useSelector((state) => state.user.isUserLoggedIn);
    const isVendorLoggedIn = useSelector((state) => state.user.isVendorLoggedIn);
    const cartItems = useSelector((state) => state.user.cart);
    const pageSize = 6;
    const [paymentCourseData, setPaymentCourseData] = useState();
    const navigate = useNavigate()
    const [currentPage, setCurrentPage] = useState(1);
    const [freeCourse, setFreeCourse] = useState([])
    const [wishlistData, setWishlistData] = useState([]);
    const [itemsPerPage] = useState(6);
    const [cartData, setCartData] = useState([]);
    const [category, setCategory] = useState([]);
    const [categories, setCategories] = useState();
    const [modalOpen, setModalOpen] = useState(false);
    const [courseId,setCourseId] = useState();
    const [courseVenodrId,setCourseVendorId] = useState();
    const [cartCountValue,setCartCountValue] = useAtom(cartCount)
    const [courseDetails,setCourseDetails]=useState([]);
    const { addItemToCart } = useContext(CartContext);


    useEffect(() => {
      
        dispatch(getServices.getCategories())
            .then((response) => {
                setCategories(response?.categories);
            })
            .catch((err) => {
                console.error({ err });
            });
    }, [])

    const getWishlistData = () => {
        dispatch(getServices.GetWistlistDataUser(userData?.user?.id)).then((response) => {
                    setWishlistData(response?.course_details)
                }).catch((error) => {
                    console.error({ error });
                })
    }
    const handleCourseData = (values) => {

        navigate(`/course/${values?.id}/${values?.title}`, {
            state: { course_id: values?.id },
        });
    };
    const handleWishlistList = (course) => {
        let formData = new FormData();
        formData.append("course_id", course?.id)

        try {

            dispatch(uploadServices.addRemoveCourseWishlist(formData)).then((response) => {
                if (isUserLoggedIn) {
                getWishlistData()
                }
                Swal.fire(
                    "Success!",
                    `${response?.message}`,
                    "success"
                )
            }).catch((error) => {
                Swal.fire(
                    "Failed",
                    "Unable to add item to wishlist.",
                    "error"
                )
            })

        } catch (e) {
            Swal.fire(
                "Failed",
                "Unable to add course to wishlist.",
                "error"
            )
        }
    }

    const handleAddToCart = (data) => {

        if (!isUserLoggedIn && !isVendorLoggedIn) {
            if (data?.is_premium === "free" || data?.fee === 0 || data?.fee === null) {
                data.fee = 0;
            }

            dispatch(add_To_Cart(data))
            addItemToCart(cartItems?.length+1);
        } else {
            const addToCart = (data) => {
                const formData = new FormData()
                formData.append("course_id", data?.id)
                formData.append("course_price",data?.fee === 0 || data?.is_premium === "free" || data?.fee === null ? 0: data?.fee)
                dispatch(uploadServices.addToCart(formData)).then((response) => {
                    if (isUserLoggedIn) {
                    fetchCartItems()
                    }
                    Swal.fire(
                        "Success",
                        `${response.message}`,
                        "success"
                    )
                }).catch((error) => {
                    Swal.fire("Failed", error.message);
                })
            }
            addToCart(data)
        }

    }


    const fetchCartItems = async () => {
        try {
            const response = await dispatch(getServices.getCartData());
            setCartCountValue(response?.data?.length || 0)
            setCartData(response?.data || []);
        } catch (error) {
            console.error("Error fetching cart data", error);
        }
    };

    const DateFormatter = (date) => {
        const inputDate = date;

        const dateObj = new Date(inputDate);

        const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];

        const day = dateObj.getDate();
        const month = monthNames[dateObj.getMonth()];
        const year = dateObj.getFullYear();

        const formatDate = `${day} ${month} ${year}`;
        return formatDate;
    };

    const handleCourseEnquiry = (course) =>{

        if(isUserLoggedIn || isVendorLoggedIn){
            setCourseDetails([{course_id:course?.id,vendor_id:course?.vendor?.id}])
             setModalOpen(true);
        }else{
            navigate("/login")
        }
    }
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    
    const Category = (category_id) => {
        const category = categories?.find(
            (category) => category?.id === category_id
        );
        return category?.name;
    };

    return (
        <>
            <div className="course-listing-container">
                {
                    freeCourse?.length > 0 ? (
                        <>
                            <Row className="">
                                {freeCourse?.length > 0 &&
                                    freeCourse
                                        
                                        ?.map((course, index) => {

                                            return (
                                                <Col
                                                    key={course?.id || index}
                                                    className={(index + 1) % 2 === 0 ? "ps-3 mb-4 mt-4" : "pe-3 mb-4 mt-4"}
                                                    style={{ padding: "0 0.5rem" }}
                                                    md={12}
                                                >
                                                    {
                                                        course?.parent_category_name !== null ? <h5 className="main-category mb-0">{course?.parent_category_name}</h5 > : ''
                                                    }
                                                   
                                                    <Card
                                                        className="feature-card home-card"
                                                        hoverable
                                                        cover={
                                                            <img
                                                                onClick={(e) => handleCourseData(course)}
                                                                style={{ height: "250px" }}
                                                                src={course?.image ? `${DEMO_IMAGE_URL}${course?.image}` : featureImg2}
                                                                alt="text"
                                                            />
                                                        }
                                                    >
                                                        <div className="wishlist-icon-div" onClick={(e) => handleCourseData(course)}>
                                                            {
                                                                isUserLoggedIn ? (
                                                                    <button
                                                                        type="button"
                                                                        className="btn wishlist-feature-button add-to-wishlist"
                                                                        onClick={() => handleWishlistList(course?.course)}
                                                                    >
                                                                        {wishlistData?.some(
                                                                            (wish) => parseInt(wish?.id) === parseInt(course?.course?.id)
                                                                        ) ? (
                                                                            <GoHeartFill />
                                                                        ) : (
                                                                            <FaRegHeart />
                                                                        )}
                                                                    </button>
                                                                ) : " "
                                                            }

                                                        </div>
                                                        <div>
                                                            <div className="card-position-div">
                                                                <div
                                                                    style={{
                                                                        zIndex: "99",
                                                                        padding: "10px 15px",
                                                                        backgroundColor: "#106ab3",
                                                                        borderRadius: "0 10px 10px 0",
                                                                    }}
                                                                    className="expire-container"
                                                                >
                                                                    <span className="Lock-icon">
                                                                        <FaLock color="#fff" />
                                                                    </span>
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        zIndex: "99",
                                                                        padding: "10px 10px",
                                                                        backgroundColor: "#106ab3",
                                                                        borderRadius: "10px 0 0 10px",
                                                                    }}
                                                                    className="expire-container"
                                                                >
                                                                    <div className="start-end-date-container">
                                                                        <p
                                                                            style={{ color: "#fff", margin: 0 }}
                                                                            className="start-enddate"
                                                                        >
                                                                            {course?.course_start && course?.course_end
                                                                                ? `${DateFormatter(course?.course_start)} - ${DateFormatter(
                                                                                    course?.course_end
                                                                                )}`
                                                                                : "12 July 2024 - 15 August 2025"}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <Flex gap="middle" className="feature-course-subhead-price">
                                                                    <div>
                                                                        <p
                                                                            style={{
                                                                                color: "#106AB3",
                                                                                fontWeight: 700,
                                                                            }}
                                                                        >
                                                                            {Category(course?.category_id)
                                                                                ? Category(course?.category_id)
                                                                                : "Web Development"}
                                                                        </p>
                                                                    </div>
                                                                    <div>
                                                                        <p
                                                                            style={{
                                                                                color: "#1EAA39",
                                                                                fontWeight: 700,
                                                                            }}
                                                                        >
                                                                            {course?.fee !== 0 ? `$${course.fee}` : 'Free'}
                                                                        </p>
                                                                    </div>
                                                                </Flex>
                                                                <Flex className="feature-course-subhead-price">
                                                                    <h4>{course?.title}</h4>
                                                                    <img
                                                                        style={{ width: "25px", height: "auto" }}
                                                                        src={arrowUpRight}
                                                                        alt="text"
                                                                    />
                                                                </Flex>
                                                            </div>
                                                            <div>
                                                                <p className="course-description">
                                                                    {course?.about_course}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <Flex className="feature-course-subhead-price">
                                                            {
                                                                isUserLoggedIn  ? (
                                                                    // Regular user is logged in
                                                                    cartData && cartData.some(cartItem => cartItem?.course?.id === course?.id) ? (
                                                                        // Product is already in the cart
                                                                        <button
                                                                            type="button"
                                                                            className="btn add-to-cart-button"
                                                                            onClick={() => navigate("/cart")}
                                                                        >
                                                                            Go to cart
                                                                        </button>
                                                                    ) : (
                                                                        // Product is not in the cart
                                                                        <button
                                                                            type="button"
                                                                            className="btn add-to-cart-button"
                                                                            onClick={() => handleAddToCart(course)}
                                                                        >
                                                                            Add to cart
                                                                        </button>
                                                                    )
                                                                ) : (
                                                                    
                                                                    '' 
                                                                )
                                                            }
                                                            <button
                                                                type="button"
                                                                className="btn btn-warning feature-button enroll-button"
                                                                onClick={() => handleCourseEnquiry(course)}
                                                            >
                                                                Enquiry Now
                                                            </button>
                                                        </Flex>
                                                    </Card>
                                                </Col>
                                            );


                                        })}
                            </Row>


                            <Pagination
                                className="pagination"
                                current={currentPage}
                                pageSize={itemsPerPage}
                                total={
                                    freeCourse.length > 0
                                        ? freeCourse.length
                                        : ""
                                }
                                onChange={handlePageChange}
                            />
                        </>
                    ) : (
                        <p className="no_items_found">No Items found.</p>
                    )
               }
            </div>
            <Modal
        centered
        open={modalOpen}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
        className="course-enquiry-modal"
      >
         <CourseEnquiry course_details={courseDetails} setModalOpen={setModalOpen} />
      </Modal>
        </>
       
    )
}

export default FreeCourse;
