import React, { useEffect, useState,useContext } from "react";
import Sidebar from "../../../components/Sidebar/sidebar";
import { Row, Col, Table, Image, Button } from "antd";
import { MyCOurseImg,featureImg2 } from "../../../constant/images";
import { BiCartDownload } from "react-icons/bi";
import { IoEyeOutline } from "react-icons/io5";
import { RiDeleteBin6Line } from "react-icons/ri";
import * as getService from "../../../services/getServices";
import { useDispatch, useSelector } from "react-redux";
import * as uploadService from "../../../services/uploadServices";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
// import { add_To_Cart } from "../../../store/actions/user";
import { add_To_Cart } from "../../../StoreRedux/appSlice";
import {DEMO_IMAGE_URL} from "../../../Helper"
import { CartContext } from "../../../Helpers/CartContext";


export default function Wishlist() {
    const isUserLoggedIn = useSelector((state) => state.user.isUserLoggedIn);
    const isVendorLoggedIn = useSelector((state) => state.user.isVendorLoggedIn);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [wishlistData, setWishlistData] = useState([])
    const { addItemToCart } = useContext(CartContext);
    const cartItems = useSelector((state) => state.user.cart);

    const fetchWishlistData = async () => {
        const querryString = `?wishlist=true`
        dispatch(getService.getUserCourses(querryString)).then((response) => {
                    setWishlistData(response?.data)
                }).catch((error) => {
                    console.error("Error fetching wishlist data", error)
                })
    }

    useEffect(() => {
        if (isUserLoggedIn) {
         fetchWishlistData();
        }
    }, [])


    const handleRemoveCourse = (course) => {
        let formData = new FormData();
        formData.append("course_id", course?.id)

        try {

            dispatch(uploadService.addRemoveCourseWishlist(formData)).then((response) => {
                fetchWishlistData()
                
                Swal.fire(
                    "Success!",
                    `${response?.message}`,
                    "success"
                )
            }).catch((error) => {
                fetchWishlistData()
                Swal.fire(
                    "Failed",
                    "Unable to add item to wishlist.",
                    "error"
                )
            })

        } catch (e) {
            Swal.fire(
                "Failed",
                "Unable to add item to wishlist.",
                "error"
            )
        }

    }

    const handleCourseData = (values) => {
        navigate(`/course/${values?.id}/${values?.title}`, {
            state: { course_id: values?.id },
        });
    };


    const handleAddToCart = (data) => {

        if (!isUserLoggedIn && !isVendorLoggedIn) {
            if (data?.is_premium === "free" || data?.fee === 0 || data?.fee === null) {
                data.fee = 0;
            }

            dispatch(add_To_Cart(data))
            addItemToCart(cartItems?.length+1);
        } else {
            const addToCart = (data) => {
                const formData = new FormData()
                formData.append("course_id", data?.id)
                formData.append("course_price",data?.fee === 0 || data?.is_premium === "free" || data?.fee === null ? 0: data?.fee)
                dispatch(uploadService.addToCart(formData)).then((response) => {
                    Swal.fire(
                        "Success",
                        `${response.message}`,
                        "success"
                    )
                }).catch((error) => {
                    Swal.fire("Failed", error.message);
                })
            }
            addToCart(data)
        }

    }

    const columns = [
        {
            title: "My Items",
            dataIndex: "My Courses",
            key: "My Courses",
            render: (text, record) => (
                <div className="wish-image-my-course">
                    <Image
                        src={record?.image ? `${DEMO_IMAGE_URL}${record?.image}`: featureImg2 }
                        alt="Not Found"
                        className="img-fluid wish-image"
                        preview={false}
                    />
                    <p className="record-mycourse">{record?.title}</p>
                </div>
            ),
        },
        {
            title: "Price",
            dataIndex: "Price",
            key: "Price",
            render: (text, record) => (
                <div className="original-latest-price">
                    <p className="wish-latest-price">${record?.fee ? record?.fee :0}</p>
                </div>
            ),
        },
        // {
        //     title: "Ratings",
        //     dataIndex: "Ratings",
        //     key: "Ratings",
        //     render: (text, record) => {
        //         const averageRating = findAverateRating(record?.reviews || []);
        //         return (
        //             <div className="ratings-rating">
        //                 <p className="wish-rating">{averageRating.toFixed(1)}</p>
        //                 <Rate
        //                     className="rating-star"
        //                     allowHalf
        //                     defaultValue={averageRating}
        //                 />
        //                 <p className="wish-total-review">({record?.reviews?.length || 0} reviews)</p>
        //             </div>
        //         );
        //     },
        // },
        {
            title: "Actions",
            dataIndex: "Actions",
            key: "Actions",
            render: (text, record) => (
                <>
                    <Button onClick={() => handleAddToCart(record)} className="cart-button"><BiCartDownload color="#FFC227" width={12} height={10} /></Button>
                    <Button className="eye-button"><IoEyeOutline color="#106AB3" onClick={() => handleCourseData(record)} /></Button>
                    <Button className="delete-button" onClick={() => handleRemoveCourse(record)}><RiDeleteBin6Line color="#DA0734" /></Button>
                </>
            ),
        },
    ]; 

    const data = [
        {
            myCourses: "Web Development",
            originalPrice: 1299,
            latestPrice: 999,
            Ratings: 4,
            total_review: 2089,
            image: MyCOurseImg,
        },
        {
            myCourses: "Web Development",
            originalPrice: 1299,
            latestPrice: 999,
            Ratings: 4,
            total_review: 2089,
            image: MyCOurseImg,
        },
        {
            myCourses: "Web Development",
            originalPrice: 1299,
            latestPrice: 999,
            Ratings: 4,
            total_review: 2089,
            image: MyCOurseImg,
        },
        {
            myCourses: "Web Development",
            originalPrice: 1299,
            latestPrice: 999,
            Ratings: 4,
            total_review: 2089,
            image: MyCOurseImg,
        },
    ];
    return (
        <>
            <Row>
                <Col xl={4} className="d-xl-block d-md-none">
                    <Sidebar />
                </Col>
                <Col xl={20} md={24}>
                    <div className="mainContent wishlist">
                        <div className="wishlist-container">
                            <h2 className="my-wishlist-head">My Wishlist</h2>

                            <Row>
                                <Col md={24}>
                                    <div className="table-responsive">
                                        <Table
                                            className="table"
                                            pagination={{ pageSize: 10 }}
                                            columns={columns}
                                            dataSource={wishlistData}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    );
}
