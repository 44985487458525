import Cookies from 'js-cookie';

const COOKIE_NAME = 'userConsent';

// Function to set a session cookie
export const setCookie = () => {
    Cookies.set(COOKIE_NAME, 'accepted', { expires: 1, path: '/' }); 
};

// Function to check if the cookie exists
export const hasCookie = () => {
    return Cookies.get(COOKIE_NAME) !== undefined;
};

