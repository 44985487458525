function SalesSummaryChart(data) {
  if ( data.length === 0) {
    return {
      title: {
        text: "No Data Found",
        align: "center",
        verticalAlign: "middle",
        style: {
          fontSize: "16px",
          color: "#555",
        },
      },
      series: [],
    };
  }

  const xAxisCategories = data.map(item => item?.key);
  const seriesData = data.map(item => parseFloat(item?.value));

  const options = {
    chart: {
      type: "column",
    },
    title: {
      text: "",
    },
    exporting: {
      enabled: true,
    },
    plotOptions: {
      series: {
        pointWidth: 50,
      },
      column: {
        colorByPoint: true,
      },
    },
    xAxis: {
      categories: xAxisCategories,
      title: {
        text: "Booking Period",
      },
    },
    yAxis: {
      title: {
        text: "Total Items Fee Amount",
      },
      labels: {
        formatter: function () {
          return "$" + this.value;
        },
      },
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        name: "Total Items Fee",
        data: seriesData.map(value => (value === 0 ? null : value)),
      },
    ],
  };

  return options;
}

export { SalesSummaryChart };