import React, { useState, useEffect } from 'react'
import * as getServices from "../../services/getServices";
import { useDispatch } from 'react-redux';
import { Card, Carousel,Spin } from 'antd';
import {DEMO_IMAGE_URL} from "../../Helper";
import { LoadingOutlined } from '@ant-design/icons';

const Offer = () => {

    const dispatch = useDispatch();
    const [offer, setOffer] = useState([]);
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        setLoader(true)
        dispatch(getServices.getOffer())
            .then((res) => {
                setLoader(false)
                setOffer(res?.offers);
            })
            .catch((err) => {
                setLoader(false)
                console.error(err);
            });
    }, []);

    
    return (
        <>
          {loader ? (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                            <Spin indicator={<LoadingOutlined style={{ fontSize: 38 }} spin />} />
                        </div>
          ) :(
            <Carousel autoplay autoplaySpeed={2500} dots={false} arrows draggable={true} style={{ margin: '80px auto', width: '100%', maxWidth:'1200px' }}className='offers_slider_zone'>
                {offer.map(offerItem => (
                    <div key={offerItem.id} style={{ height: '200px', overflow: 'hidden' }}>
                        <Card hoverable style={{ borderRadius: '8px', height: '100%', overflow: 'hidden' }}>
                            <img  onClick={() => offerItem.redirect_link && window.open(offerItem.redirect_link, '_blank')} alt={offerItem.title} src={`${DEMO_IMAGE_URL}${offerItem.image}`} style={{ objectFit: 'contain', height: '100%', width: '100%',cursor: offerItem.redirect_link ? 'pointer' : 'default' }}/>
                            <Card.Meta
                                title={offerItem.title}
                                // description={`Active: ${offerItem.start_date} to ${offerItem.end_date}`}
                                style={{ padding: '8px', textAlign: 'center' }} 
                            />
                        </Card>
                    </div>
                ))}
            </Carousel>
          )}
            
        </>
    )
}
export default Offer