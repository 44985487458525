import React from "react";
import { Row, Col, Button, Input, Image } from "antd";
import { BiSolidCopy } from "react-icons/bi";
import { CiMail } from "react-icons/ci";
import { ReferFriend } from "../../constant/images";

export default function ReferAFriend() {
  return (
    <>
      <div className="refer-friend-outer-container">
        <div className="refer-friend-container container bgNone">
          <Row className="refer-friend-row">
            <Col className="refer-friend-col-one" md={14}>
              <p className="refer-earn">Refer and Earn</p>
              <p className="invite-text">
                Invite your friends to join
                <span className="well-fun-text"> Welloffun</span>, and for each
                one who purchase a lesson, we'll give both of you a 10% of
                discount on booking of lessons.
              </p>
              <p className="get-discount">
                Get 10% discount on purchase of lesson
              </p>
              <p className="referal-code">Your referral code:</p>
              <div className="code-copy-div">
                <p className="code">ASW123</p>
                <Button className="copy-button-refer">
                  <BiSolidCopy className="copy-icon" />
                  <p className="copy-text">Copy</p>
                </Button>
              </div>

              <div className="sign-up-email-container">
                <Input
                  className="email-input-container"
                  prefix={<CiMail className="email-icon-refer" />}
                  placeholder="Email address"
                  suffix={
                    <Button className="signup-button-text">Signup</Button>
                  }
                />
              </div>
              <Button className="invite-button-one">Invite friend</Button>
            </Col>
            <Col className="refer-friend-col-two" md={10}>
              <div className="refer-earn-image-container">
                <div className="refer-earn-background-image">
                  <p className="refer-earn-text">Refer and Earn</p>

                  <div className="refer-image-container">
                    <Image
                      className="refer-image"
                      src={ReferFriend}
                      preview={false}
                    />
                  </div>
                </div>
                <p className="share-referal-text">
                  Share your referral code with your friends and get benefits.
                </p>
                <div className="invite-button-container">
                  <Button className="invite-button-two">Invite friend</Button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
