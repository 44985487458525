import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import * as uploadService from "../../../services/uploadServices";
import Swal from "sweetalert2";
import { Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';

export default function UserOnBoarding() {
	const VendorData = useSelector((state) => state.user.userData);
	const location = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [code, setCode] = useState(null);
	const [loader, setLoader] = useState(false)
	const user_id = sessionStorage.getItem("user_id");

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const paramsObject = {};
		queryParams.forEach((value, key) => {
			paramsObject[key] = value;
		});

		setCode(paramsObject?.code);
	}, [location.search]);

	useEffect(() => {
		setLoader(true)
		if (!code) return;

		const formData = new FormData();
		const finalUserId = VendorData?.user?.id;

		if (!finalUserId) {
			Swal.fire("Failed!", "User ID is missing", "error");
			navigate("/");
			return;
		}

		formData.append("user_id", finalUserId);

		dispatch(uploadService.uploadStriptConnectId(formData))
			.then((response) => {
				setLoader(false)
				Swal.fire("Connected!", response?.message, "success").then(() => {
					navigate("/vendor/dashboard");
				});
			})
			.catch((error) => {
				setLoader(false)
				Swal.fire("Failed!", error?.message, "error").then(() => {
					navigate("/");
				});
			});
	}, [code, dispatch, navigate, user_id, VendorData]);

	return (
		<div>
			{loader ? (
				<div style={{ height: "100vh", width: "100%" }} className="user-onboarding-loader-container d-flex justify-content-center align-items-center">
					<Spin indicator={<LoadingOutlined />} size="large" />
				</div>
			) : ("")}

		</div>
	);
}
