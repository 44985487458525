import React, { useState } from "react";
import {
    Row,
    Col,
    Button
}
    from "antd";
import AddressForm from "../../pages/paymentPrcess/addressForm";
import CheckoutCourses from "../../pages/paymentPrcess/checkoutCourses";
// import CourseEnquiry from "../courseEnquiry"
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as uploadService from "../../services/uploadServices"
import Swal from "sweetalert2";

export default function Checkout() {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const isUserLoggedIn = useSelector((state) => state.user.isUserLoggedIn);
    const [courseDetails, setCourseDetails] = useState([])
    const [payLoader, setPayLoader] = useState(false)
    const userData = useSelector((state) => state.user.userData);

    const handleBillingAddress = (values) => {
        onFinish(values)
    }

    const onFinish = (billingData) => {
        if (billingData.landmark === undefined) {
            billingData.landmark = null
        } else {
            billingData.landmark = billingData.landmark
        }
        if (billingData.address_line2 === undefined ) {
            billingData.address_line2 = null
        } else {
            billingData.address_line2 = billingData.address_line2
        }
        setPayLoader(true)
        try {
            const formData = {
                "billing_details": billingData,
                "order_items": courseDetails?.order_items,
                "total_original_amount": courseDetails?.total_original_amount,
                "paid_amount": courseDetails?.paid_amount,
                "coupon_id": courseDetails?.coupon_id,
                "coupon_code": courseDetails?.coupon_code,
                "coupon_discount": courseDetails?.coupon_discount,
                "pricing_category_id": courseDetails?.pricing_category_id,
                "pricing_category_discount": courseDetails?.pricing_category_discount,
                "user_id": isUserLoggedIn ? userData?.user?.id : "",
                tax_amount: ""
            }

            dispatch(uploadService.payment(formData)).then((response) => {
                setPayLoader(false)
                Swal.fire(
                    "Payment Successful",
                    `${response?.message}`,
                    "success"
                ).then(() => {
                    navigate("/thankyou", {
                        state: {
                            transaction_id: response?.payment?.transaction_id
                        }
                    })
                }).catch(() => {
                    navigate("/thankyou", {
                        state: {
                            transaction_id: response?.payment?.transaction_id
                        }
                    })
                })
            }).catch((error) => {
                setPayLoader(false)
                Swal.fire(
                    "Failed",
                    `${error.message}`,
                    "error"
                )
            })
        } catch (error) {
            console.error({ error })

        }
    }

    return (
        <>
            <div className="checkout-page-section">
                <div className="container">
                    <Row className="checkout-mobile-reverce">
                        <Col xl={12} md={12} sm={24}>
                            <CheckoutCourses setCourseDetails={setCourseDetails} />
                        </Col>
                        <Col className="billing-detail-form" xl={12} md={12} sm={24}>
                            {<div className="row">
                                {/* {!isUserLoggedIn ? (
                                    <div className="col-xl-12 col-md-12">
                                        <p className="alreadyTitle">Already have an account ?<Button onClick={() => navigate("/login")}>Log in</Button></p>
                                    </div>
                                ) : (<CourseEnquiry course_details={courseDetails} />)} */}
                                <AddressForm onData={handleBillingAddress} payLoader={payLoader} />
                            </div>}
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}
